import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";

export const CardRendezVous = ({personName, duration, status, onClick}) => {
  const style = useStyles();

  return (
    <div className={style.cardContainer}>
      <p className={style.font}>Avec: {personName}</p>
      <p className={style.font}>Duration: {duration}</p>
      <p className={style.font}>Status: {status}</p>
      <div onClick={onClick} className={style.button}>
        Voir
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  cardContainer: {
    width: "300px",
    // height: 100,
    backgroundColor: "white",
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    padding: 10,
    marginBottom: 15,
    marginRight: 20,
    boxShadow: "0 3px 6px rgb(0,0,0, 0.26)",

    "@media (max-width: 920px)": {
      marginRight: 0,
    },
  },
  cardTopContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  font: {
    fontFamily: "poppins-regular",
    fontSize: 16,
    margin: 2,
  },
  button: {
    height: 35,
    backgroundColor: "#388ADC",
    color: "white",
    borderRadius: 5,
    marginTop: 10,
    marginBottom: 10,
    fontFamily: "poppins-regular",
    fontSize: 16,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "#2769ab",
    },
  },
});
