import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";

const DashboardMiddleBar = () => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <div
        className={style.button_container}
        onClick={() => navigate("/poing")}
      >
        <img src="images/iconPunch.png" className={style.logos} />
        <p className={style.text}>Opération coup de poing</p>
      </div>
      <div
        className={style.button_container}
        onClick={() => navigate("/equipe")}
      >
        <img
          src="images/iconTeam.png"
          className={style.logos}
          style={{ height: "3vh" }}
        />
        <p className={style.text}>Mon équipe</p>
      </div>
      <div
        className={style.button_container}
        onClick={() => navigate("/messagerie")}
      >
        <img
          src="images/iconMail.png"
          className={style.logos}
          style={{ height: "3vh" }}
        />
        <p className={style.text}>Messagerie</p>
      </div>
      <div
        className={style.button_container}
        onClick={() => navigate("/historique")}
      >
        <img
          src="images/iconHistory.png"
          className={style.logos}
          style={{ height: "3.2vh" }}
        />
        <p className={style.text}>Historique</p>
      </div>
      <div
        className={style.button_container}
        onClick={() => navigate("/prospections")}
      >
        <img src="images/iconGeo.png" className={style.logos} />
        <p className={style.text}>Zone hors QR code</p>
      </div>
      <div
        className={style.button_container}
        onClick={() => navigate("/produits")}
      >
        <img src="images/iconBag.png" className={style.logos} />
        <p className={style.text}>Leurs secteurs d'activités</p>
      </div>
      <div className={style.right_container}>
        <img
          src="images/meteo.png"
          style={{ height: "7vh", cursor: "pointer" }}
          className={style.logos}
          onClick={() => navigate("/meteo")}
        />
        <img
          src="images/leaf.png"
          style={{ height: "4vh", marginLeft: 20, cursor: "pointer" }}
          className={style.logos}
          onClick={() => navigate("/ecoresponsable")}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "relative",
    fontFamily: "poppins-regular",
  },
  button_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 20px 0 20px",
    cursor: "pointer",
  },
  logos: {
    height: "3.5vh",
  },
  text: {
    fontSize: 14,
    color: "#388ADC",
    margin: "5px 0 0 0",
  },
  right_container: {
    position: "absolute",
    right: 40,
    display: "flex",
    alignItems: "center",
  },
});

export default DashboardMiddleBar;
