import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { height } from "@mui/system";

const RelationSiege = () => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div className={style.containers}>
      <div className={style.content}>
        <img src="../../../images/sablier.png" className={style.sablier} />
        <p className={style.textSablier}>
          Un Gain de temps et d'argent en limitant la prospection véhiculée.
          <br></br>
          <br></br>
          Une visibilité et un contrôle total sur sa prospection.
        </p>
        <div className={style.longText}>
          <strong style={{ fontSize: 20 }}>Société de mise en relation</strong>
          <br></br>
          <br></br>
          Prospection commerciale a su inventer et créer un nouveau canal de
          prospection : <br></br>
          <br></br>• La prospection Qr-code avec des appels d’offres directs &
          indirects.<br></br>
          <br></br> 1S1 du visuel de son logo, est une solution informatique en
          ligne permettant d’optimiser et simplifier les relations et
          interactions entre les entreprises et les fournisseurs, dans une
          démarche de fidélisation.<br></br>
          <br></br> Contrairement aux 7 canaux existants :<br></br>
          <br></br> • Prospection terrain
          <br></br> • Prospection par téléphone
          <br></br> • Prospection par e-mailing
          <br></br>• Prospection par votre site web pour attirer des clients
          <br></br> • Prospection par les réseaux sociaux
          <br></br>• Prospection par les apporteurs d'affaires
          <br></br>• La prescription <br></br>
          <br></br>
          Ce 8ème canal révolutionne la prospection commerciale, il est
          intuitif, économique, éco-responsable et direct. Il permet d’accéder
          aux appels d’offre, sans solliciter les entreprises. <br></br>
          <br></br>
          Prospection commerciale est un outil d’un nouveau genre, une pierre
          supplémentaire dans la construction du nouveau monde numérique dédié
          aux professionnels.
        </div>
        <div className={style.rightSide}>
          <p className={style.titleLogos}>
            <strong>Découvez les fonctionnalités de notre outil</strong>
          </p>
          <img
            src="../../../images/sablierSiege.png"
            className={style.sablierLogos}
          />
          <div
            className={style.button}
            onClick={() => {
              navigate("/inscription/fournisseur/siege/abonnement");
            }}
          >
            Suivant
          </div>
        </div>
      </div>
      <div className={style.footer}>
        <p className={style.textFooter} onClick={() => navigate("/mentions")}>
          Mentions légales
        </p>
        <p
          className={style.textFooter}
          onClick={() => navigate("/conditionsgenerales")}
        >
          Conditions générales
        </p>
        <p className={style.textFooter} onClick={() => navigate("/politique")}>
          Politique de confidentialité
        </p>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  containers: {
    height: "100vh",
    background: "linear-gradient(#4EA8B7, #3A95C8)",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    height: "100vh",
    fontFamily: "poppins-regular",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    backgroundColor: "#000000",
    height: "135px",
  },
  longText: {
    color: "white",
    fontSize: "2vh",
    width: "35%",
    marginTop: 30,
    marginLeft: 30,
  },
  sablier: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    height: "100vh",
  },
  sablierLogos: {
    width: "45vh",
    marginTop: 50,
    marginBottom: 50,
  },
  rightSide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "8%",
    marginTop: 30,
  },
  titleLogos: {
    color: "white",
    fontSize: "2vh",
  },
  textSablier: {
    position: "absolute",
    textAlign: "center",
    fontSize: "2vh",
    width: "13%",
    left: "50%",
    top: "72%",
    transform: "translateX(-50%)",
  },
  textFooter: {
    margin: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "poppins-regular",
    fontSize: 20,
    color: "#3A95C8",
    width: "100%",
    height: "80px",
    backgroundColor: "white",
    borderRadius: 10,
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default RelationSiege;
