import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useNavigate} from "react-router-dom";

const Card2 = ({
  besoin,
  button,
  isPunch,
  isTimer,
  timerText,
  changeStyle,
  redirect,
  personName = null,
  start = null,
  end = null,
  day,
  besoinColor,
}) => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div
      className={style.container}
      style={changeStyle}
      onClick={() => {
        if (redirect) {
          navigate(redirect);
        }
      }}
    >
      <div className={style.topContainer}>
        <div className={style.locContainer}>
          <p className={style.locText}>{personName && "Avec: " + personName}</p>
          <p className={style.locText}>Le: {day}</p>

          <p className={style.locText}>
            {start ? "De: " + start + " à " + end : "Fait à: " + end}
          </p>
        </div>
        <div className={style.besoinContainer}>
          <p className={style.besoinText}>Nb besoin</p>
          <div className={style.besoinNumber}>
            <p className={style.besoinBox} style={{color: besoinColor}}>
              {besoin}
            </p>
          </div>
        </div>
      </div>
      <div className={style.bottomContainer}>
        {button && <div className={style.button}>{button}</div>}
        {isPunch && <img src="images/punch.png" style={{height: 25}} />}
        {isTimer && (
          <div className={style.buttonTimer}>
            <img src="images/clock.png" style={{height: 15, marginRight: 5}} />
            <p>{timerText}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    height: 135,
    width: "60%",
    borderRadius: 20,
    padding: 10,
    backgroundColor: "white",
    color: "black",
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
    fontFamily: "poppins-regular",
    cursor: "pointer",

    "@media (max-width: 500px)": {
      // height: 100,
      width: "80%",
    },
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  locLogo: {
    width: 9,
    height: 12.7,
    marginRight: 7,
  },
  locContainer: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    fontFamily: "poppins-regular",
    fontSize: 16,
    width: "60%",
  },
  locText: {
    fontFamily: "poppins-regular",
    fontSize: 16,
    margin: 3,

    "@media (max-width: 500px)": {
      fontSize: 12,
    },
  },
  besoinContainer: {
    display: "flex",
    alignItems: "center",
  },
  besoinNumber: {
    padding: "0 20px 0 20px",
    borderRadius: 5,
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
    backgroundColor: "white",

    "@media (max-width: 500px)": {
      padding: "0 15px 0 15px",
    },
  },
  besoinText: {
    fontFamily: "poppins-regular",
    fontSize: 16,
    marginRight: 10,

    "@media (max-width: 500px)": {
      fontSize: 12,
      marginRight: 2,
    },
  },
  besoinBox: {
    color: "black",
    margin: 0,
    fontSize: 20,
    fontFamily: "poppins-regular",

    "@media (max-width: 500px)": {
      fontSize: 16,
    },
  },
  bottomContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexGrow: 1,
  },
  button: {
    color: "white",
    backgroundColor: "#388ADC",
    padding: "5px 20px 5px 20px",
    borderRadius: 25,
    fontFamily: "poppins-regular",
    fontSize: 14,
  },
  buttonTimer: {
    display: "flex",
    alignItems: "center",
    color: "white",
    backgroundColor: "#388ADC",
    padding: "0 15px 0 15px",
    borderRadius: 25,
    fontFamily: "poppins-regular",
    fontSize: 12,
  },
});

export default Card2;
