import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import DashboardZoom from "../../Components/DashboardZoom";
import PopUp from "../PopUp";
import { useCookies } from "react-cookie";
import { useFirebase } from "../../hooks/useFirebase";
import { Calendar } from "react-calendar";
import {
  query,
  where,
  orderBy,
  collection,
  doc,
  updateDoc,
  setDoc,
  addDoc,
  getDoc,
  getDocs,
} from "@firebase/firestore";

const DashboardSalarie = () => {
  const style = useStyles();
  const navigate = useNavigate();
  const [closePop, setClosePop] = useState(true);
  const [closePopInd, setClosePopInd] = useState(true);
  const [value, onChange] = useState(new Date());
  const [cookies, setCookie, removeCookie] = useCookies();
  const [geoData, setGeoData] = useState([]);

  const { user, logout, db } = useFirebase();

  const disconnect = async () => {
    logout();
    removeCookie("userToken");
    navigate("/");
  };

  useEffect(() => {
    const pop = document.querySelector("#qr");
    if (closePop === true) {
      pop.style.transform = "translateY(100%)";
    } else {
      pop.style.transform = "translateY(0)";
    }
  }, [closePop]);

  useEffect(() => {
    const pop = document.querySelector("#ind");
    if (closePopInd === true) {
      pop.style.transform = "translateY(100%)";
    } else {
      pop.style.transform = "translateY(0)";
    }
  }, [closePopInd]);

  useEffect(() => {
    (async () => {
      const devisRef = query(
        collection(db, "devis"),
        where("fournisseur", "==", user.ref),
        where("testField", "==", true)
      );
      const docSnap = await getDocs(devisRef);

      const docs = await Promise.all(
        docSnap.docs
          .filter(
            (d) =>
              (d.data().status == "refused" && !d.data().secondDevisAt) ||
              ["sent", "pending"].includes(d.data().status)
          )
          .map(async (geo) => {
            const _user = await getDoc(geo.data().restaurateur);

            return {
              id: geo.id,
              ref: geo.ref,
              data: {
                ...geo.data(),
                ref: geo.ref,
              },
              user: {
                ..._user.data(),
                ref: _user.ref,
              },
            };
          })
      );

      setGeoData(docs);
      // setLoading(false);
    })();
  }, []);

  const rdv = [
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
  ];

  return (
    <div className={style.container}>
      <div className={style.deco} onClick={() => disconnect()}>
        <p className={style.decoText}>Déconnexion</p>
      </div>
      <div className={style.popUpContainer} id="qr">
        <PopUp
          type={"green"}
          data={rdv}
          close={closePop}
          key="test"
          setClosePop={setClosePop}
          closePop={closePop}
          redirect={"/appeloffre"}
          redirectVoir={"/prospectionsqr"}
        />
      </div>
      <div className={style.popUpContainer} id="ind">
        <PopUp
          type={"bluePunch"}
          data={geoData}
          close={closePop}
          key="test"
          setClosePop={setClosePopInd}
          closePop={closePopInd}
          redirect={"/appeloffre"}
          redirectVoir={"/prospectionspoing"}
        />
      </div>
      <div className={style.topContainer}>
        <div className={style.topContainerLeft}>
          <div
            onClick={() => navigate("/geo")}
            className={style.zoomContainers}
          >
            <DashboardZoom rotate={true} />
          </div>
          <div className={style.geoContainer}>
            <p style={{ margin: 0 }}>Géolocalisation</p>
            <p className={style.direct}>EN DIRECT</p>
          </div>
          <img src="images/carte.png" className={style.carte} />
        </div>
        <div className={style.topContainerRight}>
          <div
            onClick={() => navigate("/support")}
            style={{ "&:hover": { cursor: "pointer" } }}
            className={style.zoomContainers}
          >
            <DashboardZoom changeStyle={{ left: "90.5%" }} />
          </div>
          <div className={style.orgaContainer}>
            <p style={{ margin: 0 }}>Organisation</p>
            <img src="images/bell.png" style={{ height: 40, marginLeft: 15 }} />
          </div>
          <div className={style.calendarContainer}>
            <Calendar
              className={"dashboardCalendar"}
              onChange={onChange}
              value={value}
            />
          </div>
        </div>
      </div>
      <div className={style.middleContainer}>
        <img
          src="images/opPoing.png"
          style={{ left: "35%" }}
          className={style.logos}
          onClick={() => navigate("/coupdepoing")}
        />
        <img
          src="images/historique.png"
          style={{ left: "45%" }}
          className={style.logos}
          onClick={() => navigate("/historique")}
        />
        <img
          src="images/zoneQr.png"
          style={{ left: "55%" }}
          className={style.logos}
          onClick={() => navigate("/prospections")}
        />
        <img
          src="images/secteurAct.png"
          style={{ left: "65%" }}
          className={style.logos}
          onClick={() => navigate("/produits")}
        />
        <img
          src="images/meteo.png"
          style={{ left: "90%" }}
          className={style.logos}
          onClick={() => navigate("/meteo")}
        />
        <img
          src="images/leaf.png"
          style={{ left: "95%", height: "5vh" }}
          className={style.logos}
          onClick={() => navigate("/ecoresponsable")}
        />
      </div>
      <div className={`${style.bottomContainer}`}>
        <img
          src="images/cardQr.png"
          style={{ left: "35%" }}
          className={style.cards}
          onClick={() => {
            setClosePop(false);
          }}
        />
        <img
          src="images/cardProp.png"
          style={{ left: "65%" }}
          className={style.cards}
          onClick={() => {
            setClosePopInd(false);
          }}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100%",
    fontFamily: "poppins-regular",
    fontSize: 30,
    overflow: "hidden",
    position: "relative",
  },
  topContainer: {
    height: "55vh",
    width: "100%",
    display: "flex",
  },
  topContainerLeft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    position: "relative",
  },
  geoContainer: {
    position: "absolute",
    top: 5,
    left: 30,
  },
  direct: {
    margin: 0,
    color: "white",
    backgroundColor: "#F6C745",
    width: "50%",
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 15,
    padding: "2px 5px 2px 5px",
  },
  carte: {
    height: "80%",
    transform: "translateX(-30%)",
  },
  zoomContainers: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  topContainerRight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.04)",
    width: "50%",
    height: "100%",
    position: "relative",
  },
  calendar: {
    height: "80%",
  },
  orgaContainer: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: 5,
    right: 30,
  },
  middleContainer: {
    display: "flex",
    height: "10vh",
    width: "100%",
    backgroundColor: "white",
    boxShadow: "0 3px 12px rgb(0,0,0, 0.16)",
    zIndex: 10,
    position: "relative",
  },
  logos: {
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    height: "7vh",
    "&:hover": {
      cursor: "pointer",
    },
  },
  bottomContainer: {
    height: "35vh",
    width: "100%",
    position: "relative",
  },
  cards: {
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    height: "90%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  popUpContainer: {
    position: "absolute",
    width: "100%",
    zIndex: 10,
    bottom: 0,
    overflow: "hidden",
    transform: "translateY(100%)",
    height: "35vh",
  },
  deco: {
    position: "absolute",
    top: -25,
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1000,
    height: 100,
    width: 100,
    borderRadius: "100%",
    backgroundColor: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  decoText: {
    color: "white",
    fontSize: 12,
    margin: 0,
  },
  calendarContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
    maxHeight: "90%",
    borderRadius: 20,
    overflow: "hidden",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
  },
});

export default DashboardSalarie;
