import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLogin from "../Components/InputLogin";
import Home from "../Components/Home";
import { useNavigate } from "react-router-dom";
import { useFirebase } from "../hooks/useFirebase";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  query,
  getdoc,
} from "@firebase/firestore";

const Produits = () => {
  const style = useStyles();
  const navigate = useNavigate();
  const [isInscription, setIsInscription] = useState(false);
  const [element, setElement] = useState([
    {
      text: "VIANDE",
      color: "#B53729",
      number: 0,
    },
    {
      text: "POISSONS",
      color: "#388ADC",
      number: 0,
    },
    {
      text: "LAITIER",
      color: "#F6C745",
      number: 0,
    },
    {
      text: "FRAIS",
      color: "#61B194",
      number: 0,
    },
    {
      text: "EPICERIE",
      color: "#DFA265",
      number: 0,
    },
    {
      text: "VIN",
      color: "#E3E3E3",
      number: 0,
    },
  ]);
  const { user, db } = useFirebase();

  useEffect(() => {
    if (window.location.href.includes("inscription")) {
      setIsInscription(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const ref = query(collection(db, "users", user.id, "produits_courants"));
      const querySnap = await getDocs(ref);
      querySnap.forEach((el) => {
        if (el.id == "viande") {
          let tmp = element;
          tmp[0].number = el.data().produits?.length || 0;
          setElement([...tmp]);
          return;
        }
        if (el.id == "poissons") {
          let tmp = element;
          tmp[1].number = el.data().produits?.length || 0;
          setElement([...tmp]);
          return;
        }
        if (el.id == "laitier") {
          let tmp = element;
          tmp[2].number = el.data().produits?.length || 0;
          setElement([...tmp]);
          return;
        }
        if (el.id == "frais") {
          let tmp = element;
          tmp[3].number = el.data().produits?.length || 0;
          setElement([...tmp]);
          return;
        }
        if (el.id == "epicerie") {
          let tmp = element;
          tmp[4].number = el.data().produits?.length || 0;
          setElement([...tmp]);
          return;
        }
        if (el.id == "vin") {
          let tmp = element;
          tmp[5].number = el.data().produits?.length || 0;
          setElement([...tmp]);
          return;
        }
      });
    })();
  }, []);

  return (
    <div className={style.container}>
      {!isInscription && <Home />}
      {isInscription ? (
        <div
          className={style.topContainer}
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <p className={style.title}>Ajoutez vos produits</p>
          <p style={{ marginLeft: 50, opacity: 0.5 }}>
            Prospection commerciale vous invite à écrire la liste de vos besoins
            familiers par colonne.
          </p>
        </div>
      ) : (
        <div className={style.topContainer}>
          <p className={style.title}>Vos produits</p>
          <img
            src="images/info.png"
            style={{ height: 30, marginLeft: 10, marginTop: 50 }}
          />
        </div>
      )}
      <div className={style.middleContainer}>
        {element.map((el, i) => {
          return (
            <div
              key={i}
              className={style.productContainer}
              onClick={() => {
                const link = el.text.replace(" ", "").toLowerCase();
                if (isInscription) {
                  navigate(`/inscription/restaurateur/produits/modification`);
                  return;
                }
                navigate(`/produits/modification#${link}`);
              }}
            >
              <p>{el.text}</p>
              <div
                className={style.buttonContainer}
                style={{ backgroundColor: el.color }}
              >
                {el.number == 0 && isInscription ? (
                  <img
                    src="../../images/add-circle.png"
                    style={{ height: 42.75 }}
                  />
                ) : (
                  <p className={style.number} style={{ color: el.color }}>
                    {el.number}
                  </p>
                )}
              </div>
              {!isInscription && (
                <div className={style.bottomAdd}>
                  <img
                    src="images/plusButton.png"
                    style={{ height: 35, width: 35 }}
                  />
                  <p className={style.addText}>Ajouter</p>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className={style.bottomContainer}>
        <div
          className={style.nextButton}
          onClick={() => {
            if (isInscription) {
              navigate("/inscription/restaurateur/abonnement");
            } else {
              navigate("/dashboard");
            }
          }}
        >
          <InputLogin
            text={"Valider"}
            changeStyle={{
              backgroundColor: "#388ADC",
              width: 300,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    fontFamily: "poppins-regular",
  },
  title: {
    fontSize: 25,
    margin: "50px 0 0 50px",
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: 25,
  },
  middleContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    marginTop: 100,
  },
  productContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "13%",
    cursor: "pointer",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    height: 88,
    width: "100%",
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
  },
  bottomContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexGrow: 1,
    margin: "0 25px 25px",
  },
  nextButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  number: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: 40,
    width: 40,
    fontSize: 20,
    borderRadius: "100%",
  },
  bottomAdd: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  addText: {
    color: "#388ADC",
    fontSize: 18,
  },
});

export default Produits;
