import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import Home from "../Components/Home";
import Select from "react-dropdown-select";
import DefaultButton from "../Components/defaultButton";

const Statistique = () => {
  const style = useStyles();
  const navigate = useNavigate();

  const [pressed, setPressed] = useState(false);

  return (
    <div className={style.container}>
      <Home />
      <div className={style.leftContainer}>
        <div className={style.leftContainerCenter}>
          <div className={style.leftContainerTop}>
            <p className={style.title}>Statistique</p>
            <img
              src="images/info.png"
              style={{ width: 32.57, height: 35, marginLeft: 20 }}
            />
          </div>
          <div className={style.leftContainerMiddle}>
            <Select
              style={{
                width: "60%",
                border: "none",
                outline: "none",
                borderRadius: 5,
                backgroundColor: "#F6F6F6",
                height: 47,
                padding: 10,
                marginBottom: 20,
              }}
              onChange={() => undefined}
              onDropdownOpen={() => setPressed(true)}
              onDropdownClose={() => setPressed(false)}
            />
          </div>
          <div className={style.leftContainerBottom}>
            <p className={style.leftContainerBottomText}>Observation:</p>
          </div>
        </div>
      </div>
      <div className={style.rightContainer}>
        <div className={style.table}>
          <div className={style.tableLeft}>
            <div className={style.tableLeftCells} style={{ height: "5vh" }}>
              <p style={{ margin: 0, fontSize: 25 }}>Il y a</p>
            </div>
            <div className={style.tableLeftCells} style={{ height: "22vh" }}>
              <p className={style.weeksText}>
                S1
                <br />
                <p style={{ fontSize: 20 }}>Semaine 17</p>
              </p>
            </div>
            <div className={style.tableLeftCells} style={{ height: "22vh" }}>
              <p className={style.weeksText}>
                S1
                <br />
                <p style={{ fontSize: 20 }}>Semaine 17</p>
              </p>
            </div>
            <div className={style.tableLeftCells} style={{ height: "22vh" }}>
              <p className={style.weeksText}>
                S1
                <br />
                <p style={{ fontSize: 20 }}>Semaine 17</p>
              </p>
            </div>
            <div
              className={style.tableLeftCells}
              style={{ height: "9vh", border: "none" }}
            ></div>
          </div>
          <div className={style.tableRight}>
              <div className={style.tableRightTop}>
                <p style={{ margin: '0 0 0 30px', fontSize: 25 }}>Graphique</p>
              </div>
              <div className={style.tableRightMiddle}></div>
              <div className={style.tableRightBottom}>
                <div>
                  <div></div>
                  <p>moyenne des collaborateurs</p>
                </div>
              </div>
          </div>
        </div>
        <div className={style.buttonsContainer}>
          <DefaultButton>Lui envoyer</DefaultButton>
          <DefaultButton>Télécharger</DefaultButton>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
  },
  leftContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "50%",
  },
  leftContainerCenter: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    height: "100%",
  },
  leftContainerTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 100,
  },
  title: {
    fontSize: 37,
  },
  leftContainerBottom: {
    width: "100%",
    height: "90%",
    backgroundColor: "white",
    marginBottom: 50,
    borderRadius: 20,
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
  },
  leftContainerBottomText: {
    fontSize: 25,
    marginLeft: 20,
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 20,
    width: "90%",
    height: "80vh",
    marginBottom: 30,
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "90%",
  },
  tableLeft: {
    width: "30%",
    boxShadow: "21px 3px 16px -16px rgba(194,194,194,0.91)",
    overflow: 'hidden',
  },
  tableLeftCells: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "2px solid grey",
  },
  weeksText: {
    fontSize: 60,
    textAlign: 'center'
  },
  tableRight: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
  },
  tableRightTop: {
    height: '5vh',
    borderBottom: "2px solid grey",
  },
  tableRightMiddle: {
    height: '66vh',
    paddingTop: 4,
    borderBottom: "2px solid grey",
    backgroundColor: '#F2F2F2'
  }
});

export default Statistique;
