import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";

const AboSiege = () => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <div className={style.container__center}>
        <img src="../../../images/aboSiege.png" className={style.image} />
        <div
          className={style.buttonG}
          style={{ backgroundColor: "#B4E197" }}
          onClick={() => {
            localStorage.setItem("abosalarie", "pro");
            navigate("/inscription/fournisseur/siege/gratuit");
          }}
        >
          Gratuit
        </div>
        <div
          className={style.buttonG}
          style={{ backgroundColor: "#83BD75", left: 200 }}
          onClick={() => {
            localStorage.setItem("abosalarie", "pro");
            navigate("/inscription/fournisseur/siege/pro");
          }}
        >
          Pro
        </div>
        <div
          className={style.buttonG}
          style={{ backgroundColor: "#4E944F", left: 340 }}
          onClick={() => {
            localStorage.setItem("abosalarie", "pro");
            navigate("/inscription/fournisseur/siege/pro+");
          }}
        >
          Pro +
        </div>
      </div>
    </div>
  );
};

export default AboSiege;

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  container__center: {
    position: "relative",
  },
  image: {
    width: "auto",
    height: "90vh",
  },
  buttonG: {
    position: "absolute",
    bottom: 50,
    left: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "poppins-regular",
    fontSize: 20,
    color: "white",
    backgroundColor: "#B4E197",
    width: 80,
    height: "25px",
    borderRadius: 5,
    padding: "10px 10px 10px 10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
});
