import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const Zoom = () => {
  const style = useStyles();

  return (
    <div className={style.container}>
      <img src="images/zoomIn.png" className={style.zoomIn} />
      <img src="images/zoomOut.png" className={style.zoomOut} />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    position: "absolute",
    bottom: 40,
    right: 50,
    height: 174,
    width: 86,
    borderRadius: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "white",
    alignItems: "center",
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
  },
  zoomIn: {
    height: 70,
  },
  zoomOut: {
    height: 70,
  },
});

export default Zoom;
