import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../Components/Card";
import Home from "../Components/Home";
import InputLogin from "../Components/InputLogin";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { useFirebase } from "../hooks/useFirebase";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  getDoc,
  query,
  getdoc,
  onSnapshot,
  updateDoc,
  arrayUnion,
  where,
  addDoc,
} from "@firebase/firestore";
import { useNavigate } from "react-router-dom";

const colors = {
  viande: "#B53729",
  poissons: "#388ADC",
  laitier: "#F6C745",
  frais: "#61B194",
  epicerie: "#DFA265",
  vin: "#E3E3E3",
};

const ProduitsEdit = () => {
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [product, setProduct] = useState();
  const [weight, setWeight] = useState();
  const [price, setPrice] = useState();
  const [showInputs, setShowInputs] = useState(true);
  const [showStats, setShowStats] = useState(false);
  const [userSelected, setUserSelected] = useState("");
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [isInscription, setIsInscription] = useState(false);
  const [dataInput, setDataInput] = useState([]);
  const [unitSelected, setUnitSelected] = useState("kg");
  const [editIndex, setEditIndex] = useState();
  const style = useStyles();
  const { user, db } = useFirebase();
  const navigate = useNavigate();

  const unit = [
    { label: "Kilogrammes", value: "kg" },
    { label: "Grammes", value: "g" },
    { label: "Litres", value: "L" },
    { label: "Bottes", value: "bottes" },
    { label: "Pièces", value: "pièces" },
  ];

  const productType = window.location.hash.substring(1);
  const autocompleteRef = useRef();

  useEffect(() => {
    console.log(autocompleteRef.current);
  }, [autocompleteRef]);
  useEffect(() => {
    if (window.location.href.includes("inscription")) {
      setIsInscription(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const q = query(
        collection(db, "produits"),
        where("type", "==", productType)
      );
      const querySnap = await getDocs(q);

      onSnapshot(q, async (snap) => {
        snap.forEach((el) => {
          setDataInput((prev) => [...prev, el.data()]);
        });
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const productRef = collection(db, "users", user.id, "produits_courants");
      const q = query(doc(productRef, productType));

      onSnapshot(q, async (snap) => {
        if (snap.data().produits) {
          setProducts((prev) => [...snap.data().produits]);
        }
      });
    })();
  }, []);

  const handleSubmit = async () => {
    if (!product || !weight || !price) {
      window.alert("Tous les champs sont requis");
      return;
    }
    if (editIndex) {
      await handleDelete(editIndex);
    }
    const productRef = collection(user.ref, "produits_courants");
    const tmpData = {
      name: product,
      weight: weight,
      unit: unitSelected,
      price,
    };
    try {
      await updateDoc(doc(productRef, productType), {
        produits: arrayUnion(tmpData),
      });
      setProduct(null);
      setWeight(0);
      setPrice(0);
    } catch (e) {
      await setDoc(doc(productRef, productType), {
        produits: arrayUnion(tmpData),
      });
      setProduct(null);
      setWeight(0);
      setPrice(0);
    }
    try {
      autocompleteRef.current
        .getElementsByClassName("MuiAutocomplete-clearIndicator")[0]
        .click();
    } catch (error) {
      console.log("Error: ", error);
    }
    const q = query(collection(db, "produits"), where("name", "==", product));
    const querySnap = await getDocs(q);
    setEditIndex(false);
    if (querySnap.size === 0) {
      await addDoc(collection(db, "produits"), {
        name: product,
        type: productType,
      });
    }
  };

  const handleDelete = async (index) => {
    const tmp = products;
    tmp.splice(index, 1);
    const productRef = collection(db, "users", user.id, "produits_courants");
    await updateDoc(doc(productRef, productType), {
      produits: tmp,
    });
  };

  const handleEdit = async (index) => {
    setEditIndex(index);

    setProduct(products[index].name);
    setWeight(products[index].weight);
    setUnitSelected(products[index].unit);
    setPrice(products[index].price);
  };

  return (
    <div className={style.container}>
      <p
        className={style.title}
        style={{ backgroundColor: colors[productType] }}
      >
        {productType}
      </p>
      <div className={style.leftContainer}>
        <div className={style.table}>
          <div className={style.tableTitleContainer}>
            <p className={style.tableTitle}>Historique</p>
          </div>
          <div className={style.tableTop}>
            <p className={style.tableTopName}>Produit:</p>
            <p className={style.tableTopId}>Quantité:</p>
            <p className={style.tableTopPassword}>Prix:</p>
          </div>
          <div className={style.tableBottom}>
            {products.map((el, index) => {
              return (
                <div
                  className={style.userContainer}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "rgba(0,0,0,0.07)" : "white",
                  }}
                  onClick={() => {
                    setUserSelected(el.id);
                    setShowInputs(false);
                    setShowStats(true);
                  }}
                >
                  <p className={style.userName}>{el.name}</p>
                  <p className={style.userId}>{el.weight}</p>
                  <p
                    className={style.userPasswordContainer}
                    id={"userContainer"}
                  >
                    {el.price}
                  </p>
                  <img
                    src={
                      !isInscription
                        ? "../images/pen.png"
                        : "../../../images/pen.png"
                    }
                    className={style.delete}
                    style={{ left: "87%" }}
                    onClick={() => handleEdit(index)}
                  />
                  <img
                    src={
                      !isInscription
                        ? "../images/close-circle.png"
                        : "../../../images/close-circle.png"
                    }
                    className={style.delete}
                    onClick={() => handleDelete(index)}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <button className={style.validateButton} onClick={() => navigate(-1)}>
          Valider
        </button>
      </div>
      <div className={style.rightContainer}>
        <div className={style.rightContainerCenter}>
          <div style={{ width: "100%" }}>
            <div className={style.add_mate}>
              <div className={style.add_mate_table}>
                <p className={style.titleInput}>Nom du produit:</p>
                <Autocomplete
                  freeSolo
                  ref={autocompleteRef}
                  options={dataInput.map((option) => option.name)}
                  // defaultValue={product}
                  value={product}
                  onChange={(e, value) => {
                    setProduct(value);
                  }}
                  getOptionLabel={(option) => option}
                  inputValue={product}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={product}
                      onChange={(e) => setProduct(e.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        className: style.add_mate_input,
                      }}
                    />
                  )}
                />
                <p className={style.titleInput}>Poids:</p>
                <div className={style.selectContainer}>
                  <input
                    type="text"
                    className={style.add_mate_input}
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                    style={{ width: "50%" }}
                  />
                  <select
                    className={style.select}
                    value={unitSelected}
                    onChange={(e) => {
                      setUnitSelected(e.target.value);
                    }}
                  >
                    {unit.map((el) => {
                      return <option value={el.value}>{el.label}</option>;
                    })}
                  </select>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p className={style.titleInput}>Prix actuel</p>
                  <img
                    src={
                      !isInscription
                        ? "../images/info.png"
                        : "../../../images/info.png"
                    }
                    style={{ height: 25, width: 25, margin: "0 8px 0 8px" }}
                    onClick={() => {
                      setShowInputs(true);
                      setShowStats(false);
                    }}
                  />
                  <p>:</p>
                </div>
                <input
                  type="number"
                  className={style.add_mate_input}
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className={style.add_mate_button_container}>
                <button
                  className={style.submitButton}
                  onClick={() => handleSubmit()}
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    height: "100vh",
    backgroundSize: "cover",
    fontFamily: "poppins-regular",
    fontSize: 20,
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    maxHeight: "100vh",
    width: "50%",
    overflow: "auto",
  },
  leftContainerCenter: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
  },
  leftTitle: {
    fontSize: 30,
    margin: "50px 0 80px",
  },
  title: {
    position: "absolute",
    fontSize: 30,
    top: "-2%",
    left: 100,
    backgroundColor: "#B53729",
    height: 60,
    width: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    borderRadius: 5,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    width: "100%",
    height: 50,
    backgroundColor: "#EEEEEE",
    border: "none",
    borderRadius: 10,
    margin: "10px 0 35px",
    textIndent: 10,
    "&::placeholder": {
      fontFamily: "poppins-regular",
      fontSize: 18,
      color: "##707070",
      marginLeft: 20,
    },
  },
  leftButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  rightContainer: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightContainerCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "70%",
  },
  table: {
    width: "85%",
    maxHeight: "70%",
    overflow: "auto",
    borderRadius: 25,
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
  },
  tableTop: {
    display: "flex",
    width: "100%",
    height: 100,
    position: "relative",
    borderBottom: "1px solid #E3E3E3",
    fontFamily: "poppins-bold",
    fontSize: 18,
    backgroundColor: "rgba(56, 138, 220, 0.2)",
  },
  tableTopPassword: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "70%",
    top: "50%",
    margin: 0,
  },
  tableTopId: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "40%",
    top: "50%",
    margin: 0,
  },
  tableTopName: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "10%",
    top: "50%",
    margin: 0,
  },
  tableBottom: {
    maxHeight: "100%",
  },
  userContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 60,
    position: "relative",
  },
  userPasswordContainer: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    transform: "translate(-50%, 0)",
    // top: "50%",
    left: "70%",
  },
  hideContainer: {
    position: "relative",
    width: 113,
  },
  hideContainerImg: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  hideContainerText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    margin: 0,
    fontFamily: "poppins-regular",
    fontSize: 18,
  },
  userId: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "40%",
    top: "50%",
    margin: 0,
    fontFamily: "poppins-regular",
    fontSize: 18,
  },
  userName: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "10%",
    top: "50%",
    margin: 0,
    fontSize: 18,
  },
  passwordImg: {
    width: 33.7,
    height: 27,
    marginLeft: 15,
  },
  delete: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "92%",
    width: 20,
    height: 20,
    cursor: "pointer",
  },
  nextButtonContainer: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    bottom: 0,
    right: 0,
    width: 332,
    "&:hover": {
      cursor: "pointer",
    },
  },
  plusButton: {
    height: 80,
    width: 80,
    cursor: "pointer",
  },
  button_container: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: 50,
  },
  add_mate: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    overflow: "auto",
    borderRadius: 15,
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
    maxHeight: "70%",
    padding: 20,
  },
  add_mate_table: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: 30,
    paddingBottom: 0,
  },
  add_mate_input: {
    border: "none",
    borderRadius: 10,
    backgroundColor: "#F2F2F2",
    height: 60,
    width: "100%",
    marginBottom: 30,
  },
  add_mate_button_container: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 20,
  },
  add_mate_button: {
    height: 40,
    width: 137,
    fontSize: 18,
    backgroundColor: "#388ADC",
    borderRadius: 50,
    border: "none",
    color: "white",
    cursor: "pointer",
  },
  stats_button: {
    width: 224,
    height: 84,
    fontSize: 18,
    color: "white",
    backgroundColor: "#F6C745",
    borderRadius: 10,
    border: "none",
    cursor: "pointer",
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
  },
  titleInput: {
    margin: 0,
    fontSize: 25,
  },
  tableTitle: {
    fontSize: 30,
    color: "#388ADC",
    marginLeft: 20,
  },
  validateButton: {
    position: "absolute",
    bottom: 20,
    right: 60,
    backgroundColor: "#388ADC",
    color: "white",
    border: "none",
    borderRadius: 5,
    height: 60,
    width: 250,
    fontSize: 20,
    cursor: "pointer",
  },
  submitButton: {
    backgroundColor: "#388ADC",
    color: "white",
    border: "none",
    borderRadius: 5,
    height: 60,
    width: 250,
    fontSize: 20,
    alignSelf: "flex-end",
  },
  selectContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  select: {
    width: "35%",
    height: 65,
    border: "2px solid rgba(0,0,0,0.1)",
    borderRadius: 10,
  },
});

export default ProduitsEdit;
