import React, { useState, useEffect } from "react";
import Home from "../../Components/Home";
import { makeStyles } from "@material-ui/core/styles";
import Calendar from "react-calendar";
import "./../../Calendar.css";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import useFirebase from "../../hooks/useFirebase";
import { addDoc, collection, doc, getDoc } from "@firebase/firestore";
import { toast, Toaster } from "react-hot-toast";

const RendezVous = () => {
  const style = useStyles();
  const [value, setValue] = useState(new Date());
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const { db, user } = useFirebase();
  const [fournisseur, setFournisseur] = useState(null);
  const { offerId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const docRef = doc(db, "devis", offerId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const fournisseurRef = await getDoc(data.fournisseur);
        const fournisseur = fournisseurRef.data();
        setFournisseur(fournisseur);
        setLoading(false);
      } else {
        // toast.error("Ce devis n'existe pas");
        navigate("/dashboard");
      }
    })();
  }, []);

  const handleConfirm = async () => {
    try {
      if (start && end && message && value) {
        const docRef = doc(db, "devis", offerId);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();

        const meeting = {
          date: moment(value).format("YYYY-MM-DD"),
          start: start,
          end: end,
          message: message,
          status: "sent",
          restaurateur: user.ref,
          fournisseur: data.fournisseur,
          devisRef: docRef,
        };
        const docW = await addDoc(collection(db, "rendez_vous"), meeting);
        navigate("/propositions");
      } else {
        toast.error("Veuillez remplir tous les champs");
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <div className={style.container}>
      <Toaster />
      <Home />
      <p className={style.title}>Ajouter un rendez-vous</p>
      <div className={style.centerContainer}>
        <div className={style.centerContainerLeft}>
          <Calendar onChange={setValue} value={value} />
        </div>
        <div className={style.centerContainerRight}>
          <div className={style.centerContainerRightTop}>
            <p className={style.centerContainerRightTopTitle}>Heure</p>
            <p>De</p>
            <input
              className={style.input}
              type={"time"}
              onChange={(e) => {
                setStart(e.target.value);
              }}
            />
            <p>À</p>

            <input
              className={style.input}
              type={"time"}
              onChange={(e) => setEnd(e.target.value)}
            />
          </div>
          <div className={style.centerContainerRightMiddle}>
            <p className={style.centerContainerRightTopTitle}>Détails</p>
            <p className={style.centerContainerRightTopTitle}>
              Fournisseur:{" "}
              {!loading
                ? fournisseur?.name + " " + fournisseur?.firstname
                : null}
            </p>
            <textarea
              className={style.textarea}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <button onClick={handleConfirm} className={style.button}>
            Proposition de rendez-vous
          </button>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
  },
  title: {
    position: "absolute",
    top: 0,
    left: 100,
    fontSize: 30,
  },
  centerContainer: {
    height: "70%",
    width: "70%",
    display: "flex",
    flexDirection: "row",
  },
  centerContainerLeft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    width: "50%",
    padding: "50px 60px 50px 60px",
  },
  centerContainerRight: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centerContainerRightTop: {
    backgroundColor: "white",
    padding: 30,
    width: "60%",
    borderRadius: 20,
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
  },
  input: {
    backgroundColor: "rgb(0,0,0, 0.08)",
    border: "none",
    borderRadius: 50,
    width: "100%",
    height: 30,
    marginBottom: 20,
  },
  centerContainerRightTopTitle: {
    margin: 0,
    marginBottom: 20,
    fontSize: 20,
  },
  centerContainerRightMiddle: {
    backgroundColor: "white",
    padding: 30,
    width: "60%",
    borderRadius: 20,
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
  },
  textarea: {
    backgroundColor: "rgb(0,0,0, 0.08)",
    border: "none",
    borderRadius: 10,
    width: "100%",
    minWidth: "100%",
    maxWidth: "100%",
    height: 100,
    minHeight: 100,
    maxHeight: 100,
  },
  button: {
    backgroundColor: "#388ADC",
    borderRadius: 10,
    width: "70%",
    height: 60,
    border: "none",
    color: "white",
    fontSize: 18,
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
  },
});

export default RendezVous;
