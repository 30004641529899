function reducer(state, action) {
  switch (action.type) {
    case "text":
      return {
        ...state,
        [action.key]: action.value,
      };
    default:
      return {
        ...action,
      };
  }
}

export default reducer;
