import axios from "axios";

const useLocation = () => {
  const getLocation = async (withAdress = false) => {
    if ("permissions" in navigator) {
      const {state} = await navigator.permissions.query({
        name: "geolocation",
      });
      if (state === "denied") {
        return Promise.reject("Vous devez autoriser la géolocalisation");
      }
      let location = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      let city = null;
      if (withAdress) {
        city = await getCity(
          location.coords.latitude,
          location.coords.longitude
        );
      }
      return {location, city};
    }
  };

  const getCity = async (lat, lng) => {
    const address = await axios.get(
      "https://api-adresse.data.gouv.fr/reverse/?lon=" + lng + "&lat=" + lat
    );
    return address.data.features[0].properties.city;
  };

  return {getLocation, getCity};
};

export default useLocation;
