import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLogin from "../Components/InputLogin";
import Home from "../Components/Home";
import { useNavigate } from "react-router-dom";

const AjouterRendezVous = () => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <Home />
      <div className={style.topContainer}>
        <p className={style.title}>Ajouter un rendez-vous</p>
      </div>
      <div style={{ display: "flex", marginTop: "3%" }}>
        <div className={style.topContainerLeft}>
          <div className={style.container4}>
            <img src="images/calendar.png" className={style.calendar} />
          </div>
        </div>
        <div className={style.topContainerRight}>
          <div className={style.container2}>
            <label
              style={{
                marginTop: "5%",
                marginLeft: "5%",
              }}
            >
              Heure
            </label>
            <input
              style={{
                marginTop: "3%",
                backgroundColor: "gainsboro",
                marginLeft: "5%",
                marginRight: "5%",
                padding: "3%",
                borderRadius: 20,
                borderStyle: "none",
              }}
            />
          </div>
          <div className={style.container3}>
            <label style={{ marginTop: "5%", marginLeft: "5%" }}>Détails</label>
            <label style={{ marginTop: "5%", marginLeft: "5%" }}>
              Fournisseur : Lorem ipsum
            </label>
            <input
              style={{
                marginTop: "3%",
                backgroundColor: "gainsboro",
                marginLeft: "5%",
                marginRight: "5%",
                padding: "10%",
                borderRadius: 20,
                borderStyle: "none",
              }}
            />
          </div>
          <div className={style.nextButton}>
            <InputLogin
              text={"Proposition de rendez-vous"}
              changeStyle={{
                backgroundColor: "#388ADC",
                width: 400,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    fontFamily: "poppins-regular",
  },
  container2: {
    display: "flex",
    flexDirection: "column",
    height: 120,
    width: 400,
    borderRadius: 20,
    padding: 10,
    backgroundColor: "white",
    color: "black",
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
    fontFamily: "poppins-regular",
  },
  container3: {
    display: "flex",
    flexDirection: "column",
    height: 200,
    width: 400,
    borderRadius: 20,
    padding: 10,
    backgroundColor: "white",
    color: "black",
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
    fontFamily: "poppins-regular",
    marginTop: "3%",
  },
  container4: {
    height: 450,
    width: 450,
    borderRadius: 20,
    padding: 10,
    backgroundColor: "white",
    color: "black",
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
    fontFamily: "poppins-regular",
    marginTop: "5%",
    marginLeft: "5%",
  },
  title: {
    fontSize: 25,
    margin: "50px 0 0 50px",
    marginLeft: 70,
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: 25,
  },
  middleContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    marginTop: 100,
  },
  productContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "13%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    height: 88,
    width: "100%",
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
  },
  bottomContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexGrow: 1,
    margin: "0 25px 25px",
  },
  nextButton: {
    "&:hover": {
      cursor: "pointer",
    },
    marginTop: "3%",
    padding: 10,
  },
  calendar: {
    height: 450,
    width: 450,
  },
  topContainerLeft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "55%",
    position: "relative",
  },
  topContainerRight: {
    justifyContent: "center",
    alignItems: "center",
    width: "45%",
    height: "100%",
    position: "relative",
  },
});

export default AjouterRendezVous;
