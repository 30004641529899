import React from "react";

const TextConditions = () => {
  return (
    <p>
      PROSPECTION COMMERCIALE France Société par actions simplifiée à associé
      unique n°… , 128 rue des Tacounet, 74190 Passy <br></br>
      <br></br>Adresse électronique : @prospectioncommerciale.fr <br></br>
      <br></br>1. Objet<br></br>La société Prospection commerciale France
      (ci-après « PROSPECTION COMMERCIALE ») propose un service permettant aux
      professionnels de la restauration ou de l’alimentaire (ci-après les «
      Restaurateurs ») de communiquer et de transmettre leurs produits courants,
      chiffrer auprès de fournisseur ci-après les « Fournisseurs ») (ci-après
      les « Services »). <br></br>
      <br></br>Les Services sont accessibles par le biais d’une application
      (ci-après l’« Application »). <br></br>
      <br></br>Les présentes conditions générales (ci-après les « Conditions
      Générales ») ont pour objet de définir les modalités et conditions de
      fourniture des Services au bénéfice du Restaurateur ou du Fournisseur les
      ayant acceptées et utilisant les Services (l’ « Utilisateur»). <br></br>
      <br></br>Les Conditions Générales prévalent sur toutes autres conditions
      générales ou particulières non expressément agréées par PROSPECTION
      COMMERCIALE. Elles peuvent être complétées le cas échéant par des
      conditions d’utilisation particulières à certains services offerts sur
      l'Application, lesquelles complètent les présentes Conditions Générales
      et, en cas de contradiction, prévalent sur ces dernières. <br></br>
      <br></br>2. Accès au Site et aux Services <br></br>2.1 Capacité juridique{" "}
      <br></br>L’Application et les Services sont accessibles : À toute personne
      physique ayant la qualité de commerçant disposant de la pleine capacité
      juridique pour s’engager au titre des présentes conditions générales. La
      personne physique qui ne dispose pas de la pleine capacité juridique ne
      peut accéder à l’Application et aux Services qu’avec l’accord de son
      représentant légal ; À toute personne morale agissant par l’intermédiaire
      d’une personne physique disposant de la capacité juridique et de
      l’autorité ou des pouvoirs nécessaires pour contracter au nom et pour le
      compte de la personne morale. 2.2 Application et Services réservés aux
      professionnels L’Application et les Services sont conçus pour et destinés
      à un usage professionnel et s’adressent exclusivement aux professionnels,
      entendus comme toutes personnes physiques ou morales exerçant une activité
      rémunérée de façon non occasionnelle dans tous les secteurs d’activité de
      l’industrie et du commerce, dont notamment les secteurs de la restauration
      et de l’industrie agro-alimentaire. <br></br>
      <br></br>3. Inscription sur l’Application <br></br>3.1. Création du Compte{" "}
      <br></br>L’utilisation des Services nécessite que l’Utilisateur soit
      inscrit sur l’Application et accepte les présentes Conditions Générales.
      Un formulaire d’inscription est prévu à cet effet, dans lequel doit être
      complété l’ensemble des informations marquées comme obligatoires. Toute
      inscription incomplète ne sera pas validée et bloquée. PROSPECTION
      COMMERCIALE procède à l’inscription de l’Utilisateur à partir des
      informations communiquées par ce dernier à PROSPECTION COMMERCIALE.
      L’inscription entraîne automatiquement l’ouverture d’un compte au nom de
      l’Utilisateur (ci-après le « Compte ») qui lui permet de gérer son
      utilisation des Services sous une forme et selon les moyens techniques que
      PROSPECTION COMMERCIALE juge les plus appropriés pour rendre lesdits
      Services. L’Utilisateur garantit que toutes les informations indiquées
      dans le formulaire d’inscription sont exactes, à jour et sincères et ne
      sont entachées d’aucun caractère trompeur. Il s’engage à mettre à jour ces
      informations sur son Compte en cas de modifications, afin qu’elles
      correspondent toujours aux critères susvisés. L’Utilisateur accepte que
      les informations saisies aux fins de création et dès la création de son
      mot de passe valent preuve de son identité et pour toutes connexions à
      l’application PROSPECTION COMMERCIALE. Les informations relatives à
      l’Utilisateur l’engagent dès son acceptation des conditions générales.
      <br></br>
      <br></br>3.2. Modalités d’utilisation du Compte ‍À chaque fois que
      l’Utilisateur souhaite accéder à son Compte, il doit renseigner :{" "}
      <br></br>
      <br></br>Son identifiant : il s’agit du numéro de téléphone ou adresse
      e-mail propre à l’Utilisateur, tel que renseigné lors de l’inscription ;{" "}
      <br></br>
      <br></br>Son mot de passe : il s’agit d’un code à 4 (quatre) chiffres
      minium à usage unique et modifiable uniquement par l’Utilisateur.{" "}
      <br></br>
      <br></br>Sous réserve de la disponibilité de cette fonctionnalité,
      l’Utilisateur qui procède à l’inscription ou à une connexion a la
      possibilité de solliciter un administrateur de PROSPECTION COMMERCIALE par
      téléphone ou par e-mail (ci-après l’« Administrateur »). L’Administrateur
      a la possibilité de créer plusieurs profils Utilisateurs et devra
      communiquer à PROSPECTION COMMERCIALE, par tous moyens écrits utiles, les
      noms, numéros de téléphone et éventuellement adresses email de
      l’Administrateur et ceux des Utilisateurs ou de l’Utilisateur inscrit(s)
      et/ ayant bénéficier d’un nouveau mot de passe. L’Utilisateur ou les
      Utilisateurs du Compte souhaitant accéder aux Services recevra / recevront
      un mot de passe par SMS sur le numéro de téléphone communiqué ou par
      e-mail par l’Administrateur de PROSPECTION COMMERCIALE. <br></br>
      <br></br>L’Administrateur est seul responsable de la création de ces
      profils et des Utilisateurs auxquels il les attribue en cas de problème de
      connexion ou d’inscription. Il s’interdit d’utiliser la faculté de
      création d’un profil Utilisateur afin de donner accès à l’Application,
      directement ou indirectement, à un tiers concurrent de PROSPECTION
      COMMERCIALE. Il s’engage à veiller à ce que les Utilisateurs ne permettent
      à aucun tiers de les utiliser à leur place ou pour leur compte, sauf à en
      supporter l’entière responsabilité.
      <br></br>
      <br></br>En tout état de cause, les identifiants de connexion sont
      strictement personnels et confidentiels. L’Utilisateur s’engage à
      préserver la sécurité et à veiller à la confidentialité de l’identifiant
      de son Compte et, le cas échéant, à imposer aux Utilisateurs dont il aura
      créé le profil les mêmes obligations. Il s’engage à prendre toute mesure
      appropriée à cet égard. <br></br>
      <br></br>Tout accès à l’Application ou au Compte effectué à l’aide des
      identifiants de l’Utilisateur sera réputé effectué par l’Utilisateur.
      Celui-ci doit informer PROSPECTION COMMERCIALE sans délai s’il remarque
      que son Compte a été utilisé à son insu. Il reconnaît à PROSPECTION
      COMMERCIALE le droit de prendre toutes mesures appropriées en pareil cas.
      En cas de faille de sécurité due au non-respect de cet article par
      l’Utilisateur, ce dernier sera seul responsable et ne pourra en aucun cas
      engager la responsabilité de PROSPECTION COMMERCIALE. <br></br>
      <br></br>4. Description des Services <br></br>PROSPECTION COMMERCIALE
      concède à l’Utilisateur, pour le monde entier et pendant toute la durée
      des Services telle que prévue à l’article « Durée des Services,
      désinscription », une licence non exclusive, personnelle et non
      transmissible d’utilisation de l’Application, dans sa version existante à
      la date des présentes et dans toutes éventuelles versions à venir, aux
      seules fins de la fourniture des Services.
      <br></br>
      <br></br>L’Utilisateur a accès aux Services de devis et/ou devis validé et
      de rendez-vous attribué, modifié, supprimé sous une forme et selon les
      fonctionnalités et moyens techniques que PROSPECTION COMMERCIALE juge les
      plus appropriés : Le service de devis permet : <br></br>
      <br></br>(i) Au Restaurateur de communiquer et de déposer directement en
      ligne et sur Qr-code nominatif un appel d’offre. <br></br>
      <br></br>(ii) Au Fournisseur de recevoir les appels d’offres du
      Restaurateur directement ; sous forme centralisée, dématérialisée et de
      manière instantanée ; et sous forme centralisée, dématérialisée et de
      manière instantanée en flashant son Qr-code. <br></br>
      <br></br>PROSPECTION COMMERCIALE ne procède à aucune vérification
      concernant les appels d’offres et/ou devis et n’est pas partie au contrat
      liant le Restaurant et le Fournisseur. <br></br>
      <br></br>Les conversations par le biais du service de messagerie
      s’effectuent entre le Restaurateur et le Fournisseur. <br></br>
      <br></br>Le Restaurateur peut initier une conversation qu’avec des
      Fournisseurs choisis (i) dont les coordonnées ont été renseignées dans son
      Compte à l’initiative du Fournisseur ou (ii) que le Restaurateur a invité
      à rejoindre en conversation par la validation de son devis sur
      l’Application et qui ont accepté ladite invitation. <br></br>
      <br></br>En outre, afin de faciliter le passage d’appel d’offre, le
      Restaurateur a la possibilité de renseigner des listes de produits qu’il
      commande habituellement auprès ses Fournisseur (ci-après les « Listes »)
      en sélectionnant sa Liste « de produit courant/familier », le Restaurateur
      n’a plus qu’à indiquer les quantités souhaitées et son prix souhaité. Le
      fournisseur identifie s’il peut faire le prix souhaité ou mieux.
      PROSPECTION COMMERCIALE ne collecte aucun paiement relatif aux appels
      d’offres et/ ou Devis sur l’Application PROSPECTION COMMERCIALE,
      PROSPECTION COMMERCIALE n’intervient pas dans la négociation de ces prix
      entre le Fournisseur et le Restaurateur. <br></br>
      <br></br>Pour se faire : <br></br>
      <br></br>Le Restaurateur ne communique en rien à PROSPECTION COMMERCIALE
      les coordonnées de ses Fournisseurs ; et/ou <br></br>
      <br></br>PROSPECTION COMMERCIALE ne paramètre pas le Compte avec les
      coordonnées ses Fournisseurs et les Listes par le Restaurateur.
      <br></br>
      <br></br>PROSPECTION COMMERCIALE se réserve la possibilité de proposer
      tout autre Service qu’elle jugera utile, sous une forme et selon les
      fonctionnalités et moyens techniques qu’elle estimera les plus appropriés
      pour rendre lesdits Services. <br></br>
      <br></br>PROSPECTION COMMERCIALE se réserve également la possibilité de
      modifier les fonctionnalités du Service ou de supprimer certaines
      informations accessibles à un Utilisateur (comme par exemple la Liste de
      produits d’un Fournisseur), notamment en cas d’obligation légale.
      PROSPECTION COMMERCIALE n’intervient pas dans les paiements de commande ni
      dans les livraisons des produits, qui sont négociés et réalisés
      directement entre le Restaurant et le Fournisseur. <br></br>
      <br></br>5. Prix des Services
      <br></br>Aucune commission n’est prélevée par PROSPECTION COMMERCIALE au
      Restaurateur pour la fourniture des Services. <br></br>
      <br></br>Aucune commission n’est prélevée par PROSPECTION COMMERCIALE au
      Fournisseur pour la fourniture des Services sur l’option découverte, une
      partie PRO & PRO+ offrant des services plus poussés et des annexes sont en
      option. <br></br>
      <br></br>6. Durée des Services, désinscription <br></br>Les Services sont
      souscrits pour une durée indéterminée. <br></br>
      <br></br>L’Utilisateur peut se désinscrire des Services à tout moment, en
      adressant une demande à cet effet à PROSPECTION COMMERCIALE par email, aux
      coordonnées mentionnées en tête des présentes. La désinscription est
      effective dans un délai maximum de 7 (sept) jours à compter de cette
      demande. Elle entraîne la suppression automatique du Compte de
      l’Utilisateur. <br></br>
      <br></br>PROSPECTION COMMERCIALE peut également résilier le présent
      contrat, fermer et supprimer le Compte d’un Utilisateur ayant violé les
      articles 2, 8, 10, 13 ou 15 des présentes Conditions Générales, si
      l’Utilisateur n’a pas remédié audit manquement dans un délai de sept (7)
      jours suivant l’envoi d’une notification écrite par email. Dans
      l’hypothèse où il ne pourrait être remédié au manquement, la résiliation
      interviendra dans un délai de 7 (sept) jours suivant l’envoi d’une
      notification par email. <br></br>
      <br></br>PROSPECTION COMMERCIALE pourra également résilier le présent
      contrat et clôturer le Compte d’un Utilisateur pour tout motif sous
      réserve d’un préavis minimum de 30 (trente) jours. <br></br>
      <br></br>En tout état de cause, PROSPECTION COMMERCIALE se réserve par
      ailleurs le droit de fermer et supprimer tout Compte qui resterait inactif
      pendant une durée continue de 6 (six) mois. <br></br>
      <br></br>7. Convention de preuve <br></br>7.1 L’Utilisateur reconnaît et
      accepte expressément : <br></br>
      <br></br>(i) que les données recueillies sur l’Application et les
      équipements informatiques de PROSPECTION COMMERCIALE, en ce compris les
      confirmations de transmission de commandes et les récapitulatifs de
      commande constituent des preuves de la réalité des transmissions de
      commande intervenues sur l’Application dans le cadre des présentes dont la
      valeur probante est reconnue par les parties, <br></br>
      <br></br>(ii) que ces données constituent le principal mode de preuve
      admis entre les parties. L’Utilisateur peut accéder à ces données sur son
      Compte. <br></br>
      <br></br>Dès lors, PROSPECTION COMMERCIALE, en plein accord avec les
      restaurateurs et les fournisseurs, pourra bénéficier de la valeur totale
      des devis validés sur l’application PROSPECTION COMMERCIALE ; afin de
      valoriser aux publics « la valeur significative » du montant totale des
      transactions faites sur l’Application PROSPECTION COMMERCIALE. <br></br>
      <br></br>Le Restaurateur s’engage à permettre à PROSPECTION COMMERCIALE de
      disposer pleinement de toutes ses informations personnelles qu’ils
      transmet sur l’Application PROSPECTION COMMERCIALE, son identité pour
      permettre à un fournisseur de connaître le tiers d’un appel d’offre avant
      et/ou après si le restaurateur valider le devis fournisseur, aussi d’être
      géolocaliser dès son inscription « pour qu’il puisse bénéficier de devis
      en ligne pour une démarche éco responsable et non véhiculé » aussi de ses
      noms de produits, poids et prix afin qu’il puissent être utilisés pour
      être visible en Appel(s) d’offre(s) en ligne et Qr-code, faire connaître
      son adresse à un fournisseur avant son choix de devis et après avoir
      proposé un rendez-vous à un fournisseur afin que celui-ci puisse savoir
      avec qui et où il a rendez-vous, et, également l'utilisation de ses prix
      fournies afin de permettre à PROSPECTION COMMERCIALE comme un système de
      référence naturel d'alimentaire ses cours du marché sur l’Application
      PROSPECTION COMMERCIALE comme une source de base de données. <br></br>
      <br></br>Le Fournisseur s’engage à uniquement permettre à PROSPECTION
      COMMERCIALE de divulguer son nom et prénom, éventuellement société et
      devis en réponse à un appel(s) offre(s) flasher/scanner en Qr-code ou
      qu’il y est bénéficié par une opération coup de poing en indirect et sans
      Qr-code dans le but de faire connaître son identité lors d’un devis validé
      d’un restaurateur et afin que le restaurateur puisse savoir avec qui il a
      rendez-vous et quand sur l’Application PROSPECTION COMMERCIALE. <br></br>
      <br></br>8. Obligations de l’Utilisateur
      <br></br>Sans préjudice des autres obligations prévues aux présentes,
      l’Utilisateur s’engage à respecter les obligations qui suivent : <br></br>
      <br></br>8.1 L’Utilisateur garantit disposer de tous les droits et
      autorisations nécessaires à la conclusion du présent contrat, ainsi que du
      pouvoir d’engager l’entité au nom de laquelle il agit. ‍<br></br>
      <br></br>8.2 Le Restaurateur s’engage à envoyer via l’Application des
      appels d’offres véridiques et sincères. <br></br>
      <br></br>Le Fournisseur s’engage à prendre connaissance des appels
      d’offres réalisées via l’Application dans les mêmes conditions que les
      autres applis d’offres passées en dehors de l’Application. ‍<br></br>
      <br></br>8.3 L’Utilisateur s’engage, dans son usage des Services, à
      respecter les lois et règlements en vigueur et à ne pas porter atteinte
      aux droits de tiers ou à l’ordre public. Il est seul responsable du bon
      accomplissement de toutes les formalités avec son utilisation des
      Services. La responsabilité de PROSPECTION COMMERCIALE ne pourra en aucun
      cas être engagée à ce titre. <br></br>
      <br></br>‍8.4 L’Utilisateur reconnaît avoir pris connaissance des
      caractéristiques et contraintes, notamment techniques, de l’ensemble des
      Services. Il est seul responsable de son utilisation des Services et
      notamment des relations qu’il pourra nouer avec les autres Utilisateurs ou
      avec des Fournisseurs et des informations qu’il leur communiquera dans le
      cadre des Services. Il lui appartient d’exercer la prudence et le
      discernement appropriés dans ces relations et communications.
      L’Utilisateur s’engage en outre, dans ses échanges avec les autres
      Utilisateurs ou avec les Fournisseurs, à respecter les règles usuelles de
      politesse et de courtoisie.<br></br>
      <br></br> ‍8.5 L’Utilisateur s’engage à faire un usage strictement
      personnel des Services. Il s’interdit en conséquence de céder, concéder ou
      transférer tout ou partie de ses droits ou obligations au titre des
      présentes à un tiers, de quelque manière que ce soit. <br></br>
      <br></br>‍8.6 L’Utilisateur s’engage à fournir à PROSPECTION COMMERCIALE
      toutes les informations nécessaires et, plus généralement, à coopérer
      activement avec PROSPECTION COMMERCIALE en vue de la bonne exécution des
      Services. ‍<br></br>
      <br></br>8.7 L’Utilisateur est autorisé de transmettre certains contenus
      avant la validation d’un rendez-vous que ce soit pour le restaurateur ou
      le fournisseur des informations de toute nature (nom, prénom d’un
      utilisateur ou fournisseur, nom de société commerciaux et/ou toutes
      entreprises que ce soient, rédactionnels, graphiques, audios, audiovisuels
      ou autres, en ce compris la dénomination et/ou l’image éventuellement
      choisies par l’Utilisateur pour identifier quiconque sur l’Application, le
      nom des Restaurateurs et Fournisseurs <br></br>
      <br></br>Les informations des tenues d’un restaurateur ciblant un
      fournisseur en dehors de la plate-forme ou dans l’application sont
      interdites : rendre nominatif une personne au descriptif d’un produit,
      d’un poids, d’un prix est strictement interdit pour un restaurateur, il
      engage dès lors sa pleine responsabilité civile et pénale. <br></br>
      <br></br>PROSPECTION COMMERCIALE pour la fourniture des Services (ci-après
      désignés les « Contenus et/ou Informations »). <br></br>
      <br></br>Il garantit à PROSPECTION COMMERCIALE qu’il dispose de tous les
      droits et autorisations nécessaires à la diffusion de ces Contenus et
      Informations. Il s’engage à ce que lesdits Contenus ou Informations soient
      licites, ne portent pas atteinte à l’ordre public, aux bonnes mœurs ou aux
      droits de tiers, qu’il s’agisse de droits de propriété intellectuelle ou
      de droits équivalents, de droits de la personnalité, de secrets d’affaires
      ou informations confidentielles, n’enfreignent aucune disposition
      législative ou règlementaire et plus généralement, ne soient aucunement
      susceptibles de mettre en jeu la responsabilité civile ou pénale de
      PROSPECTION COMMERCIALE. <br></br>
      <br></br>L’Utilisateur s’interdit ainsi de diffuser, notamment et sans que
      cette liste soit exhaustive : <br></br>
      <br></br>Des Contenus pornographiques, obscènes, indécents, choquants ou
      inadaptés à un public familial, diffamatoires, injurieux, violents,
      racistes, xénophobes ou révisionnistes, <br></br>
      <br></br>des Contenus contrefaisants, <br></br>
      <br></br>des Contenus attentatoires à l’image d’un tiers, des Informations
      en violation d’un secret d’affaires ou d’un engagement de confidentialité,{" "}
      <br></br>
      <br></br>des Contenus mensongers, trompeurs ou proposant ou promouvant des
      activités illicites, frauduleuses ou trompeuses, des Contenus nuisibles
      aux systèmes informatiques de tiers (tels que virus, vers, chevaux de
      Troie, etc.), <br></br>
      <br></br>et plus généralement des Contenus susceptibles de porter atteinte
      aux droits de tiers ou d’être préjudiciables à des tiers, de quelque
      manière et sous quelque forme que ce soit. ‍<br></br>
      <br></br>8.8 L’Utilisateur reconnaît que les Services lui offrent une
      solution supplémentaire, non exclusive, de communiquer et passer un ou des
      appel(s) d’offre(s) entre Restaurateurs et Fournisseurs et/ou de passer un
      ou des devis entre Fournisseurs et Restaurateurs et que cette solution ne
      saurait se substituer aux autres moyens dont l’Utilisateur peut disposer
      par ailleurs pour atteindre le même objectif. Les Restaurants et
      Fournisseurs restent libres d’avoir recours à d’autres modalités de
      passation de commande et d’utiliser ou non l’Application.<br></br>
      <br></br> ‍8.9 L’Utilisateur est informé et accepte que la mise en œuvre
      des Services nécessite qu’il soit connecté à internet et que la qualité
      des Services dépend directement de cette connexion, dont il est seul
      responsable. Par ailleurs, l’Application est évolutive et l’Utilisateur
      sera informé de toute nouvelle fonctionnalité ou de toute modification
      d'une fonctionnalité existante. L’Utilisateur reconnaît et accepte que la
      mise en œuvre des Services nécessite qu’il utilise les versions
      mises-à-jour de l’Application telles que fournies par PROSPECTION
      COMMERCIALE.<br></br>
      <br></br> ‍8.10 Les données personnelles traitées par les Utilisateurs
      dans le cadre de leurs échanges avec les autres Utilisateurs ou
      Fournisseurs et de la passation de commande sont de la responsabilité des
      Utilisateurs. <br></br>
      <br></br>9. Garantie de l’Utilisateur <br></br>L’Utilisateur garantit
      PROSPECTION COMMERCIALE contre toutes plaintes, réclamations, actions
      et/ou revendications quelconques que PROSPECTION COMMERCIALE pourrait
      subir du fait de la violation, par l’Utilisateur de l’une quelconque de
      ses obligations ou garanties aux termes des présentes conditions
      générales. <br></br>
      <br></br>Il s’engage à indemniser PROSPECTION COMMERCIALE de tout
      préjudice qu’elle subirait et à lui payer tous les frais, en ce compris
      frais d’avocats, charges et/ou condamnations qu’elle pourrait avoir à
      supporter de ce fait. <br></br>
      <br></br>10. Comportements prohibés
      <br></br>10.1 Il est strictement interdit d’utiliser l’Application et les
      Services aux fins suivantes : <br></br>
      <br></br>L’exercice d’activités illégales, frauduleuses ou portant
      atteinte aux droits ou à la sécurité des tiers, <br></br>
      <br></br>l’atteinte à l’ordre public ou la violation des lois et
      règlements en vigueur,
      <br></br>
      <br></br>l’intrusion dans le système informatique d’un tiers ou toute
      activité de nature à nuire, contrôler, interférer, ou intercepter tout ou
      partie du système informatique d’un tiers, en violer l’intégrité ou la
      sécurité,
      <br></br>
      <br></br>l’envoi d’emails non sollicités et/ou de prospection ou
      sollicitation commerciale, <br></br>
      <br></br>les manipulations destinées à améliorer le référencement d’un
      site tiers, <br></br>
      <br></br>l’utilisation de l’Application pour diffuser des informations ou
      liens redirigeant sur un site tiers, <br></br>
      <br></br>l’aide ou l’incitation, sous quelque forme et de quelque manière
      que ce soit, à un ou plusieurs des actes et activités décrits ci-dessus,{" "}
      <br></br>
      <br></br>et plus généralement toute pratique détournant les Services à des
      fins autres que celles pour lesquelles ils ont été conçus. <br></br>
      <br></br>10.2 Il est strictement interdit aux Utilisateurs et à toute
      personne de copier, extraire et/ou de détourner à leurs fins ou à celles
      de tiers le concept, les technologies, tout ou partie des données ou tout
      autre élément de l’Application de PROSPECTION COMMERCIALE. <br></br>
      <br></br>10.3 Sont également strictement interdits : (i) tous
      comportements de nature à interrompre, suspendre, ralentir ou empêcher la
      continuité des Services, (ii) toutes intrusions ou tentatives d’intrusions
      dans les systèmes de PROSPECTION COMMERCIALE, (iii) tous détournements des
      ressources système de l’Application, (iv) toutes actions de nature à
      imposer une charge disproportionnée sur les infrastructures de ce dernier,
      (v) toutes atteintes aux mesures de sécurité et d’authentification, (vi)
      tous actes de nature à porter atteinte aux droits et intérêts financiers,
      commerciaux ou moraux de PROSPECTION COMMERCIALE ou des usagers de son
      Application, et enfin plus généralement (vii) tout manquement aux
      présentes conditions générales. <br></br>
      <br></br>10.4 Il est strictement interdit de monnayer, vendre ou concéder
      tout ou partie de l’accès aux Services ou à l’Application, ainsi qu’aux
      informations qui y sont hébergées et/ou partagées. <br></br>
      <br></br>11. Sanctions des manquements <br></br>En cas de manquement à
      l’une quelconque des dispositions des présentes conditions générales ou
      plus généralement, d’infraction aux lois et règlements en vigueur par un
      Restaurant ou Fournisseur, PROSPECTION COMMERCIALE se réserve le droit de
      prendre toute mesure appropriée et notamment de : <br></br>
      <br></br>(i) Suspendre ou empêcher l’accès aux Services du Restaurant ou
      Fournisseur, auteur du manquement ou de l’infraction, ou y ayant
      participé, sous réserve d’une notification préalable. <br></br>
      <br></br>(ii) supprimer tout contenu mis en ligne sur l’Application,
      <br></br>
      <br></br>(iii) publier sur l’Application tout message d’information que
      PROSPECTION COMMERCIALE jugera utile, <br></br>
      <br></br>(iv) avertir toute autorité concernée, <br></br>
      <br></br>(v) engager toute action judiciaire. <br></br>
      <br></br>12. Responsabilité et garantie de PROSPECTION COMMERCIALE{" "}
      <br></br>
      <br></br>12.1 PROSPECTION COMMERCIALE s’engage à fournir les Services avec
      diligence et selon les règles de l’art, étant précisé qu’il pèse sur elle
      une obligation de moyens, à l’exclusion de toute obligation de résultat,
      ce que les Utilisateurs reconnaissent et acceptent expressément.<br></br>
      <br></br>12.2 PROSPECTION COMMERCIALE a connaissance et utilise les
      Contenus mis en ligne par les Restaurateurs, ce que les Utilisateurs
      restaurateurs reconnaissent et acceptent expressément. PROSPECTION
      COMMERCIALE n’a pas connaissance pour les Fournisseurs dans le cadre des
      Services et notamment dans ses communications Fournisseurs, sur lesquels
      elle n’effectue aucune modération, sélection, vérification ou contrôle
      d’aucune sorte et à l’égard desquels elle n’intervient qu’en tant que
      prestataire d’hébergement. En conséquence, PROSPECTION COMMERCIALE ne peut
      être tenue pour responsable des Contenus, dont les auteurs sont des tiers,
      toute réclamation éventuelle devant être dirigée en premier lieu vers
      l’auteur des Contenus en question. Les Contenus préjudiciables à un tiers
      peuvent faire l’objet d’une notification à PROSPECTION COMMERCIALE selon
      les modalités prévues par l’article 6 I 5 de la loi n° 2004-575 du 21 juin
      2004 pour la confiance dans l'économie numérique, PROSPECTION COMMERCIALE
      se réservant de prendre les mesures décrites à l’article « Sanctions des
      manquements ». ‍<br></br>
      <br></br>12.3 L’intervention de PROSPECTION COMMERCIALE s’applique à la
      mise en relation entre les Restaurants et Fournisseurs. PROSPECTION
      COMMERCIALE agit en son nom personnel et ne passe aucun acte juridique au
      nom et pour le compte des Restaurants ou des Fournisseurs, qui contractent
      directement entre eux. PROSPECTION COMMERCIALE n’est pas partie aux
      contrats conclus entre les Restaurants et les Fournisseurs et ne saurait
      en aucun cas voir sa responsabilité engagée au titre des difficultés
      pouvant intervenir lors de la conclusion ou de l’exécution de ces
      contrats, ni être partie à quelques litiges éventuels que ce soit entre
      les Restaurants et Fournisseurs concernant notamment les conditions et
      délais de livraison des produits, les garanties, les délais de paiement ou
      obligations de paiement, déclarations et autres obligations quelconques
      que les Restaurants et Fournisseurs auraient entre eux contractées.
      PROSPECTION COMMERCIALE ne procède, sans que la présente liste ne soit
      exhaustive, à aucune vérification de la qualité ou des caractéristiques
      des produits listés dans l’Application, à leur conformité aux lois et
      règlementations en vigueur, à leurs conditions de stockage ou de
      livraison, ou encore à la capacité financière et la solvabilité des
      Restaurateurs et sa responsabilité ne pourra être engagée à ce titre.
      Toutefois, dans un souci d’amélioration constante de la qualité des
      Services, PROSPECTION COMMERCIALE invite les Utilisateurs à lui faire part
      de tous les commentaires et informations qu’ils pourraient souhaiter
      porter à sa connaissance concernant la qualité des transactions réalisées
      par l’intermédiaire des Services.<br></br>
      <br></br> ‍12.4 PROSPECTION COMMERCIALE s’engage à procéder régulièrement
      à des contrôles techniques afin de vérifier le fonctionnement et
      l’accessibilité de l’Application. A ce titre, PROSPECTION COMMERCIALE se
      réserve la faculté d’interrompre momentanément l’accès à l’Application
      pour des raisons de maintenance. PROSPECTION COMMERCIALE ne saurait être
      tenue responsable des difficultés ou impossibilités momentanées d’accès au
      Site qui auraient pour origine des circonstances qui lui sont extérieures,
      la force majeure, ou encore qui seraient dues à des perturbations des
      réseaux de télécommunication.<br></br>
      <br></br> ‍12.5 PROSPECTION COMMERCIALE ne garantit pas aux Utilisateurs
      (i) que les Services, soumis à une recherche constante pour en améliorer
      notamment la performance et le progrès, seront totalement exempts
      d’erreurs, de vices ou défauts, (ii) que les Services, étant standard et
      nullement proposés à la seule intention d’un Utilisateur donné en fonction
      de ses propres contraintes personnelles, répondront spécifiquement à ses
      besoins et attentes.<br></br>
      <br></br> ‍12.6 En tout état de cause, la responsabilité susceptible
      d’être encourue par PROSPECTION COMMERCIALE au titre des présentes est
      expressément limitée aux seuls dommages directs avérés subis par
      l’Utilisateur. Sont notamment considérés comme des dommages indirects les
      pertes de recettes ou de bénéfices, pertes de données, préjudice d’image
      ou perte de clientèle. <br></br>
      <br></br>13. Propriété intellectuelle Les systèmes, logiciels, structures,
      infrastructures, bases de données et contenus de toute nature (textes,
      images, visuels, musiques, logos, marques, base de données, etc.)
      exploités par PROSPECTION COMMERCIALE au sein de l’Application sont
      protégés par tous droits de propriété intellectuelle ou droits des
      producteurs de bases de données en vigueur. Tous désassemblages,
      décompilations, décryptages, extractions, réutilisations, copies et plus
      généralement, tous actent de reproduction, représentation, diffusion et
      utilisation de l’un quelconque de ces éléments, en tout ou partie, sans
      l’autorisation de PROSPECTION COMMERCIALE sont strictement interdits et
      pourront faire l’objet de poursuites judiciaires. L’Utilisateur autorise
      expressément, PROSPECTION COMMERCIALE pendant toute la durée des Services,
      à le citer et à utiliser le cas échéant la reproduction de sa marque ou de
      son logo dans le cadre des Services, sur l’Application, notamment pour un
      Fournisseur afin de désigner sa Liste de produits, pour la durée du
      présent contrat. <br></br>
      <br></br>14. Données à caractère personnel Dispositions générales
      PROSPECTION COMMERCIALE et le Restaurateur s’engagent, chacun pour ce qui
      le concerne, à se conformer à la réglementation applicable aux données à
      caractère personnel et en particulier au règlement général sur la
      protection des données (règlement UE 2016/679 du Parlement européen et du
      Conseil du 27 avril 2016). Dans le cadre des Services, PROSPECTION
      COMMERCIALE est amené à traiter des données à caractère personnel. Les
      principales caractéristiques de ce traitement sont les suivantes :{" "}
      <br></br>
      <br></br>Finalité du traitement de données à caractère personnel :
      Réalisation des Services Type de données à caractère personnel traitées :
      Adresses email, numéro de téléphone. Catégories de personnes concernées:
      Fournisseurs du Restaurateur Durée du traitement: Durée des Services Lieu
      du traitement: Union Européenne Sous-traitant du Prestataire intervenant
      dans le traitement: AWS Le Restaurateur est le responsable de traitement
      de ces données et est tenu de s’acquitter des obligations qui lui
      reviennent en application du règlement précité, notamment en ce qui
      concerne l’obligation d’informer les personnes concernées, la tenue du
      registre des traitements mis en œuvre, et plus généralement, le respect
      des principes issus du règlement. Les données fournies par le Restaurateur
      à PROSPECTION COMMERCIALE aux fins d’exécution des Services demeurent la
      seule propriété et sous la seule responsabilité du Restaurateur.
      PROSPECTION COMMERCIALE intervient pour sa part exclusivement en qualité
      de sous-traitant des données à caractère personnel. <br></br>
      <br></br>Obligations du Prestataire vis-à-vis du Restaurateur Les
      traitements de données effectués par PROSPECTION COMMERCIALE pour le
      Restaurateur ont les caractéristiques suivantes : <br></br>
      <br></br>Objet du traitement : la fourniture des Services tel que défini
      dans ce document. Durée des traitements : la durée des services
      conformément à l’article 6 ci-dessus. La nature et la finalité du
      traitement : PROSPECTION COMMERCIALE recueille les données du Restaurateur
      afin de permettre les services de mise en relation avec les fournisseurs
      et la centralisation des commandes. Type de données à caractère personnel
      : données d’identification et données professionnelle (nom, prénom,
      adresse, nom de société, photo, téléphone, email, nom des produits : leurs
      poids et prix etc.). Catégories de personnes : restaurateurs et
      fournisseurs professionnels. Traitement des données : PROSPECTION
      COMMERCIALE s’engage à ne traiter les données à caractère personnel qu’aux
      fins d’exécution des Services et conformément aux instructions documentées
      du Restaurateur à travers les Services, y compris en ce qui concerne le
      transfert des données en dehors de l’Union européenne. Le Restaurateur
      s’engage à informer PROSPECTION COMMERCIALE si, selon lui, une instruction
      constitue une violation de la réglementation applicable. <br></br>
      <br></br>Sécurité et confidentialité des données : PROSPECTION COMMERCIALE
      s’engage à mettre en œuvre les mesures techniques et organisationnelles
      appropriées pour assurer la sécurité et l’intégrité des données à
      caractère personnel, leur sauvegarde ainsi que le rétablissement de leur
      disponibilité en cas d’incident physique ou technique. PROSPECTION
      COMMERCIALE veille également à ce que les personnes autorisées à traiter
      les données à caractère personnel soient soumises à l’obligation d’en
      préserver la confidentialité. <br></br>
      <br></br>s sous-traitants : Le Restaurateur consent par les présentes aux
      sous-traitants visés au tableau figurant au début de cet article 14.
      PROSPECTION COMMERCIALE peut également faire appel à un autre
      sous-traitant (ci-après « le sous-traitant ultérieur ») pour mener des
      activités de traitement spécifiques. Dans ce cas, il informe préalablement
      et par écrit le Restaurateur de tout changement envisagé concernant
      l’ajout ou le remplacement de sous-traitants ultérieurs. Cette information
      doit indiquer clairement les activités de traitement sous-traitées,
      l’identité et les coordonnées du sous-traitant ultérieur et les dates du
      contrat de sous-traitance. Le Restaurateur dispose d’un délai de 7 (sept)
      jours à compter de la date de réception de cette information pour
      présenter ses objections. Cette sous-traitance ne peut être effectuée que
      si le Restaurateur n'a pas émis d'objection motivée pendant le délai
      convenu. En cas d’objection, les Parties conviennent de se rencontrer dans
      les plus brefs délais pour envisager les issues possibles à cette
      objection. En cas de persistance de l’objection et d’impossibilité pour
      PROSPECTION COMMERCIALE de se passer de ce sous-traitant ultérieur,
      PROSPECTION COMMERCIALE pourra résilier les présentes Conditions Générales
      avec effet immédiat, sans responsabilité à l’égard de l’une ou l’autre des
      Parties.
      <br></br>
      <br></br>Le sous-traitant ultérieur est tenu de respecter les obligations
      de PROSPECTION COMMERCIALE pour le compte et selon les instructions du
      Restaurateur. Il appartient à PROSPECTION COMMERCIALE de s’assurer que le
      sous-traitant ultérieur présente les mêmes garanties suffisantes quant à
      la mise en œuvre de mesures techniques et organisationnelles appropriées
      de manière à ce que le traitement réponde aux exigences du RGPD. Si le
      sous-traitant ultérieur ne remplit pas ses obligations en matière de
      protection des données, PROSPECTION COMMERCIALE demeure pleinement
      responsable devant le Restaurateur de l’exécution par l’autre
      sous-traitant de ses obligations. <br></br>
      <br></br>Assistance et Fourniture d’informations : Au titre de ses
      obligations d’assistance (article 28.3 (e) et 28.3 (f) du RGPD),
      PROSPECTION COMMERCIALE s’engage à répondre à toute demande d’information
      lui étant adressée par le Restaurateur, que ce soit dans le cadre d’une
      demande d’exercice de leurs droits par les personnes concernées, d’une
      analyse d’impact, ou d’une demande présentée par les autorités de
      protection des données ou les délégués à la protection des données du
      Restaurateur. <br></br>
      <br></br>Notification des violations de données à caractère personnel :
      PROSPECTION COMMERCIALE s’engage à notifier au Restaurateur toute
      violation de données à caractère personnel dans un délai maximum de 72
      heures après en avoir pris connaissance. Dans le cas où le Restaurateur
      aurait lui-même à notifier cette violation à l’autorité de contrôle
      compétente, PROSPECTION COMMERCIALE s’engage à lui communiquer toute
      documentation utile. <br></br>
      <br></br>Conservation des données : ‍PROSPECTION COMMERCIALE s’engage à
      supprimer les données à caractère personnel à l’expiration du Contrat,
      quelle qu’en soit la cause, et à ne pas en conserver de copie, à moins que
      le droit applicable n'exige la conservation des données à caractère
      personnel en cause. <br></br>
      <br></br>Registre et documentation : PROSPECTION COMMERCIALE tient par
      écrit un registre des traitements effectués pour le compte de ses clients.
      Ce registre comprend en outre les informations afférentes à ces
      traitements. PROSPECTION COMMERCIALE met à disposition du Restaurateur,
      sur demande de celui-ci, toutes les informations nécessaires pour
      démontrer le respect de ses obligations. <br></br>
      <br></br>Audits Afin de démontrer sa conformité au présent article, à la
      demande du Restaurateur, PROSPECTION COMMERCIALE devra soit (i) mettre à
      disposition du Restaurateur les rapports d'audit effectués par des
      organismes d'audit indépendants tiers et toutes informations pertinentes
      fournies par ces organismes, le cas échéant, soit (ii) fournir toute
      information aux Restaurateurs afin de démontrer sa conformité. <br></br>
      <br></br>A défaut pour PROSPECTION COMMERCIALE de fournir au Restaurateur
      une preuve de sa conformité aux dispositions des présentes via la
      fourniture d’information ou via les rapports susmentionnées et/ou si le
      Restaurateur estime raisonnablement nécessaire d'effectuer un audit
      complémentaire conformément à la réglementation, pour pleinement vérifier
      la conformité des services fournis à la réglementation et au contrat,
      PROSPECTION COMMERCIALE accepte de se soumettre à un audit effectué par un
      auditeur indépendant réputé, ne concurrençant pas les activités
      commerciales de PROSPECTION COMMERCIALE, dans la limite d'un audit par an.
      Cet auditeur indépendant est choisi par le Restaurateur et accepté par
      PROSPECTION COMMERCIALE. Il possède les qualifications professionnelles
      requises et est soumis à un accord de confidentialité. Les parties
      reconnaissent que tous rapports et information obtenues dans le cadre de
      cet audit sont des informations confidentielles. <br></br>
      <br></br>La date de début de l'audit, la durée et le périmètre de l'audit
      sont définis d'un commun accord par les parties avec un préavis minimum de
      15 jours ouvrés. L'audit ne peut être effectué que durant les heures
      d'ouverture de PROSPECTION COMMERCIALE et d'une façon qui ne perturbe pas
      l'activité de PROSPECTION COMMERCIALE. L'audit ne comporte pas d'accès à
      tous systèmes, information, données non liées aux traitements effectués en
      vertu de ce contrat ni d'accès physique aux serveurs sur lesquels est
      sauvegardée l’Application. <br></br>
      <br></br>Le Restaurateur prend à sa charge tous les frais occasionnés par
      l'audit, incluant de manière non limitative les honoraires de l'auditeur
      et rembourse à PROSPECTION COMMERCIALE toutes les dépenses et frais
      occasionnés par cet audit, y compris le temps consacré à l'audit en
      fonction du taux horaire moyen du personnel de PROSPECTION COMMERCIALE
      ayant collaboré à l'audit. <br></br>
      <br></br>15. Confidentialité <br></br>PROSPECTION COMMERCIALE pour les
      fournisseurs, les parties conviennent d’ores et déjà expressément que
      cette obligation de confidentialité couvre les données à caractère
      personnel, hormis son identité : nom, prénom et entreprise, devis
      bénéficié en scanne /flash Qr-code ou par l’opération coup de poing en
      indirect, sans Qr-code que PROSPECTION COMMERCIALE sera amené à traiter
      pour l’Utilisateur dans le cadre des Services envers les restaurateurs de
      l’Application PROSPECTION COMMERCIALE. L’ensemble de ces informations est
      désigné ci-après les « Informations Confidentielles ». <br></br>
      <br></br>La partie qui reçoit des Informations Confidentielles s’engage à
      pouvoir les divulguer sans accord préalable de l’autre partie, pendant une
      durée de 5 (cinq) ans à compter de la fin de l’exécution des Services
      concernés. Elle ne pourra les transmettre à des employés, collaborateurs,
      stagiaires ou conseils que s’ils sont tenus à la même obligation de
      confidentialité que celle prévue aux présentes. Cette obligation s’étend
      aux documents, éléments, données et informations : <br></br>
      <br></br>(i) dont la partie qui les reçoit avait déjà connaissance ;{" "}
      <br></br>
      <br></br>(ii) déjà publics lors de leur communication ou qui le
      deviendraient sans violation des présentes conditions générales ;{" "}
      <br></br>
      <br></br>(iii) qui auraient été reçus d’un tiers de manière licite ;{" "}
      <br></br>
      <br></br>(iv) dont la communication serait exigée par les autorités
      judiciaires, en application des lois et règlements ou en vue d’établir les
      droits d’une partie au titre des présentes Conditions Générales. <br></br>
      <br></br>PROSPECTION COMMERCIALE pour les restaurateurs, les parties
      conviennent d’ores et déjà expressément que cette obligation de
      confidentialité couvre les données à caractère personnel, hormis le droit
      pour PROSPECTION COMMERCIALE d’utiliser les informations suivante : nom
      d’un produit et/ou des produit(s), d’un poids et/ou des poid(s), d’un prix
      et/ou des prix, d’un et/ou des restaurateur(s) pour enrichir sa base de
      données et faire bénéficier d’enrichir l’Application PROSPECTION
      COMMERCIALE d’un appel d’offre ou d’appels d’offres, de devis validé et
      d’alimenter et enrichir les cours du marché sur l’Application PROSPECTION
      COMMERCIALE, PROSPECTION COMMERCIALE sera amené à traiter pour
      l’Utilisateur dans le cadre des Services. L’ensemble de ces informations
      est désigné ci-après les « Informations Confidentielles ». <br></br>
      <br></br>Également, cette obligation s’étend aux documents, éléments,
      données et informations : <br></br>
      <br></br>(i) dont la partie qui les reçoit avait déjà connaissance ;{" "}
      <br></br>
      <br></br>(ii) déjà publics lors de leur communication ou qui le
      deviendraient sans violation des présentes conditions générales ;{" "}
      <br></br>
      <br></br>(iii) qui auraient été reçus d’un tiers de manière licite ;{" "}
      <br></br>
      <br></br>(iv) dont la communication serait exigée par les autorités
      judiciaires, en application des lois et règlements ou en vue d’établir les
      droits d’une partie au titre des présentes Conditions Générales. <br></br>
      <br></br>16. Références commerciales <br></br>L’Utilisateur autorise
      expressément PROSPECTION COMMERCIALE à le citer et à utiliser le cas
      échéant la reproduction de sa marque ou de son logo à titre de références
      commerciales, notamment lors de manifestations ou d’événements, dans ses
      documents commerciaux ou publicitaires, dans des articles, sur son site et
      sur l’Application, ainsi que sur des réseaux sociaux professionnels tels
      que Linkedin, sous quelque forme que ce soit. L’Utilisateur pourra à tout
      moment informer PROSPECTION COMMERCIALE qu’il refuse finalement
      l’utilisation de sa marque ou son logo par PROSPECTION COMMERCIALE et
      PROSPECTION COMMERCIALE cessera toute utilisation de sa marque ou logo
      pour le futur. <br></br>
      <br></br>17. Entrée en vigueur rétroactive des Conditions Générales
      <br></br>Une fois acceptées par l’Utilisateur, les présentes Conditions
      Générales seront applicables rétroactivement à compter de l’acceptation
      par l’Utilisateur de tester ou d’utiliser l’Application, que cette
      acceptation se matérialise de manière expresse ou par la première
      communication d’Informations ou de Contenus de l’Utilisateur à PROSPECTION
      COMMERCIALE aux fins de fourniture des Services par PROSPECTION
      COMMERCIALE. <br></br>
      <br></br>18. Modifications <br></br>PROSPECTION COMMERCIALE se réserve la
      faculté de modifier à tout moment les présentes Conditions Générales.
      L’Utilisateur sera informé de ces modifications ainsi que de leur date
      d’entrée en vigueur en avance, par tout moyen écrit dédié. <br></br>
      <br></br>L’Utilisateur qui n’accepte pas les conditions générales
      modifiées doit se désinscrire des Services selon les modalités prévues à
      l’article « Durée des Services, désinscription ». <br></br>
      <br></br>Tout Utilisateur qui a recours aux Services postérieurement à
      l’entrée en vigueur des conditions générales modifiées est réputé avoir
      accepté ces modifications. <br></br>
      <br></br>19. Loi applicable et juridiction <br></br>Les présentes
      conditions générales sont régies par la loi française. <br></br>
      <br></br>En cas de contestation sur la validité, l’interprétation et/ou
      l’exécution ou la résiliation des présentes Conditions Générales, les
      parties conviennent que le tribunal de commerce de Paris aura compétence
      exclusive, sauf règles de procédure impératives contraires.
    </p>
  );
};

export default TextConditions;
