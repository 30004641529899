import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import InputLogin from "../Components/InputLogin";

const FournisseurConnexion = () => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <div className={style.containerLeft}></div>
      <div className={style.containerRight}>
        <form className={style.form}>
          <p className={style.title}>Connexion</p>
          <label>Email</label>
          <input type="text" className={style.input}></input>
          <label>Mot de passe</label>
          <div className={style.oeilContainer}>
            <input
              type="text"
              className={style.input}
              style={{ marginBottom: 0 }}
            ></input>
            <img src="images/oeil.png" className={style.oeil} />
          </div>
          <div
            className={style.buttonContainer}
            onClick={() => {
              if (
                localStorage.getItem("navigationRegister1State") ==
                "salariéIndépConnexion"
              ) {
                navigate("/dashboardsalarieindep");
              } else if (
                localStorage.getItem("navigationRegister1State") ==
                "siègeSupConnexion"
              ) {
                navigate("/inscriptioncollaborateurs");
              } else {
                navigate("/dashboardsalarieindep1");
              }
            }}
          >
            <InputLogin
              text={"Connexion"}
              changeStyle={{
                backgroundColor: "#388ADC",
                color: "white",
                height: 70,
              }}
            />
          </div>

          <p style={{ marginTop: 60 }}>
            Je n'ai pas de compte ?{" "}
            <span
              style={{ color: "#388ADC" }}
              className={style.buttonContainer}
              onClick={() => navigate("/fournisseurinscription")}
            >
              S'inscrire.
            </span>
          </p>
        </form>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    fontFamily: "poppins-regular",
  },
  containerLeft: {
    width: "50%",
    height: "100%",
    backgroundImage: "url('images/fournisseurConnexion.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  containerRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    maxHeight: "100vh",
    overflow: "auto",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
  },
  title: {
    fontSize: 30,
  },
  oeilContainer: {
    display: "flex",
    alignItems: "center",
    height: "contents",
    width: "100%",
    position: "relative",
    marginBottom: 60,
  },
  oeil: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: 30,
    height: "3vh",
  },
  input: {
    backgroundColor: "#F2F2F2",
    borderRadius: 5,
    border: "none",
    height: "6vh",
    marginBottom: 60,
    width: "100%",
  },
  checkboxContainer: {
    display: "flex",
    marginBottom: 20,
  },
  buttonContainer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default FournisseurConnexion;
