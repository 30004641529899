import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";

const DefaultButton = ({ styleHandle, onClick, children }) => {
  const style = useStyles();

  return (
    <button className={style.button} onClick={onClick} style={styleHandle}>
      {children}
    </button>
  );
};

const useStyles = makeStyles({
  button: {
    backgroundColor: "#388ADC",
    color: "white",
    fontSize: 20,
    padding: "0 20px 0 20px",
    border: "none",
    borderRadius: 5,
    height: 50,
    width: 250,
    cursor: "pointer",
  },
});

export default DefaultButton;
