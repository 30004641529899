import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useNavigate} from "react-router-dom";
import Slider, {Range} from "rc-slider";
import "../Slider.css";
import {toast, Toaster} from "react-hot-toast";

const HandleSecteur = ({
  regions,
  setRegions,
  index,
  data,
  coor,
  zoomRadius,
  setZoomRadius,
  nbMAx,
  setRange,
  range,
  setNumber,
  setWant,
  setPrice,
  setRegion,
  number,
  want,
  price,
  region,
  handleModify,
  handleSubmit,
  handleDelete,
  edit,
}) => {
  const style = useStyles();
  const [hide, setHide] = useState(true);
  const navigate = useNavigate();

  // const handleEdit = () => {
  //   if (!region || !number || !want || !price) {
  //     toast.error("Veuillez remplir tous les champs !");
  //     return;
  //   }
  //   const tmp = regions;
  //   const samePos = tmp[index].pos;
  //   tmp.splice(index, 1);
  //   setRegions((prev) => [
  //     ...tmp,
  //     {
  //       region,
  //       range,
  //       number,
  //       want,
  //       price,
  //       pos: coor || samePos,
  //       date: new Date(),
  //     },
  //   ]);
  //   setHide(true);
  // };

  // const handleDelete = () => {
  //   const tmp = regions;
  //   tmp.splice(index, 1);
  //   setRegions((prev) => [...tmp]);
  //   setHide(true);
  // };

  useEffect(() => {
    setZoomRadius && setZoomRadius(range);
  }, [range]);

  return (
    <div
      className={style.container}
      style={hide ? {maxHeight: 55, overflow: "hidden"} : {}}
    >
      <div>
        <Toaster toastOptions={{style: {fontSize: 15}}} />
      </div>
      <div className={style.title}>
        {data ? (
          <div className={style.title_left_container}>
            <p className={style.title_text}>{data.region}</p>
          </div>
        ) : (
          <div className={style.title_left_container}>
            <p className={style.title_text}>
              {edit ? "Modifier mon secteur:" : "Créer mon secteur:"}
            </p>
            <img src="images/info.png" style={{height: 25}} />
          </div>
        )}
        <img
          src="images/down.png"
          className={style.arrow}
          style={{height: 15}}
          onClick={() => setHide((prev) => !prev)}
        />
      </div>
      <input
        type="text"
        placeholder="Bretagne"
        className={style.input_zone}
        value={region}
        onChange={(e) => setRegion(e.target.value)}
      />
      <div className={style.slider_container}>
        <p className={style.slider_text} style={{left: 0}}>
          0 km
        </p>
        <p className={style.slider_text} style={{right: 0}}>
          200 km
        </p>
        <Slider
          min={0}
          max={200}
          onChange={(e) => setRange(e)}
          value={range}
          defaultValue={data?.range}
        />
      </div>
      <div className={style.input_container}>
        <input
          className={style.input_container_int}
          type="number"
          style={{color: "#B53729"}}
          value={nbMAx}
          // onChange={(e) => setNumber(e.target.value)}
          disabled={true}
        />
        <p className={style.input_container_text} style={{color: "#B53729"}}>
          Nb max
        </p>
      </div>
      <div className={style.input_container}>
        <input
          type="number"
          className={style.input_container_int}
          value={want}
          onChange={(e) => setWant(e.target.value)}
        />
        <p className={style.input_container_text}>J'en souhaite:</p>
      </div>
      <div className={style.input_container}>
        <input
          type="number"
          className={style.input_container_int}
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <p className={style.input_container_text}>Prix:</p>
      </div>
      <div
        className={style.buttons_container}
        style={data && {justifyContent: "space-between"}}
      >
        <button
          className={style.validate}
          onClick={data ? handleModify : handleSubmit}
        >
          {data ? "Modifier ce secteur" : "Valider ce secteur"}
        </button>
        {data && (
          <img
            src="images/trash.png"
            className={style.logos}
            style={{height: 40, cursor: "pointer"}}
            onClick={handleDelete}
          />
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    padding: "0 20px 15px 20px",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    borderRadius: 20,
    marginTop: 50,
    marginBottom: 20,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title_left_container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title_text: {
    fontSize: 20,
    marginRight: 10,
  },
  arrow: {
    cursor: "pointer",
  },
  input_zone: {
    backgroundColor: "#EEEEEE",
    height: 61,
    width: "100%",
    border: "none",
    borderRadius: 15,
  },
  input_container: {
    position: "relative",
    backgroundColor: "blue",
    height: 60,
    marginTop: 20,
    border: "1px solid #707070",
    overflow: "hidden",
  },
  input_container_int: {
    width: "100%",
    height: 60,
    paddingLeft: 10,
    paddingTop: 8,
    border: "none",
    backgroundColor: "#ffff",
  },
  input_container_text: {
    position: "absolute",
    margin: 0,
    top: 0,
    left: 10,
    fontSize: 18,
    fontWeight: 600,
  },
  validate: {
    height: 42,
    backgroundColor: "#388ADC",
    color: "white",
    border: "none",
    padding: "0 20px 0 20px",
    borderRadius: 50,
    fontSize: 18,
    cursor: "pointer",
  },
  buttons_container: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "15px 0 15px 0",
  },
  slider_container: {
    position: "relative",
    margin: "45px 0 30px 0",
  },
  slider_text: {
    position: "absolute",
    top: -40,
    fontSize: 15,
  },
});

export default HandleSecteur;
