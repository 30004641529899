import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../Components/Card";
import Home from "../Components/Home";
import CardGeo from "../Components/CardGeo";
import useFirebase from "../hooks/useFirebase";
import { getDocs, query, collection, where, getDoc } from "@firebase/firestore";
import moment from "moment";

const HistoriqueAll = () => {
  const style = useStyles();
  const { user, db } = useFirebase();
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const q = query(
          collection(db, "devis"),
          where("fournisseur", "==", user.ref)
        );
        const snap = await getDocs(q);
        let tmp = await Promise.all(
          snap.docs.map((el) => {
            return {
              reataurateurRef: el.data().restaurateur,
              data: el.data(),
              id: el.id,
            };
          })
        );
        let nameChecker = [];
        let _usersName = await Promise.all(
          tmp.map(async (el) => {
            const _user = await getDoc(el.reataurateurRef);
            const _userName = _user.data().name;
            if (!nameChecker.includes(_userName)) {
              nameChecker.push(_userName);
              return {
                _userName,
                _userRef: _user.ref,
              };
            }
          })
        );
        let finalArray = [];
        tmp.map((el) => {
          const index = _usersName.findIndex(
            (item) => item?._userRef?.path === el.reataurateurRef.path
          );
          const finalIndex = finalArray.findIndex(
            (item) => item._userName === _usersName[index]._userName
          );
          if (finalIndex === -1) {
            finalArray.push({
              ..._usersName[index],
              ...el,
            });
            return;
          }
          finalArray[finalIndex].data.otherData = [];
          finalArray[finalIndex]?.data?.otherData.push({
            ..._usersName[index],
            ...el,
          });
        });
        setItems([...finalArray]);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <div className={style.container}>
      <Home />
      <div className={style.leftContainer}>
        <div className={style.informations}>
          <p style={{ fontSize: 30, maxWidth: "50%" }}>Historique</p>
          <img src="images/info.png" className={style.informationsImg} />
        </div>
        <div className={style.historyContainer}>
          <p>Date</p>
          <img
            src="images/fournisseur5.png"
            style={{ width: 32, height: 32 }}
          />
        </div>
        <div className={style.rdvContainer}>
          {items.map((el, i) => {
            return (
              <div
                className={style.rdv}
                onClick={() => setSelectedItem(i)}
                style={
                  selectedItem === i
                    ? { borderBottom: "4px solid #26466D" }
                    : {}
                }
              >
                <p className={style.rdvName}>{el._userName}</p>
                <p className={style.rdvDate}>
                  Déposé le {moment(el.data.createdAt.toDate()).format("L")}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={style.rightContainer}>
        <div className={style.rightContainerLeftSide}>
          <div className={style.titleContainer}>
            <img src="images/blackQR.png" style={{ height: 41 }} />
            <p className={style.titleTables}>Prospections validées</p>
          </div>
          <div className={style.tables}>
            {items[selectedItem]?.data.status === "sent" &&
              items[selectedItem]?.data.lines.map((el, index) => {
                return (
                  <div className={style.cardWidth}>
                    <Card
                      besoin={items[selectedItem]?.data.lines.length}
                      besoinColor={"white"}
                      loc={`Le: ${moment(
                        items[selectedItem]?.data.createdAt.toDate()
                      ).format("L")}`}
                      locIsWhite={true}
                      changeStyle={{
                        backgroundColor: "#9BC070",
                        color: "white",
                        height: 110,
                      }}
                      redirect={`/devisvalide/${items[selectedItem].id}`}
                    />
                  </div>
                );
              })}
            {items[selectedItem]?.data.status === "sent" &&
              items[selectedItem]?.data.otherData.map((el, index) => {
                return el?.data?.lines?.map((e) => {
                  return (
                    <div className={style.cardWidth}>
                      <Card
                        besoin={el.data.lines.length}
                        besoinColor={"white"}
                        loc={`Le: ${moment(el.data.createdAt.toDate()).format(
                          "L"
                        )}`}
                        locIsWhite={true}
                        changeStyle={{
                          backgroundColor: "#9BC070",
                          color: "white",
                          height: 110,
                        }}
                        redirect={`/devisvalide/${el.id}`}
                      />
                    </div>
                  );
                });
              })}
          </div>
        </div>
        <div className={style.rightContainerRightSide}>
          <div className={style.titleContainer}>
            <img src="images/blackQR.png" style={{ height: 41 }} />
            <p className={style.titleTables}>Prospections refusées</p>
          </div>
          <div className={style.tables}>
            {items[selectedItem]?.data.status === "refused" &&
              items[selectedItem]?.data.lines.map((el, index) => {
                return (
                  <div className={style.cardWidthRight}>
                    <Card
                      besoin={items[selectedItem]?.data.lines.length}
                      besoinColor={"#B53729"}
                      loc={`Le: ${moment(
                        items[selectedItem]?.data.createdAt.toDate()
                      ).format("L")}`}
                      locIsWhite={true}
                      changeStyle={{
                        backgroundColor: "#B53729",
                        color: "white",
                        height: 110,
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    backgroundSize: "cover",
    fontFamily: "poppins-regular",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "100vh",
    width: "35%",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    overflow: "auto",
  },
  informations: {
    display: "flex",
    alignItems: "center",
  },
  informationsImg: {
    marginLeft: 50,
    height: 34,
  },
  historyContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F6F6F6",
    width: "65%",
    height: 47,
    padding: 10,
    borderRadius: 5,
  },
  rdvContainer: {
    display: "flex",
    flexDirection: "column",
  },
  rdv: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 40,
    cursor: "pointer",
  },
  rdvName: {
    fontFamily: "poppins-semiBold",
    fontSize: 50,
    color: "#26466D",
    margin: 0,
  },
  rdvDate: {
    fontFamily: "poppins-light",
    fontSize: 20,
    margin: 0,
    transform: "translateY(-10px)",
  },
  rightContainer: {
    display: "flex",
    maxHeight: "100vh",
    width: "65%",
    position: "relative",
  },
  rightContainerLeftSide: {
    position: "relative",
    width: "50%",
  },
  rightContainerRightSide: {
    position: "relative",
    width: "50%",
  },
  table: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 15,
    width: "80%",
    height: "80%",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    overflow: "auto",
  },
  tables: {
    position: "absolute",
    transform: "translate(-50%, 0%)",
    bottom: "5%",
    left: "50%",
    backgroundColor: "white",
    overflow: "auto",
    height: "75%",
    width: "85%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "20px",
    borderRadius: 20,
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
  },
  tableTitle: {
    position: "absolute",
    alignItems: "center",
    display: "flex",
    fontSize: 30,
    top: 0,
    left: "10%",
  },
  logoTitle: {
    width: 23.5,
    height: 28,
    marginRight: 10,
  },
  titleContainer: {
    position: "absolute",
    top: "5%",
    left: "8%",
    display: "flex",
    alignItems: "center",
  },
  titleTables: {
    fontFamily: "poppins-regular",
    fontSize: 30,
    marginLeft: 15,
  },
  leftSide: {
    display: "flex",
    marginTop: 30,
  },
  rightSide: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 30,
  },
  cardWidth: {
    width: "90%",
    height: 110,
    margin: 0,
    marginBottom: 160,
    marginRight: 20,
  },
  cardWidthRight: {
    width: "90%",
    height: 110,
    margin: 0,
    marginTop: 150,
    marginRight: 20,
  },
});

export default HistoriqueAll;
