import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../Components/Card";
import Home from "../Components/Home";
import InputLogin from "../Components/InputLogin";

const InscriptionCollaborateurs1 = () => {
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [users, setUsers] = useState([]);
  const style = useStyles();

  const handleSubmit = async () => {
    if (name == "" || id == "" || password == "") {
      window.alert("Tous les champs sont requis");
      return;
    }

    await setUsers((prev) => [
      ...prev,
      {
        name,
        id,
        password,
      },
    ]);
    console.log(users);
  };

  return (
    <div className={style.container}>
      <Home />
      <div className={style.leftContainer}>
        <div className={style.leftContainerCenter}>
          <p className={style.leftTitle}>Collaborateurs</p>
          <form className={style.form}>
            <label>Ajouter un collaborateur</label>
            <input
              text="text"
              placeholder="Nom"
              value={name}
              className={style.input}
              onChange={(e) => setName(e.target.value)}
            />
            <label>Identifiant</label>
            <input
              text="text"
              placeholder="Identifiant"
              className={style.input}
              onChange={(e) => setId(e.target.value)}
            />
            <label>Mot de passe</label>
            <input
              text="text"
              placeholder="Mot de passe"
              className={style.input}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className={style.leftButtonContainer}
              onClick={() => handleSubmit()}
            >
              <InputLogin
                text={"Ajouter"}
                changeStyle={{
                  height: 65,
                  width: "60%",
                }}
              />
            </div>
          </form>
        </div>
      </div>
      <div className={style.rightContainer}>
        <div className={style.nextButtonContainer}>
          <InputLogin
            text={"Suivant"}
            changeStyle={{
              height: 70,
            }}
          />
        </div>
        <div className={style.table}>
          <div className={style.tableTop}>
            <p className={style.tableTopName}>PRENOM</p>
            <p className={style.tableTopId}>IDENTIFIANT</p>
            <p className={style.tableTopPassword}>MOT DE PASSE</p>
          </div>
          <div className={style.tableBottom}>
            {users.map((el, index) => {
              return (
                <div className={style.userContainer}>
                  <p className={style.userName}>{el.name}</p>
                  <p className={style.userId}>{el.id}</p>
                  <div
                    className={style.userPasswordContainer}
                    id={"userContainer"}
                  >
                    <div className={style.hideContainer}>
                      <p
                        className={style.hideContainerText}
                        id={`password${index}`}
                        style={{ display: "none" }}
                      >
                        {el.name}
                      </p>
                      <img
                        src="images/passwordHide.png"
                        style={{ width: 113 }}
                        className={style.hideContainerImg}
                        id={`img${index}`}
                      />
                    </div>
                    <img
                      src="images/oeil.png"
                      className={style.passwordImg}
                      onClick={() => {
                        const img = document.querySelector(`#img${index}`);
                        img.style.display =
                          img.style.display === "none" ? "" : "none";
                        const password = document.querySelector(
                          `#password${index}`
                        );
                        password.style.display =
                          password.style.display === "none" ? "" : "none";
                      }}
                    />
                  </div>
                  <img src="images/close-circle.png" className={style.delete} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    backgroundSize: "cover",
    fontFamily: "poppins-regular",
    fontSize: 20,
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "100vh",
    width: "35%",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    overflow: "auto",
  },
  leftContainerCenter: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
  },
  leftTitle: {
    fontSize: 30,
    margin: "50px 0 80px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    width: "100%",
    height: 50,
    backgroundColor: "#EEEEEE",
    border: "none",
    borderRadius: 10,
    margin: "10px 0 35px",
    textIndent: 10,
    "&::placeholder": {
      fontFamily: "poppins-regular",
      fontSize: 18,
      color: "##707070",
      marginLeft: 20,
    },
  },
  leftButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  rightContainer: {
    position: "relative",
    width: "65%",
    backgroundColor: "#B53729",
  },
  table: {
    position: "absolute",
    transform: "translate(-50%, 0)",
    top: "5%",
    left: "50%",
    width: "70%",
    maxHeight: "70%",
    overflow: "auto",
    borderRadius: 25,
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
    backgroundColor: "white",
  },
  tableTop: {
    display: "flex",
    width: "100%",
    height: 100,
    position: "relative",
    borderBottom: "1px solid #E3E3E3",
    fontFamily: "poppins-bold",
    fontSize: 18,
  },
  tableTopPassword: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "70%",
    top: "50%",
    margin: 0,
  },
  tableTopId: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "40%",
    top: "50%",
    margin: 0,
  },
  tableTopName: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "10%",
    top: "50%",
    margin: 0,
  },
  tableBottom: {
    maxHeight: "100%",
  },
  userContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 60,
    position: "relative",
  },
  userPasswordContainer: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "70%",
  },
  hideContainer: {
    position: "relative",
    width: 113,
  },
  hideContainerImg: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  hideContainerText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    margin: 0,
    fontFamily: "poppins-regular",
    fontSize: 18,
  },
  userId: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "40%",
    top: "50%",
    margin: 0,
    fontFamily: "poppins-regular",
    fontSize: 18,
  },
  userName: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "10%",
    top: "50%",
    margin: 0,
    fontFamily: "poppins-bold",
    fontSize: 18,
  },
  passwordImg: {
    width: 33.7,
    height: 27,
    marginLeft: 15,
  },
  delete: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "92%",
    width: 20,
    height: 20,
  },
  nextButtonContainer: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    bottom: 0,
    right: 0,
    width: 332,
  },
});

export default InscriptionCollaborateurs1;
