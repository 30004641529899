import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextConditions from "../Components/TextConditions";
import { useNavigate } from "react-router-dom";

const ConditionsGenerales = () => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <div className={style.topContainer}>
        <img
          src="images/back.png"
          className={style.backButton}
          onClick={() => navigate(-1)}
        />
        <p className={style.title}>Conditions générales</p>
      </div>
      <div className={style.middleContainer}>
        <TextConditions />
      </div>
      <div className={style.footer}>
        <p className={style.textFooter} onClick={() => navigate("/mentions")}>
          Mentions légales
        </p>
        <p
          className={style.textFooter}
          onClick={() => navigate("/conditionsgenerales")}
        >
          Conditions générales
        </p>
        <p className={style.textFooter} onClick={() => navigate("/politique")}>
          Politique de confidentialité
        </p>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    fontFamily: "poppins-regular",
    fontSize: 18,
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0 50px 50px",
  },
  title: {
    marginLeft: 20,
    fontSize: 30,
  },
  middleContainer: {
    margin: "0 50px 180px 50px",
  },
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    height: 137,
    width: "100%",
    backgroundColor: "black",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  textFooter: {
    margin: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  backButton: {
    height: 36,
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default ConditionsGenerales;
