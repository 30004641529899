import {makeStyles} from "@material-ui/core";

const CoursMarcheTab = ({
  index,
  productName,
  productWeight,
  productUnit,
  MinPrice,
  MaxPrice,
  AvgPrice,
}) => {
  const style = useStyles();

  return (
    <div
      className={style.element}
      style={
        index % 2 === 0
          ? {backgroundColor: "#E2E2E2"}
          : {backgroundColor: "#F6F6F6"}
      }
    >
      <p className={style.elementsIn} style={{marginLeft: 40}}>
        {productName ? productName : ""}
        <br></br>
        {productWeight ? productWeight : ""}
        {productUnit ? productUnit : ""}
      </p>
      {MinPrice ? (
        <p
          style={{color: "#9BC070", left: "58%", top: "20%"}}
          className={style.elementsIn}
        >
          {MinPrice} €
        </p>
      ) : (
        <p
          style={{color: "#388ADC", left: "58%", top: "20%"}}
          className={style.elementsIn}
        >
          n. r
        </p>
      )}

      {MaxPrice ? (
        <p
          style={{color: "#B53729", left: "58%", top: "80%"}}
          className={style.elementsIn}
        >
          {MaxPrice} €
        </p>
      ) : (
        <p
          style={{color: "#388ADC", left: "58%", top: "80%"}}
          className={style.elementsIn}
        >
          n. r
        </p>
      )}

      {AvgPrice ? (
        <p className={style.elementsIn} style={{left: "88%"}}>
          {AvgPrice} €
        </p>
      ) : (
        <p style={{color: "#388ADC", left: "88%"}} className={style.elementsIn}>
          n. r
        </p>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  element: {
    position: "relative",
    height: 100,
    fontSize: 20,
  },
  elementsIn: {
    position: "absolute",
    transform: "translateY(-50%)",
    top: "50%",
    margin: 0,
  },
});
export default CoursMarcheTab;
