import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "@firebase/auth";
import { doc, setDoc } from "@firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import { geohashForLocation } from "geofire-common";
import React, { useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import InputLogin from "../../Components/InputLogin";
import { useFirebase } from "./../../hooks/useFirebase";

const InscriptionSalarie = () => {
  const style = useStyles();
  const [name, setName] = useState();
  const [firstName, setFirstName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [politique, setPolitique] = useState(false);
  const [cookies, setCookie] = useCookies();
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [geoHash, setGeoHash] = useState();
  const [adressePostal, setAdressePostal] = useState();
  const navigate = useNavigate();
  const { auth, db } = useFirebase();
  const params = useParams();

  const handleSubmit = async () => {
    if (!name || !firstName || !email || !password || !adressePostal) {
      toast.error("Veuillez remplir tous les champs !");
      return;
    }
    if (!politique) {
      toast.error("Veuillez accepter notre politique de cofidentialité !");
      return;
    }

    try {
      const newUser = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );

      const token = await newUser.user.getIdToken();
      setCookie("userToken", token);

      await signInWithEmailAndPassword(auth, email, password);
      const docRef = doc(db, "users", email.toLowerCase());

      const data = {
        name,
        firstname: firstName,
        lat,
        lng,
        geoHash,
        email,
        adressePostal,
        type: params.fourType == "siege" ? "Siege" : "Fournisseur",
        wantedSubscription: params.aboType,
      };

      await setDoc(docRef, data);
      if (params.fourType == "siege") {
        navigate("/fourniseur/siege/paiement-abonnement");
        // navigate("/fourniseur/siege/collaborateurs");
      } else {
        navigate("/fourniseur/salarie/paiement-abonnement");
        // navigate("/fourniseur/salarie/intro");
      }
    } catch (e) {
      toast.error("Une erreur est survenue !");
      console.log(e);
    }
  };

  return (
    <div className={style.container}>
      <div>
        <Toaster />
      </div>
      <div className={style.containerLeft}></div>
      <div className={style.containerRight}>
        <form className={style.form}>
          <p className={style.title}>Inscription</p>
          <label>Nom</label>
          <input
            type="text"
            className={style.input}
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></input>
          <label>Prénom</label>
          <input
            type="text"
            className={style.input}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          ></input>
          <label>Adresse postale</label>
          <GooglePlacesAutocomplete
            apiKey="AIzaSyD3PFIpCNUCHanrus6QgjQeGw91kpbpu7s"
            autocompletionRequest={{
              componentRestrictions: {
                country: ["fr"],
              },
            }}
            selectProps={{
              onChange: (value) => {
                geocodeByAddress(value.label)
                  .then((results) => {
                    setLat(results[0].geometry.location.lat());
                    setLng(results[0].geometry.location.lng());
                    const hash = geohashForLocation([
                      results[0].geometry.location.lat(),
                      results[0].geometry.location.lng(),
                    ]);
                    setGeoHash(hash);
                    setAdressePostal(value.label);
                  })

                  .catch((error) => console.error("Error", error));
              },

              styles: {
                input: (provided) => ({
                  ...provided,
                  fontSize: 16,
                  fontFamily: "poppins-regular",
                  border: "none",
                }),
                option: (provided) => ({
                  ...provided,
                  fontSize: 16,
                  fontFamily: "poppins-regular",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  fontSize: 16,
                  fontFamily: "poppins-regular",
                }),
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "#F2F2F2",
                  borderRadius: 5,
                  border: "none",
                  height: "6vh",
                  marginBottom: 20,

                  backgroundImage: "url('../../images/locGrey.png')",
                  backgroundPosition: "50px 50%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "20px",
                }),

                menu: (provided) => ({
                  ...provided,
                  fontSize: 16,
                  fontFamily: "poppins-regular",
                }),

                placeholder: (provided) => ({
                  ...provided,
                  fontSize: 16,
                  color: "#E3E3E3",
                  fontFamily: "poppins-regular",
                  // marginLeft: 70,
                }),

                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: "none",
                }),

                dropdownIndicator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
              },

              placeholder: "",
            }}
          />
          <label>Adresse mail</label>
          <input
            type="text"
            className={style.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <label>Mot de passe</label>
          <div className={style.oeilContainer}>
            <input
              type="password"
              className={style.input}
              style={{ marginBottom: 0 }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
            <img src="../../../images/oeil.png" className={style.oeil} />
          </div>
          <div className={style.checkboxContainer}>
            <input
              type="checkbox"
              checked={politique}
              onChange={() => setPolitique((prev) => !prev)}
            ></input>
            <p>
              j'ai lu et j'accepte{" "}
              <span style={{ color: "#388ADC" }}>
                la politique de confidentialité
              </span>
            </p>
          </div>
          <div
            className={style.nextContainer}
            onClick={() => {
              handleSubmit();
            }}
          >
            <InputLogin
              text={"Suivant"}
              changeStyle={{
                backgroundColor: "#388ADC",
                color: "white",
                height: 70,
              }}
            />
          </div>
        </form>
        <p>
          J'ai déjà un compte ?{" "}
          <span
            style={{ color: "#388ADC" }}
            className={style.switch}
            onClick={() => navigate("/fournisseurConnexion")}
          >
            Se connecter.
          </span>
        </p>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    fontFamily: "poppins-regular",
  },
  containerLeft: {
    width: "50%",
    height: "100%",
    backgroundImage: "url('../../../images/fournisseurInscription.png')",
    backgroundSize: "cover",
  },
  containerRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    maxHeight: "100vh",
    overflow: "auto",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
  },
  title: {
    fontSize: 30,
  },
  oeilContainer: {
    display: "flex",
    alignItems: "center",
    height: "contents",
    width: "100%",
    position: "relative",
  },
  oeil: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: 30,
    height: "3vh",
  },
  input: {
    backgroundColor: "#F2F2F2",
    borderRadius: 5,
    border: "none",
    height: "6vh",
    marginBottom: 20,
    width: "100%",
  },
  checkboxContainer: {
    display: "flex",
    marginBottom: 20,
  },
  switch: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  nextContainer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default InscriptionSalarie;
