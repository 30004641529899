import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import Home from "../Components/Home";
import DefaultButton from "../Components/defaultButton";

const ZoneHorsQr = () => {
  const style = useStyles();
  const [selected, setSelected] = useState();
  const [dataTest, setDataTest] = useState([
    {
      name: "POIREAAU France cat.l colis",
      weight: "10kg",
      min: "MIN Nantes / le kg",
      prix: "1.50",
    },
    {
      name: "POIREAAU France cat.l colis",
      weight: "10kg",
      min: "MIN Nantes / le kg",
      prix: "1.50",
    },
    {
      name: "POIREAAU France cat.l colis",
      weight: "10kg",
      min: "MIN Nantes / le kg",
      prix: "1.50",
    },
  ]);
  const navigate = useNavigate();

  const handleDelete = (i) => {
    let tmp = dataTest;
    dataTest.splice(i, 1);
    setDataTest([...tmp]);
  };

  return (
    <div className={style.container}>
      <Home />
      <div className={style.left_container}>
        <div className={style.title_container}>
          <p className={style.title}>Zone hors Qr</p>
        </div>
        <div className={style.left_container_middle}>
          <p>Nom</p>
          <input type="text" className={style.left_container_middle_input} />
          <p>Prénom</p>
          <input type="text" className={style.left_container_middle_input} />
          <p>Adresse</p>
          <textarea className={style.textarea} />
        </div>
        <div className={style.left_container_bottom}>
          <div className={style.left_container_bottom_top}>
            <p>Descriptif du produit</p>
            <img src="images/info.png" style={{ height: 25, marginLeft: 10 }} />
          </div>
          <div className={style.left_container_bottom_middle}>
            <div className={style.bottom_inputs_container}>
              <p style={{ whiteSpace: "nowrap" }}>Nom du produit:</p>
              <input type="text" className={style.bottom_inputs} />
            </div>
            <div className={style.bottom_inputs_container}>
              <p>Poids:</p>
              <input type="text" className={style.bottom_inputs} />
            </div>
            <div className={style.bottom_inputs_container}>
              <p>Prix:</p>
              <input type="text" className={style.bottom_inputs} />
            </div>
          </div>
          <div className={style.left_container_bottom_bottom}>
            <DefaultButton
              styleHandle={{
                fontSize: 15,
                width: "auto",
                borderRadius: 12,
                height: 40,
              }}
            >
              Enregister
            </DefaultButton>
          </div>
        </div>
      </div>
      <div className={style.right_container}>
        <div className={style.right_container_table}>
          <div className={style.right_container_table_top}>
            <p className={style.right_container_table_title}>
              Les produits souhaités avant devis
            </p>
            <div className={style.right_container_table_subtitle_container}>
              <p>Libélé</p>
              <p>Prix</p>
            </div>
          </div>
          <div className={style.right_container_table_bottom}>
            {dataTest.map((el, i) => {
              return (
                <div
                  className={style.data_container}
                  style={
                    i === selected
                      ? { backgroundColor: "#F6C745" }
                      : i % 2 === 0
                      ? { backgroundColor: "#F6F6F6" }
                      : { backgroundColor: "#E2E2E2" }
                  }
                  onClick={() => setSelected(i)}
                >
                  <img
                    src="images/close-circle.png"
                    className={style.delete}
                    onClick={() => handleDelete(i)}
                  />
                  <div>
                    <p style={{ marginBottom: 5 }}>{el.name}</p>
                    <p style={{ margin: 0 }}>{el.weight}</p>
                    <p style={{ marginTop: 5 }}>{el.min}</p>
                  </div>
                  <p>{el.prix}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className={style.table_buttons_container}>
          <DefaultButton
            styleHandle={{ width: 200, height: 80, marginRight: 15 }}
          >
            Modifier
          </DefaultButton>
          <DefaultButton
            styleHandle={{ width: 200, height: 80, marginLeft: 15 }}
          >
            Supprimer
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

export default ZoneHorsQr;

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  title_container: {
    width: "100%",
  },
  title: {
    fontSize: 25,
    marginLeft: 120,
  },
  left_container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    fontWeight: 500,
  },
  left_container_middle: {
    backgroundColor: "white",
    width: "75%",
    padding: 30,
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    borderRadius: 20,
  },
  left_container_middle_input: {
    backgroundColor: "#F6F6F6",
    width: "100%",
    height: 30,
    border: "none",
    borderRadius: 50,
  },
  textarea: {
    backgroundColor: "#F6F6F6",
    resize: "none",
    width: "100%",
    height: 70,
    border: "none",
    borderRadius: 20,
  },
  left_container_bottom: {
    backgroundColor: "white",
    width: "75%",
    padding: 30,
    marginTop: 50,
    marginBottom: 50,
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    borderRadius: 20,
  },
  left_container_bottom_top: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  left_container_bottom_middle: {
    backgroundColor: "#F6F6F6",
    padding: "10px 20px 10px 20px",
    borderRadius: 20,
  },
  bottom_inputs_container: {
    display: "flex",
    flexDirection: "row",
  },
  bottom_inputs: {
    border: "none",
    backgroundColor: "transparent",
    width: "100%",
    marginLeft: 5,
    marginTop: 3,
  },
  left_container_bottom_bottom: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
  },
  right_container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    height: "100%",
    fontWeight: 600,
  },
  right_container_table: {
    backgroundColor: "white",
    width: "85%",
    height: "70%",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    borderRadius: 20,
    overflow: "auto",
  },
  right_container_table_top: {
    padding: "0 120px 0 40px",
  },
  right_container_table_title: {
    fontSize: 25,
    marginBottom: 0,
  },
  right_container_table_subtitle_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  data_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F6F6F6",
    padding: "0 120px 0 40px",
    fontSize: 20,
    position: "relative",
  },
  delete: {
    position: "absolute",
    right: 20,
    bottom: 20,
    height: 25,
    cursor: "pointer",
  },
  table_buttons_container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: 30,
    width: "100%",
  },
});
