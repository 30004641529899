import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLogin from "../Components/InputLogin";
import Home from "../Components/Home";

const EcoResponsable = () => {
  const style = useStyles();
  return (
    <div className={style.container}>
      <Home />
      <div className={style.leftContainer}>
        <h1 className={style.title}>Eco-citoyen, Eco-responsable</h1>
        <p className={style.longText}>
          Prospection commerciale & L'Ademe, sensibilisent les restaurateurs et
          les fournisseurs pour un comportement éco-citoyen, éco-responsable.
          Afin de sensibiliser le secteur de l'Hôtellerie & Restauration, nous
          mettons à disposition deux liens en accord avec l’Agence De
          l’Environnement et de la Maîtrise de l’Énergie. Lien sur l'éco-citoyen
          sur la route et l'autre sur l'Eco-responsable aux bureaux. Que vous
          soyez restaurateur ou fournisseur, une chose nous rattache bien plus
          que le besoin d’obtenir un produit, ou de vendre un produit, c'est le
          transport évident pour les uns, essentiel pour les autres, que vous le
          réceptionniez ou que vous les mettiez sur route : "Nous sommes tous
          acteurs et spectateurs mais responsables des véhicules « camions,
          fourgonnettes, véhicules commerciaux, ou indépendants » mis en
          circulation. Nous sommes tous, les maillons d’une chaîne, pour un but
          qui nous est propre mais qui nous relie indéniablement les uns aux
          autres par une stratégie que nous adoptons dans nos entreprises et
          bureaux : Il est de notre devoir d’acquérir les bons gestes et
          réflexes sur son lieu de travail et de valoriser un circuit court par
          la mise en contact afin de contribuer à la baisse des émissions de
          carbone." 1S1 du visuel de son logo met à disposition une réelle
          solution aux entreprises, une solution d'initiative responsable : PAR
          UN NOUVEAU CANAL DE PROSPECTION et D'INTEGRATION.{" "}
        </p>
        <div className={style.buttonsContainer}>
          <InputLogin
            text={"Eco-responsable au bureau"}
            changeStyle={{
              width: "46%",
              fontSize: 15,
            }}
          />
          <InputLogin
            text={"Eco-responsable au volant"}
            changeStyle={{
              width: "46%",
              fontSize: 15,
            }}
          />
        </div>
      </div>
      <img src="images/drop.png" className={style.img} />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    height: "100vh",
    fontFamily: "poppins-regular",
    fontSize: 16,
  },
  leftContainer: {
    width: "50%",
    maxWidth: "50%",
    overflow: "auto",
  },
  img: {
    width: "50%",
  },
  title: {
    fontSize: 37,
    fontWeight: 400,
    margin: "50px 100px 0 100px",
  },
  longText: {
    margin: "50px 100px 50px 100px",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "50px 100px 50px 100px",
  },
  buttons: {
    backgroundColor: "#388ADC",
    color: "white",
  },
});

export default EcoResponsable;
