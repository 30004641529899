import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div className={style.home} onClick={() => navigate("/dashboard")}>
      <img src="/images/home.png" style={{ width: 29, height: 27 }} />
    </div>
  );
};

const useStyles = makeStyles({
  home: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#388ADC",
    width: 106,
    height: 106,
    position: "absolute",
    top: "-2%",
    left: "-2%",
    zIndex: 5,
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default Home;
