import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@mui/icons-material/Done";
import InputLogin from "../../Components/InputLogin";
import Home from "../../Components/Home";
import { useNavigate } from "react-router-dom";
import { useFirebase } from "../../hooks/useFirebase";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  getDoc,
  query,
  getdoc,
  updateDoc,
  arrayUnion,
} from "@firebase/firestore";

const ProduitsSelect = () => {
  const style = useStyles();
  const navigate = useNavigate();
  const [isInscription, setIsInscription] = useState(false);
  const [element, setElement] = useState([
    {
      text: "VIANDES",
      color: "#B53729",
      selected: false,
    },
    {
      text: "POISSONS",
      color: "#388ADC",
      selected: false,
    },
    {
      text: "LAITIER",
      color: "#F6C745",
      selected: false,
    },
    {
      text: "FRAIS",
      color: "#61B194",
      selected: false,
    },
    {
      text: "EPICERIE",
      color: "#DFA265",
      selected: false,
    },
    {
      text: "VIN",
      color: "#E3E3E3",
      selected: false,
    },
  ]);
  const { user, db } = useFirebase();

  useEffect(() => {
    (async () => {
      user.data()?.secteurActivite?.map((el) => {
        const index = element.findIndex((current) => current.text == el);
        if (index > -1) {
          const tmp = element;
          tmp[index].selected = true;
          setElement([...tmp]);
        }
      });
    })();
  }, []);

  const handleAdd = async (i) => {
    const tmp = element;
    tmp[i].selected = !tmp[i].selected;
    setElement([...tmp]);
  };

  const handleSubmit = async () => {
    let products = element.map((el) => {
      if (el.selected) {
        return el.text;
      }
      return "";
    });
    await updateDoc(user.ref, {
      secteurActivite: products,
    });
    navigate("/dashboard");
  };
  return (
    <div className={style.container}>
      <Home />
      <div className={style.topContainer}>
        <p className={style.title}>Je sélectionne mon secteur d'activité</p>
        <img
          src="images/info.png"
          style={{ height: 30, marginLeft: 10, marginTop: 50 }}
        />
      </div>
      <div className={style.middleContainer}>
        {element.map((el, i) => {
          return (
            <div
              key={i}
              className={style.productContainer}
              onClick={() => handleAdd(i)}
            >
              <p>{el.text}</p>
              <div
                className={style.buttonContainer}
                style={{ backgroundColor: el.color }}
              >
                {!el.selected ? (
                  <img
                    src="../../images/add-circle.png"
                    style={{ height: 42.75 }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 42.75,
                      width: 42.75,
                      borderRadius: "100%",
                      backgroundColor: "white",
                    }}
                  >
                    <DoneIcon style={{ color: el.color }} />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={style.bottomContainer}
        onClick={() => {
          if (isInscription) {
            navigate("/inscription/restaurateur/abonnement");
          }
        }}
      >
        <div className={style.nextButton} onClick={() => handleSubmit()}>
          <InputLogin
            text={"Valider"}
            changeStyle={{
              backgroundColor: "#388ADC",
              width: 300,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    fontFamily: "poppins-regular",
  },
  title: {
    fontSize: 25,
    margin: "50px 0 0 50px",
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: 25,
  },
  middleContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    marginTop: 100,
  },
  productContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "13%",
    cursor: "pointer",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    height: 88,
    width: "100%",
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
  },
  bottomContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexGrow: 1,
    margin: "0 25px 25px",
  },
  nextButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  number: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: 40,
    width: 40,
    fontSize: 20,
    borderRadius: "100%",
  },
  bottomAdd: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  addText: {
    color: "#388ADC",
    fontSize: 18,
  },
});

export default ProduitsSelect;
