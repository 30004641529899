import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import useFirebase from "../hooks/useFirebase";
import axios from "axios";

const INDE_SUBSCRIPTIONS = {
  gratuit: "free",
  pro: "inde-pro",
  "pro+": "inde-pro-plus",
};

const SIEGE_SUBSCRIPTIONS = {
  gratuit: "free",
  pro: "siege-pro",
  "pro+": "siege-pro-plus",
};

const RedirectionAbonnement = () => {
  const { user } = useFirebase();
  const style = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      console.log(user.data());
      let abo;
      if (user.data().type === "Siege") {
        abo = SIEGE_SUBSCRIPTIONS[user.data().wantedSubscription] || "free";
      } else {
        abo = INDE_SUBSCRIPTIONS[user.data().wantedSubscription] || "free";
      }

      if (
        user.data()?.customerId ||
        user.data()?.subscriptionId ||
        abo === "free"
      ) {
        navigate("/dashboard");
        return;
      }

      // TODO : change url
      axios
        .post(
          `https://prospectioncommerciale.fly.dev/stripe/subscriptions/${abo}`,
          // `https://prospectioncommerciale.fly.dev/dev/stripe/subscriptions/${abo}`,
          {
            email: user.data().email,
          },
        )
        .then((res) => {
          console.log(res);
          window.open(res.data.url, "_blank");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  return (
    <div className={style.container}>
      <p className={style.text}>
        Une page va s'ouvrir pour payer l'abonnement dans quelques secondes...
      </p>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    position: "absolute",
    inset: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  text: {
    textAlign: "center",
    fontSize: 20,
  },
});

export default RedirectionAbonnement;
