import React, { useEffect, useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Home from "../Components/Home";
import { useNavigate, useParams } from "react-router-dom";
import useFirebase from "../hooks/useFirebase";
import { collection, getDoc, getDocs, doc } from "@firebase/firestore";
import reducer from "../Utils/reducer";

const TableDevis = ({ colorBgc, titleTable, textColor }) => {
  const { user, db } = useFirebase();
  const { devisId } = useParams();
  const [state, dispatch] = useReducer(reducer, {});
  const [item, setItem] = useState([]);
  const [restaurateur, setRestaurateur] = useState();
  const style = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const q = doc(collection(db, "devis"), devisId);
        const snap = await getDoc(q);
        setItem(snap.data());
        const _user = await getDoc(snap.data().restaurateur);
        setRestaurateur(_user.data());
      } catch (e) {}
    })();
  }, []);

  return (
    <div className={style.container}>
      <Home />
      <p className={style.title}>Devis validé</p>
      <div className={style.leftContainer}>
        <div className={style.greyBgc}>
          <div className={style.greyBgcTop}></div>
          <div className={style.greyBgcBottom}></div>
        </div>
        <div className={style.leftContainerTop}>
          <div className={style.leftContainerTopLeft}>
            <p className={style.leftContainerTopLeftEl} style={{ left: "8%" }}>
              LIBELLE
            </p>
            <p className={style.leftContainerTopLeftEl} style={{ left: "38%" }}>
              QUANTITE
            </p>
            <p className={style.leftContainerTopLeftEl} style={{ left: "72%" }}>
              PRIX CLIENT
            </p>
          </div>
          <div className={style.leftContainerTopRight}>
            <p className={style.leftContainerTopRightEl} style={{ left: "8%" }}>
              MON PRODUIT
            </p>
            <p
              className={style.leftContainerTopRightEl}
              style={{ left: "44%" }}
            >
              QTE / COLIS
            </p>
            <p
              className={style.leftContainerTopRightEl}
              style={{ left: "80%" }}
            >
              PRIX
            </p>
          </div>
        </div>
        <div className={style.leftContainerBottom}>
          <div className={style.leftContainerBottomLeft}>
            {item?.lines?.map((el, index) => {
              console.log("l133 ", el);
              return (
                <div
                  className={`${style.elementContainerLeft} ${
                    el.title.split(" ")[0]
                  }${index} remove`}
                  onClick={() => {
                    if (document.querySelectorAll('[data-select="selected"]')) {
                      for (const element of document.querySelectorAll(
                        '[data-select="selected"]'
                      )) {
                        element.dataset.select = "";
                        element.classList.remove(`${style.selectedElement}`);
                        element.style.backgroundColor = "";
                      }
                    }
                    for (const element of document.querySelectorAll(
                      `.${el.title.split(" ")[0]}${index}`
                    )) {
                      element.dataset.select = "selected";
                      element.classList.add(`${style.selectedElement}`);
                      element.style.backgroundColor = colorBgc;
                    }
                  }}
                >
                  <p
                    className={style.elementContainerDataLeft}
                    style={{ left: "8%" }}
                  >
                    {el.title}
                  </p>
                  <p
                    className={style.elementContainerDataLeft}
                    style={{ left: "38%" }}
                  >
                    {el.basedQty}
                  </p>
                  <p
                    className={style.elementContainerDataLeft}
                    style={{ left: "72%" }}
                  >
                    {el.basedPrice}
                  </p>
                </div>
              );
            })}
          </div>
          <div className={style.leftContainerBottomRight}>
            {item?.lines?.map((el, index) => {
              return (
                <div
                  className={`${style.elementContainerLeft} ${
                    el.title.split(" ")[0]
                  }${index} remove`}
                  onClick={() => {
                    if (document.querySelectorAll('[data-select="selected"]')) {
                      for (const element of document.querySelectorAll(
                        '[data-select="selected"]'
                      )) {
                        element.classList.remove(`${style.selectedElement}`);
                        element.dataset.select = "";
                        element.style.backgroundColor = "";
                      }
                    }
                    for (const element of document.querySelectorAll(
                      `.${el.title.split(" ")[0]}${index}`
                    )) {
                      element.dataset.select = "selected";
                      element.classList.add(`${style.selectedElement}`);
                      element.style.backgroundColor = colorBgc;
                    }
                  }}
                >
                  <p
                    className={style.elementContainerDataRight}
                    style={{ left: "8%" }}
                  >
                    {el.productName}
                  </p>
                  <p
                    className={style.elementContainerDataRight}
                    style={{ left: "44%" }}
                  >
                    {el.basedQty}
                  </p>
                  <p
                    className={style.elementContainerDataRight}
                    style={{ left: "80%" }}
                  >
                    {el.price}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className={style.rightContainer}
        style={{ backgroundColor: "#9BC070" }}
      >
        <div className={style.rightContainerCenter}>
          <p className={style.rightContainerTitle} style={{ color: textColor }}>
            Identité
          </p>
          <div className={style.rightContainerTable}>
            <p className={style.rightContainerTableText}>
              Nom: {restaurateur.name}
              <br></br>
              <br></br>
              Prénom: {restaurateur.firstname}
              <br></br>
              <br></br>
              Adresse email: {restaurateur.email}
              <br></br>
              <br></br>
              Adresse Postale: {restaurateur.adressePostal}
              <br></br>
            </p>
          </div>
        </div>
      </div>
      <button className={style.validate} onClick={() => navigate("/geo")}>
        Retrouver cette géolocalisation
      </button>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    fontFamily: "poppins-regular",
    fontSize: 16,
  },
  title: {
    position: "absolute",
    top: 0,
    left: 120,
    fontSize: 25,
  },
  leftContainer: {
    width: "75%",
    height: "60vh",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  leftContainerTop: {
    display: "flex",
    fontFamily: "poppins-semiBold",
    fontSize: 18,
  },
  leftContainerTopLeft: {
    display: "flex",
    width: "50%",
    position: "relative",
  },
  leftContainerTopLeftEl: {
    position: "absolute",
    margin: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
  leftContainerTopRight: {
    display: "flex",
    height: "10vh",
    width: "50%",
    position: "relative",
  },
  leftContainerTopRightEl: {
    position: "absolute",
    margin: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
  leftContainerBottom: {
    display: "flex",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  leftContainerBottomLeft: {
    width: "50%",
    height: "100%",
  },
  leftContainerBottomRight: {
    width: "50%",
    height: "40vh",
  },
  elementContainerLeft: {
    height: 80,
    width: "100%",
    position: "relative",
    borderBottom: "1px solid #707070",
  },
  elementContainerRight: {
    height: 80,
    width: "100%",
    position: "relative",
    borderBottom: "2px solid #707070",
  },
  elementContainerDataRight: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    margin: 0,
  },
  elementContainerDataLeft: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    margin: 0,
  },
  selectedElement: {
    backgroundColor: "#9BC070",
    color: "white",
    height: 120,
  },
  greyBgc: {
    position: "absolute",
    zIndex: -1,
    height: "100%",
    width: "50%",
    backgroundColor: "#F3F3F3",
    right: 0,
  },
  greyBgcTop: {
    height: "10vh",
  },
  greyBgcBottom: {
    height: "50vh",
    backgroundColor: "#E2E2E2",
  },
  rightContainer: {
    width: "25%",
    height: "60vh",
    display: "flex",
    justifyContent: "center",
    fontFamily: "poppins-semiBold",
    fontSize: 18,
  },
  rightContainerCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "95%",
    width: "80%",
  },
  rightContainerTitle: {
    height: 80,
    width: "100%",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center ",
  },
  rightContainerTable: {
    height: "80%",
    width: "100%",
    borderRadius: 20,
    backgroundColor: "white",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  rightContainerTableText: {
    fontFamily: "poppins-regular",
    fontSize: 20,
    marginLeft: 20,
    textOverflow: "ellipsis",
  },
  validate: {
    position: "absolute",
    bottom: 25,
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#388ADC",
    color: "white",
    fontSize: 20,
    border: "none",
    borderRadius: 10,
    height: 56,
    padding: "0 25px 0 25px",
    cursor: "pointer",
  },
});

export default TableDevis;
