import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Home from "../../Components/Home";
import CoursMarche1 from "../../Components/CoursMarche1";
import CoursMarche2 from "../../Components/CoursMarche2";
import {collectionGroup, getDocs, query} from "@firebase/firestore";
import {useFirebase} from "../../hooks/useFirebase";
import CoursMarcheTab from "../../Components/CoursMarcheTab";

const CoursMarche = () => {
  const style = useStyles();
  const [marcheOpen, setMarcheOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(""); // search bar
  const [actu, setActu] = useState(false);
  const {db} = useFirebase();

  useEffect(() => {
    (async () => {
      const q = query(collectionGroup(db, "produits_courants"));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => doc.data());
      const flatData = data.flatMap((item) => item.produits);

      // if the name of the product is the same, put it in the same array and make the average price
      const result = Object.values(
        flatData.reduce((acc, {name, price, weight, unit}) => {
          name = name.charAt(0).toUpperCase() + name.slice(1);
          if (!acc[name]) {
            acc[name] = {
              name,
              price: [parseInt(price)],
              weight: parseFloat(weight),
              unit,
            };
          } else {
            acc[name].price.push(parseInt(price));
          }
          return acc;
        }, {})
      );

      // make the average price
      const averagePrice = result.map((item) => {
        const average = item.price.reduce((a, b) => a + b) / item.price.length;
        return {
          name: item.name,
          weight: item.weight,
          unit: item.unit,
          moyennePrice: average.toFixed(2),
          maxPrice: Math.max(...item.price).toFixed(2),
          minPrice: Math.min(...item.price).toFixed(2),
        };
      });

      // sort by price
      // const sortedData = averagePrice.sort((a, b) => a.price - b.price);

      setData(averagePrice);
      setLoading(false);
      console.log(averagePrice);
    })();
  }, [db]);

  useEffect(() => {
    // console.log("intest");
    if (marcheOpen === true) {
      document.querySelector("#marcheContainer").style.transform =
        "translateX(0)";
    } else if (marcheOpen === false) {
      document.querySelector("#marcheContainer").style.transform = "";
    }
  }, [marcheOpen]);

  useEffect(() => {
    if (actu === true) {
      document.querySelector("#actuContainer").style.transform =
        "translateX(0)";
    } else if (actu === false) {
      document.querySelector("#actuContainer").style.transform = "";
    }
  }, [actu]);

  return (
    <div className={style.container}>
      <Home />
      <div className={style.marcheContainer} id="marcheContainer">
        <CoursMarche1 />
      </div>
      <div className={style.actuContainer} id="actuContainer">
        <CoursMarche2 />
      </div>
      <div className={style.leftContainer}>
        <div className={style.title}>
          <p className={style.titleText}>Les cours du marché</p>
          <img
            src="images/info.png"
            className={style.logoInfo}
            onClick={() => {
              setMarcheOpen(!marcheOpen);
            }}
          />
        </div>
        <div className={style.leftTable}>
          <div className={style.leftTableCenter}>
            <div className={style.leftTableTitle}>
              <p>Actualités</p>
              <img
                src="images/info.png"
                className={style.logoInfo}
                onClick={() => {
                  setActu(!actu);
                }}
              />
            </div>
            <div className={style.tableGrey}></div>
          </div>
        </div>
      </div>
      <div className={style.rightContainer}>
        <div className={style.rightTable}>
          <div className={style.topRightTable}>
            <p className={style.negoc}>
              Marge de<br></br>négociation
            </p>
            <p className={style.moyen}>
              Prix<br></br>moyen
            </p>
            <div className={style.topRightTableLeft}>
              <p className={style.topRightTableLeftTitle}>Cours du marché</p>
              <div className={style.prices}>
                <div
                  className={style.pricesCircle}
                  style={{backgroundColor: "#9BC070"}}
                ></div>
                <p style={{margin: 0}}>Prix minimum</p>
              </div>
              <div className={style.prices}>
                <div
                  className={style.pricesCircle}
                  style={{backgroundColor: "#B53729"}}
                ></div>
                <p style={{margin: 0}}>Prix maximum</p>
              </div>
              <div className={style.prices}>
                <div
                  className={style.pricesCircle}
                  style={{backgroundColor: "black"}}
                ></div>
                <p style={{margin: 0}}>Prix moyen</p>
              </div>
              <div className={style.prices}>
                <div
                  className={style.pricesCircle}
                  style={{backgroundColor: "#388ADC"}}
                ></div>
                <p style={{margin: 0}}>Non renseigné</p>
              </div>
            </div>
            <div className={style.topRightTableRight}>
              <div className={style.topTableInputContainer}>
                <input
                  type="text"
                  placeholder="Rechercher"
                  className={style.input}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <img src="images/search.png" className={style.searchLogo} />
              </div>
            </div>
          </div>
          <div className={style.bottomRightTable}>
            {!loading ? (
              data
                .filter((el) => {
                  // filter the data by the search bar
                  if (search === "") {
                    return el;
                  } else if (
                    el.name.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return el;
                  }
                })
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((el, index) => {
                  return (
                    <CoursMarcheTab
                      index={index}
                      productName={el.name}
                      productWeight={el.weight}
                      productUnit={el.unit}
                      MinPrice={el.minPrice}
                      MaxPrice={el.maxPrice}
                      AvgPrice={el.moyennePrice}
                    />
                  );
                })
            ) : (
              <p>Chargement...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    height: "100vh",
    fontFamily: "poppins-regular",
    position: "relative",
    overflow: "hidden",
  },
  title: {
    position: "absolute",
    left: "12%",
    top: "2%",
    display: "flex",
    alignItems: "center",
  },
  titleText: {
    margin: 0,
    fontSize: 30,
  },
  leftContainer: {
    width: "50%",
    height: "100vh",
    position: "relative",
  },
  leftTable: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "80%",
    borderRadius: 20,
    top: "50%",
    left: "50%",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    display: "flex",
    justifyContent: "center",
  },
  leftTableCenter: {
    height: "100%",
    width: "80%",
  },
  leftTableTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: 30,
    maxHeight: "20%",
  },
  logoInfo: {
    height: 30,
    marginLeft: 15,
  },
  tableGrey: {
    backgroundColor: "#E3E3E3",
    width: "100%",
    height: "80%",
    borderRadius: 20,
  },
  rightContainer: {
    width: "50%",
    height: "100vh",
    position: "relative",
  },
  rightTable: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "80%",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    borderRadius: 20,
    overflow: "auto",
  },
  topRightTable: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
  },
  prices: {
    display: "flex",
    marginBottom: 15,
  },
  pricesCircle: {
    heihgt: 25,
    width: 25,
    borderRadius: "50%",
    marginRight: 10,
  },
  topRightTableLeft: {
    fontSize: 16,
    marginLeft: 40,
  },
  topRightTableLeftTitle: {
    fontSize: 25,
  },
  topRightTableRight: {
    display: "flex",
    alignItems: "center",
    marginRight: 40,
  },
  topTableInputContainer: {
    position: "relative",
    width: 300,
    heihgt: 50,
  },
  input: {
    width: "100%",
    height: 50,
    border: "none",
    borderRadius: 50,
    backgroundColor: "#F6F6F6",
    textIndent: 15,
    "&::placeholder": {
      fontFamily: "poppins-regular",
      fontSize: 20,
      color: "#E3E3E3",
      marginLeft: 20,
      transform: "translateY(10%)",
    },
  },
  searchLogo: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    height: 30,
    top: "50%",
    right: "2%",
  },
  negoc: {
    position: "absolute",
    bottom: 0,
    left: "58%",
    margin: 0,
  },
  moyen: {
    position: "absolute",
    bottom: 0,
    left: "88%",
    margin: 0,
  },
  element: {
    position: "relative",
    height: 100,
    fontSize: 20,
  },
  elementsIn: {
    position: "absolute",
    transform: "translateY(-50%)",
    top: "50%",
    margin: 0,
  },
  marcheContainer: {
    position: "absolute",
    right: 0,
    zIndex: 10,
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    transitionDuration: "0.5s",
    transform: "translate(100%)",
    overflow: "hidden",
  },
  actuContainer: {
    position: "absolute",
    right: 0,
    zIndex: 10,
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    transitionDuration: "0.5s",
    transform: "translate(100%)",
    overflow: "hidden",
  },
});

export default CoursMarche;
