import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Label } from "@mui/icons-material";
import InputLogin from "../Components/InputLogin";

const AcheteurInscriptionProduit = () => {
  const style = useStyles();
  const [name, setName] = useState("");
  const [weight, setWeight] = useState("");
  const [price, setPrice] = useState("");
  const [products, setProducts] = useState([]);

  const handleAdd = async () => {
    if (name == "" || weight == "" || price == "") {
      window.alert("Tous les champs sont requis");
      return;
    }

    await setProducts((prev) => [
      ...prev,
      {
        name,
        weight,
        price,
      },
    ]);
    console.log(products);
  };

  return (
    <div className={style.container}>
      <div className={style.validerContainer}>
        <InputLogin text={"Valider"} changeStyle={{ width: 250, height: 60 }} />
      </div>
      <div className={style.viandesButton}>Viandes</div>
      <div className={style.tableLeft}>
        <label className={style.label} style={{ marginTop: 10 }}>
          Nom du produit:
        </label>
        <input
          type="text"
          className={style.input}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label className={style.label}>Poids:</label>
        <input
          type="text"
          className={style.input}
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
        />
        <label className={style.label}>Prix:</label>
        <input
          type="text"
          className={style.input}
          style={{ width: "50%" }}
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <img
          src="images/closeBlue.png"
          className={style.addButton}
          onClick={(e) => handleAdd()}
        />
      </div>
      <div className={style.tableRight}>
        <div className={style.tableRightTop}>
          <p className={style.historique}>Historique</p>
          <div className={style.titles}>
            <p className={style.productName}>Nom de produit:</p>
            <p className={style.quantity}>Quantité:</p>
            <p className={style.price}>Prix:</p>
          </div>
        </div>
        <div className={style.tableRightBottom}>
          {products.map((el, index) => {
            return (
              <div
                className={style.productContainer}
                style={index % 2 == 0 ? { backgroundColor: "#F2F2F2" } : {}}
              >
                <p className={style.elementName}>{el.name}</p>
                <p className={style.elementWeight}>{el.weight}</p>
                <p className={style.elementPrice}>{el.price} €</p>
                <img
                  src="images/close-circle.png"
                  className={style.elementClose}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    height: "100vh",
    fontFamily: "poppins-regular",
  },
  tableLeft: {
    position: "absolute",
    top: "50%",
    left: "25%",
    transform: "translate(-50%, -50%)",
    fontFamily: "poppins-regular",
    fontSize: 48,
    width: "35%",
    height: "60%",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginLeft: 30,
  },
  input: {
    border: "none",
    minHeight: "10%",
    borderRadius: 5,
    backgroundColor: "#F2F2F2",
    margin: "0 30px 30px 30px",
  },
  addButton: {
    position: "absolute",
    bottom: 10,
    right: 15,
    height: 50,
    transform: "rotate(45deg)",
  },
  tableRight: {
    position: "absolute",
    top: "50%",
    left: "75%",
    transform: "translate(-50%, -50%)",
    fontFamily: "poppins-regular",
    fontSize: 48,
    width: "35%",
    height: "60%",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
  },
  validerContainer: {
    position: "absolute",
    right: 20,
    bottom: 20,
  },
  viandesButton: {
    position: "absolute",
    top: 20,
    left: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    width: 160,
    height: 50,
    backgroundColor: "#B53729",
    fontSize: 30,
    borderRadius: 5,
  },
  historique: {
    color: "#388ADC",
    fontFamily: "poppins-regular",
    fontWeight: 48,
    margin: "10px 0 10px 50px",
  },
  titles: {
    display: "flex",
    width: "100%",
    height: 50,
    backgroundColor: "rgba(56, 138, 220, 0.3)",
    fontFamily: "poppins-semiBold",
    fontSize: 20,
    position: "relative",
  },
  productName: {
    position: "absolute",
    top: "50%",
    left: "20%",
    transform: "translate(-50%, -50%)",
    margin: 0,
  },
  quantity: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    margin: 0,
  },
  price: {
    position: "absolute",
    top: "50%",
    left: "75%",
    transform: "translate(-50%, -50%)",
    margin: 0,
  },
  tableRightBottom: {
    overflow: "auto",
  },
  productContainer: {
    position: "relative",
    height: 50,
    fontSize: 20,
  },
  elementName: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "20%",
    margin: 0,
  },
  elementWeight: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    margin: 0,
  },
  elementPrice: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "75%",
    margin: 0,
  },
  elementClose: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "92%",
    height: 22,
    margin: 0,
  },
});

export default AcheteurInscriptionProduit;
