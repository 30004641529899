import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { toast, Toaster } from "react-hot-toast";
import Home from "../Components/Home";
import useLocation from "../hooks/useLocation";
import useWeather from "../hooks/useWeather";

const Meteo = () => {
  const style = useStyles();
  const getWeather = useWeather();
  const [predictions, setPredictions] = useState([]);
  const [today, setToday] = useState();
  const [city, setCity] = useState("Paris");
  const [location, setLocation] = useState({
    lat: "48.86460041787391",
    lng: "2.3274365307255307",
  });
  const { getLocation, getCity } = useLocation();

  useEffect(() => {
    (async () => {
      const weatherData = await getWeather(location.lat, location.lng);
      setPredictions(weatherData.predictions);
      setToday(weatherData.current);
      const _city = await getCity(location.lat, location.lng);
      setCity(_city);
    })();
  }, [location]);

  useEffect(() => {
    (async () => {
      getLocation()
        .then((loc) => {
          setLocation({
            lat: loc.location.coords.latitude,
            lng: loc.location.coords.longitude,
          });
        })
        .catch((error) =>
          toast.error("Pour avoir la météo, autorisez la géolocalisation")
        );
    })();
  }, []);

  return (
    <div className={style.container}>
      <Home />
      <Toaster
        containerStyle={{
          fontSize: "15px",
        }}
      />
      <div className={style.leftContainer}>
        <div className={style.leftCenterDiv}>
          {predictions.map((el, index) => {
            return (
              <div className={style.dayContainer}>
                <div className={style.leftSideDay}>
                  <img src="images/rain.png" className={style.imgSize} />
                  <p className={style.textDay}>{el.temp}°</p>
                </div>

                <div className={style.rightSideDay}>
                  <p>{moment(el.date).format("dddd")}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={style.rightContainer}>
        <div className={style.inputContainer}>
          <GooglePlacesAutocomplete
            apiKey="AIzaSyD3PFIpCNUCHanrus6QgjQeGw91kpbpu7s"
            autocompletionRequest={{
              componentRestrictions: {
                country: ["fr"],
              },
              types: ["(cities)"],
            }}
            selectProps={{
              onChange: (value) => {
                geocodeByAddress(value.label)
                  .then((results) => getLatLng(results[0]))
                  .then(({ lat, lng }) => {
                    setLocation({ lat, lng });
                  })
                  .catch((error) => console.error("Error", error));
              },

              styles: {
                input: (provided) => ({
                  ...provided,
                  fontSize: 24,
                  fontFamily: "poppins-regular",
                  border: "none",
                }),
                option: (provided) => ({
                  ...provided,
                  fontSize: 24,
                  fontFamily: "poppins-regular",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  fontSize: 24,
                  fontFamily: "poppins-regular",
                }),
                control: (provided) => ({
                  ...provided,
                  height: 82,
                  width: 550,
                  border: "none",
                  borderRadius: 50,
                  boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
                  // textIndent: 70,
                  paddingLeft: 70,

                  backgroundImage: "url('images/locGrey.png')",
                  backgroundPosition: "50px 50%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "20px",
                }),

                menu: (provided) => ({
                  ...provided,
                  fontSize: 24,
                  fontFamily: "poppins-regular",
                }),

                placeholder: (provided) => ({
                  ...provided,
                  fontSize: 24,
                  color: "#E3E3E3",
                  fontFamily: "poppins-regular",
                  // marginLeft: 70,
                }),

                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: "none",
                }),

                dropdownIndicator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
              },

              placeholder: "Rechercher une ville",
            }}
          />
        </div>
        {/* exemple avec data statique, CSS Ok */}
        <div className={style.bigDay}>
          <div className={style.bigLeftSide}>
            <img src="images/rainSun.png" className={style.bigLeftImg} />
            <div style={{ display: "flex" }}>
              <p className={style.bigLeftTemp}>{today?.temp}°</p>
              <img src="images/circleGrey.png" className={style.circleImg} />
            </div>
          </div>
          <div className={style.bigRightSide}>
            <p className={style.time}>{moment().format("HH:mm")}</p>
            <p className={style.bigTextDay}>{moment().format("dddd")}</p>
            <div className={style.rightSideLocContainer}>
              <img
                src="images/locationBlack.png"
                className={style.bigRightImg}
              />
              <p className={style.city}>{city}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    backgroundSize: "cover",
    fontFamily: "poppins-regular",
    fontSize: 30,
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "100vh",
    width: "35%",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    overflow: "auto",
  },
  dayContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 50,
  },
  imgSize: {
    width: 70,
    height: 70,
    margin: "0 10px 10px",
  },
  textDay: {
    margin: 0,
  },
  leftSideDay: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  rightContainer: {
    position: "relative",
    width: "65%",
  },
  inputContainer: {
    width: "100%",
    height: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    height: 82,
    width: 2000,
    border: "none",
    borderRadius: 50,
    textIndent: 90,
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
    alignSelf: "center",

    "&::placeholder": {
      fontFamily: "poppins-regular",
      fontSize: 20,
      color: "#E3E3E3",
      marginLeft: 20,
    },
  },
  inputLogo: {
    width: 23.9,
    height: 33.4,
  },
  bigDay: {
    //
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60%",
  },
  bigLeftImg: {
    width: 153,
    height: 132.6,
  },
  circleImg: {
    width: 108,
    height: 108,
    marginLeft: -50,
  },
  bigLeftTemp: {
    fontSize: 100,
    whiteSpace: "nowrap",
    margin: 0,
  },
  bigRightSide: {
    marginLeft: 80,
  },
  rightSideLocContainer: {
    display: "flex",
    alignItems: "center",
  },
  bigRightImg: {
    width: 24,
    height: 33.5,
    marginRight: 15,
  },
  bigTextDay: {
    fontSize: 100,
    margin: 0,
  },
  time: {
    fontSize: 40,
    margin: 0,
  },
  city: {
    fontSize: 40,
    margin: 0,
  },
});

export default Meteo;
