import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import InputLogin from "../Components/InputLogin";

const InscriptionSelectType = ({ inscription }) => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <div className={style.inputsContainer}>
        <div
          className={style.buttons}
          onClick={() => navigate("/inscription/restaurateur/relation")}
        >
          <InputLogin text={"Restaurateur"} />
        </div>
        <div
          className={style.buttons}
          onClick={() => navigate("/inscription/fournisseur/type")}
        >
          <InputLogin text={"Fournisseur"} />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100vh",
    backgroundImage: "url('../images/registerPage1.png')",
    backgroundSize: "cover",
  },
  inputsContainer: {
    position: "absolute",
    top: "80%",
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  buttons: {
    height: 20,
    width: "20%",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default InscriptionSelectType;
