import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../Components/Card";
import Home from "../../Components/Home";

const ProspectionsQr = () => {
  const style = useStyles();

  //   testing arrays
  const people = [
    {
      name: "18.04.19",
      date: "Jeu. 15.04",
    },
    {
      name: "18.04.19",
      date: "Jeu. 15.04",
    },
    {
      name: "18.04.19",
      date: "Jeu. 15.04",
    },
  ];

  const rdv = [
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
  ];

  return (
    <div className={style.container}>
      <Home />
      <div className={style.leftContainer}>
        <p style={{ fontSize: 30, maxWidth: "50%", textAlign: "center" }}>
          Prospection du jour
        </p>
        <div className={style.historyContainer}>
          <p>Date</p>
          <img
            src="images/fournisseur5.png"
            style={{ width: 32, height: 32 }}
          />
        </div>
        <div className={style.rdvContainer}>
          {people.map((el) => {
            return (
              <div className={style.rdv}>
                <p className={style.rdvName}>{el.name}</p>
                <p className={style.rdvDate}>Déposé le {el.date}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={style.rightContainer}>
        <div className={style.tableTitle}>
          <img src="images/blackQr.png" className={style.logoTitle} />
          <p>Prospections QR code</p>
        </div>
        <div className={style.table}>
          {rdv.map((currElement, index) => {
            return (
              <div className={style.cardContainer}>
                {index % 2 == 0 ? (
                  <div className={style.leftSide}>
                    <div className={style.cardWidth}>
                      <Card
                        besoin={currElement.besoin}
                        besoinColor={"#9BC070"}
                        loc={currElement.loc}
                        locIsWhite={true}
                        changeStyle={{
                          backgroundColor: "#9BC070",
                          color: "white",
                        }}
                        redirect={"/appeloffre"}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={style.rightSide}>
                    <div className={style.cardWidth}>
                      <Card
                        besoin={currElement.besoin}
                        besoinColor={"#B53729"}
                        loc={currElement.loc}
                        locIsWhite={true}
                        changeStyle={{
                          backgroundColor: "#B53729",
                          color: "white",
                        }}
                        button={"Deuxième relance"}
                        redirect={"/appeloffre"}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    backgroundSize: "cover",
    fontFamily: "poppins-regular",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "100vh",
    width: "35%",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    overflow: "auto",
  },
  historyContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F6F6F6",
    width: "65%",
    height: 47,
    padding: 10,
    borderRadius: 5,
  },
  rdvContainer: {
    display: "flex",
    flexDirection: "column",
  },
  rdv: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 40,
  },
  rdvName: {
    fontFamily: "poppins-semiBold",
    fontSize: 70,
    color: "#26466D",
    margin: 0,
  },
  rdvDate: {
    fontFamily: "poppins-light",
    fontSize: 20,
    margin: 0,
    transform: "translateY(-20px)",
  },
  rightContainer: {
    display: "flex",
    maxHeight: "100vh",
    width: "65%",
    position: "relative",
    // backgroundColor: "#B53729",
  },
  table: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 15,
    width: "80%",
    height: "80%",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    overflow: "auto",
    backgroundColor: "white",
  },
  tableTitle: {
    position: "absolute",
    alignItems: "center",
    display: "flex",
    fontSize: 30,
    top: 0,
    left: "10%",
  },
  logoTitle: {
    width: 28,
    height: 28,
    marginRight: 10,
  },
  leftSide: {
    display: "flex",
    marginTop: 30,
  },
  rightSide: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 30,
  },
  cardWidth: {
    width: "60%",
    margin: "0 70px 0 50px",
  },
});

export default ProspectionsQr;
