import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Home from "../Components/Home";
import Zoom from "../Components/Zoom";
import CardGeo from "../Components/CardGeo";
import { useFirebase } from "../hooks/useFirebase";
import moment from "moment";
import {
  GoogleMap,
  Marker,
  withScriptjs,
  withGoogleMap,
  Circle,
} from "react-google-maps";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  getDoc,
  query,
  getdoc,
  updateDoc,
  arrayUnion,
  addDoc,
} from "@firebase/firestore";

const Geo = () => {
  const style = useStyles();
  const [commandes, setCommandes] = useState([]);
  const [selectedCommande, setSelectedCommande] = useState(null);
  const { user, db } = useFirebase();

  useEffect(() => {
    (async () => {
      const tmp = await getDocs(
        collection(db, "users", user.id, "commandes_poing")
      );
      tmp.forEach((el, i) => {
        setCommandes((prev) => [...prev, el.data()]);
      });
    })();
  }, []);

  const CMap = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: 48.866667, lng: 2.333333 }}
      >
        {props.children}
      </GoogleMap>
    ))
  );

  return (
    <div className={style.container}>
      <Home />
      <Zoom />
      <div className={style.leftContainer}>
        <div className={style.leftContainerCenter}>
          <p>Géolocalisation</p>
          <p className={style.subTitle}>Historique de prospection:</p>
          <div className={style.selectContainer}>
            <select placeholder="Collaborateurs" className={style.select}>
              <option>Prospection du jour</option>
            </select>
            <div className={style.select_circle}>
              <img src="images/selectArrow.png" className={style.logoTitle} />
            </div>
          </div>
          <div className={style.cardsContainer}>
            {commandes.map((el, i) => {
              let totalBesoin = 0;
              el.secteurs.map((current) => {
                totalBesoin += parseInt(current.want);
              });
              const date = `${moment(el.date.toDate()).format(
                "Do MMMM YYYY à h"
              )}h`;
              return (
                <div
                  onClick={() => {
                    setSelectedCommande(i);
                    console.log(i);
                  }}
                >
                  <CardGeo
                    loc={"62100"}
                    besoin={totalBesoin}
                    depose={date}
                    changeStyle={{
                      backgroundColor: "white",
                      color: "black",
                      marginBottom: 50,
                      cursor: "pointer",
                      border:
                        selectedCommande === i ? "1px solid #388ADC" : "none",
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={style.rightContainer}>
        <CMap
          googleMapURL={
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyD3PFIpCNUCHanrus6QgjQeGw91kpbpu7s"
          }
          loadingElement={<div style={{ height: `100%`, width: "100%" }} />}
          containerElement={<div style={{ height: `100%`, width: "100%" }} />}
          mapElement={<div style={{ height: `100%` }} />}
          center={{ lat: 48.866667, lng: 2.333333 }}
        >
          {selectedCommande !== null &&
            commandes[selectedCommande].secteurs.map((el) => {
              return (
                <div>
                  <Marker
                    position={{ lat: el.pos?.lat, lng: el.pos?.lng }}
                    label={{
                      text: el.region,
                      color: "black",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                    title={`Nb. besoin: ${el.want}, rayon: ${el.range}, prix: ${el.price}`}
                    options={{ label: { color: "green" } }}
                  />
                  <Circle
                    defaultCenter={{ lat: el.pos?.lat, lng: el.pos?.lng }}
                    defaultRadius={el.range * 1000}
                    radius={el.range * 1000}
                    options={{
                      strokeWeight: 0,
                      fillOpacity: 0.5,
                      fillColor: "#388adc",
                    }}
                  />
                </div>
              );
            })}
        </CMap>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    height: "100vh",
    width: "100%",
    fontFamily: "poppins-regular",
    fontSize: 30,
  },
  subTitle: {
    color: "#848484",
    fontSize: 20,
  },
  leftContainer: {
    width: "30%",
    height: "100vh",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    display: "flex",
    overflow: "auto",
  },
  leftContainerCenter: {
    margin: "50px 0 0 50px",
    width: "70%",
  },
  rightContainer: {
    width: "70%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardsContainer: {
    width: "100%",
  },
  carte: {
    width: "70%",
    // to center the map
    transform: "translateX(-25%)",
  },
  op_punch: {
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    padding: 20,
    borderRadius: 25,
  },
  selectContainer: {
    position: "relative",
    marginBottom: 30,
    width: "105%",
  },
  logoTitle: {
    width: 20,
  },
  select: {
    height: 47,
    width: "100%",
    border: "none",
    backgroundColor: "#F6F6F6",
    appearance: "none",
    padding: "0 0px 0 10px",
  },
  select_circle: {
    position: "absolute",
    transform: "translate(0, -50%)",
    top: "50%",
    right: 20,
    backgroundColor: "#388ADC",
    borderRadius: "100%",
    width: 32,
    height: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default Geo;
