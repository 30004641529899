import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Home from "../Components/Home";
import Zoom from "../Components/Zoom";
import CardGeo from "../Components/CardGeo";
import HandleSecteur from "../Components/HandleSecteur";
import { useFirebase } from "../hooks/useFirebase";
import { toast, Toaster } from "react-hot-toast";
import {
  GoogleMap,
  Marker,
  withScriptjs,
  withGoogleMap,
  Circle,
} from "react-google-maps";
import RoomIcon from "@mui/icons-material/Room";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  getDoc,
  query,
  getdoc,
  updateDoc,
  arrayUnion,
  addDoc,
  orderBy,
  startAt,
  endAt,
} from "@firebase/firestore";
import { useNavigate } from "react-router-dom";
import { distanceBetween, geohashQueryBounds } from "geofire-common";
import axios from "axios";

const ALLOWED_SUBSCRIPTIONS = [
  "inde-pro",
  "inde-pro-plus",
  "siege-pro",
  "siege-pro-plus",
];

const Poing = () => {
  const style = useStyles();
  const [regions, setRegions] = useState([]);
  const [total, setTotal] = useState(0);
  const [markers, setMarkers] = useState([]);
  const [zoomRadius, setZoomRadius] = useState(0);
  const [coor, setCoor] = useState();
  const [timeoutId, setTimeoutId] = useState(null);
  const [matches, setMatches] = useState([]);
  const [number, setNumber] = useState(0);
  const [want, setWant] = useState("");
  const [price, setPrice] = useState(0);
  const [region, setRegion] = useState("");
  const [range, setRange] = useState(15);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const { user, db } = useFirebase();
  const navigate = useNavigate();

  useEffect(() => {
    if (!ALLOWED_SUBSCRIPTIONS.includes(user.data()?.subscriptionType)) {
      toast.error("Vous n'avez pas accès à cette page !");

      navigate("/dashboard");
    }
  }, [user]);

  const CMap = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: 48.866667, lng: 2.333333 }}
        onClick={(e) => {
          setCoor({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        }}
      >
        {props.children}
      </GoogleMap>
    )),
  );

  const handleSubmit = async () => {
    setLoading(true);
    const allMatches = regions.map((secteur) => secteur.matches).flat();
    console.log(allMatches.map((matches) => matches.id));
    const users = await Promise.all(
      allMatches.map((match) => getDoc(doc(db, "users", match.id))),
    );
    console.log(users.map((matches) => matches.id));

    try {
      const hadToPay =
        user.data().subscriptionType === "inde-pro" ||
        user.data().subscriptionType === "siege-pro";

      let paymentIntentId = null;
      if (hadToPay) {
        const paymentIntentResponse = await axios.post(
          "https://prospectioncommerciale.fly.dev/stripe/create-payment",
          // "https://prospectioncommerciale.fly.dev/dev/stripe/create-payment",
          {
            amount: total * 100,
            customer: user.data().subscriptionId,
          },
        );

        paymentIntentId = paymentIntentResponse.data.id;
      }

      await Promise.all(
        users.map(async (userRef) => {
          const subColRef = collection(userRef.ref, "produits_courants");
          const subColSnap = await getDocs(subColRef);

          const subColData = subColSnap.docs.flatMap((doc) => {
            const _data = doc.data().produits.map((produit) => {
              return {
                title: produit.name,
                basedPrice: produit.price,
                basedQty: produit.weight,
                unit: produit.unit || null,
                productName: "",
                canDoBetter: false,
                observation: "",
                price: null,
                status: "pending",
                technicalFile: null,
                technical: null,
              };
            });
            return _data;
          });

          console.log(subColData);

          await addDoc(collection(db, "devis"), {
            createdAt: new Date(),
            firstDevisAt: null,
            fournisseur: user.ref,
            restaurateur: userRef.ref,
            origin: "geo",
            lines: subColData,
            status: "pending",
            paymentIntentId,
          });

          setLoading(false);
        }),
      );
      setRegions([]);
      toast.success("Devis envoyé avec succès !");
      setTimeout(() => {
        navigate(-1);
      }, 1500);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error("Une erreur est survenue !");
    }
  };

  const handleModify = (index) => {
    const _secteurs = [...regions];
    const [secteur] = _secteurs.splice(index, 1);
    setRegions(_secteurs);
    setRegion(secteur.region);
    setRange(secteur.range);
    setNumber(secteur.number);
    setWant(secteur.want);
    setPrice(secteur.price);
    setCoor({ lat: secteur.pos.lat, lng: secteur.pos.lng });
    setEdit(true);
    console.log(secteur);
  };

  const handleDelete = (index) => {
    const _secteurs = [...regions];
    _secteurs.splice(index, 1);
    setRegions(_secteurs);
  };

  useEffect(() => {
    setTotal(0);
    regions.map((el) => {
      setTotal((prev) => parseInt(prev) + parseInt(el.price));
    });
  }, [regions]);

  useEffect(() => {
    coor &&
      (() => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        const id = setTimeout(async () => {
          const bounds = geohashQueryBounds([coor.lat, coor.lng], range * 1000);
          const promises = [];
          for (const b of bounds) {
            const q = query(
              collection(db, "users"),
              orderBy("geohash"),
              startAt(b[0]),
              endAt(b[1]),
            );

            promises.push(getDocs(q));
          }
          const snapshot = await Promise.all(promises);
          const matchingDocs = [];

          for (const snap of snapshot) {
            for (const doc of snap.docs) {
              const lat = doc.get("lat");
              const lng = doc.get("lng");

              // We have to filter out a few false positives due to GeoHash
              // accuracy, but most will match
              const distanceInKm = distanceBetween(
                [lat, lng],
                [coor.lat, coor.lng],
              );

              if (distanceInKm <= range) {
                matchingDocs.push(doc);
              }
            }
          }
          setMatches(matchingDocs);
          console.log(matchingDocs.length);
        }, 300);
        setTimeoutId(id);
      })();
  }, [coor, range]);

  const handleSaveSecteur = () => {
    if (!region || !want || !price) {
      toast.error("Veuillez remplir tous les champs !");
      return;
    }

    if (!coor) {
      toast.error("Veuillez obligatoirement choisir un point sur la carte !");
      return;
    }

    if (want > matches.length) {
      toast.error(
        "Le nombre de restaurateurs est supérieur au nombre de matches !",
      );
      return;
    }

    setRegions((prev) => [
      ...prev,
      {
        region,
        range,
        number,
        matches: matches,
        want,
        price,
        pos: coor,
        date: new Date(),
      },
    ]);
    // setHide(true);
    setRange(15);
    setMatches([]);
    setNumber("");
    setWant("");
    setPrice("");
    setRegion("");
    setCoor(null);
    setEdit(false);
  };

  return (
    <div className={style.container}>
      <div>
        <Toaster toastOptions={{ style: { fontSize: 15 } }} />
      </div>
      <Home />
      <Zoom />
      <div className={style.leftContainer}>
        <p style={{ fontSize: 25, margin: "0 0 10px 0" }}>
          Opération coup de poing
        </p>
        <p
          style={{
            fontSize: 15,
            color: "#707070",
            margin: "0 0 15px 0",
            maxWidth: "80%",
          }}
        >
          Rendez visible, durant 24h, l'intégralité des potentiels acheteurs.
        </p>
        <div className={style.leftContainerCenter}>
          <div className={style.cardsContainer}></div>
          <HandleSecteur
            setRegions={setRegions}
            coor={coor}
            zoomRadius={zoomRadius}
            setZoomRadius={setZoomRadius}
            nbMAx={matches.length}
            setRange={setRange}
            range={range}
            setNumber={setNumber}
            number={number}
            setPrice={setPrice}
            price={price}
            setWant={setWant}
            want={want}
            setRegion={setRegion}
            region={region}
            handleSubmit={handleSaveSecteur}
            edit={edit}
          />
          {regions.map((el, index) => {
            console.log(el);
            return (
              <HandleSecteur
                setRegions={setRegions}
                data={el}
                index={index}
                regions={regions}
                coor={coor}
                nbMAx={el.matches.length}
                setRange={setRange}
                range={el.range}
                setNumber={setNumber}
                number={el.number}
                setPrice={setPrice}
                price={el.price}
                setWant={setWant}
                want={el.want}
                setRegion={setRegion}
                region={el.region}
                handleModify={() => handleModify(index)}
                handleDelete={() => handleDelete(index)}
              />
            );
          })}
          <div className={style.total}>
            <p className={style.totalText}>Total: {total} €</p>
          </div>
          <button className={style.validate} onClick={handleSubmit}>
            Valider la commande
          </button>
          <div style={{ height: 100 }}></div>
        </div>
      </div>
      <div className={style.rightContainer}>
        <CMap
          googleMapURL={
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyD3PFIpCNUCHanrus6QgjQeGw91kpbpu7s"
          }
          loadingElement={<div style={{ height: `100%`, width: "100%" }} />}
          containerElement={<div style={{ height: `100%`, width: "100%" }} />}
          mapElement={<div style={{ height: `100%` }} />}
          center={{ lat: 48.866667, lng: 2.333333 }}
        >
          <div>
            <Marker
              position={{ lat: coor?.lat, lng: coor?.lng }}
              label={{
                text: edit ? "Secteur Selectionné" : "Nouveau point",
                color: "black",
                fontSize: "20px",
                fontWeight: "600",
              }}
            />
            <Circle
              defaultCenter={{ lat: coor?.lat, lng: coor?.lng }}
              defaultRadius={zoomRadius * 1000}
              radius={zoomRadius * 1000}
              options={{
                strokeWeight: 0,
                fillOpacity: 0.5,
                fillColor: "#B53729",
                clickable: true,
              }}
            />
            {regions.map((el, i) => {
              return (
                <div>
                  <Marker
                    position={{ lat: el.pos?.lat, lng: el.pos?.lng }}
                    label={{
                      text: el.region,
                      color: "black",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                    title={el.region}
                    options={{ label: { color: "green" } }}
                  />
                  <Circle
                    defaultCenter={{ lat: el.pos?.lat, lng: el.pos?.lng }}
                    defaultRadius={el.range * 1000}
                    radius={el.range * 1000}
                    onClick={(e) =>
                      setCoor({ lat: e.latLng.lat(), lng: e.latLng.lng() })
                    }
                    options={{
                      strokeWeight: 0,
                      fillOpacity: 0.5,
                      fillColor: "#388adc",
                      clickable: true,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </CMap>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    height: "100vh",
    width: "100%",
    fontFamily: "poppins-regular",
    fontSize: 30,
    overflow: "hidden",
  },
  leftContainer: {
    width: "30%",
    height: "100vh",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "5%",
    overflow: "auto",
  },
  leftContainerCenter: {
    width: "80%",
  },
  rightContainer: {
    width: "70%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardsContainer: {
    width: "100%",
  },
  carte: {
    width: "70%",
    transform: "translateX(-25%)",
  },
  checkbox_container: {
    display: "flex",
  },
  op_punch: {
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    padding: 20,
    borderRadius: 25,
  },
  select: {
    height: 47,
    width: "100%",
    border: "none",
    backgroundColor: "#F6F6F6",
    appearance: "none",
    padding: "0 0px 0 10px",
  },
  select_circle: {
    position: "absolute",
    transform: "translate(0, -50%)",
    top: "50%",
    right: 20,
    backgroundColor: "#388ADC",
    borderRadius: "100%",
    width: 32,
    height: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoTitle: {
    height: 9.23,
    width: 14.47,
  },
  total: {
    fontSize: 20,
  },
  validate: {
    height: 65,
    width: "100%",
    color: "white",
    backgroundColor: "#388ADC",
    fontSize: 20,
    border: "none",
    borderRadius: 10,
    cursor: "pointer",
  },
  total: {
    display: "flex",
    justifyContent: "flex-end",
  },
  totalText: {
    padding: 20,
    fontSize: 20,
    fontWeight: 600,
    borderRadius: 10,
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
  },
});

export default Poing;
