import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLogin from "../Components/InputLogin";
import Home from "../Components/Home";
import { useNavigate } from "react-router-dom";

const AcheteurInscription = () => {
  const style = useStyles();
  const navigate = useNavigate();

  const element = [
    {
      text: "VIANDES",
      color: "#B53729",
    },
    {
      text: "POISSONS",
      color: "#388ADC",
    },
    {
      text: "LAITIER",
      color: "#F6C745",
    },
    {
      text: "PRODUITS FRAIS",
      color: "#61B194",
    },
    {
      text: "EPICERIE",
      color: "#DFA265",
    },
    {
      text: "VIN",
      color: "#E3E3E3",
    },
  ];

  return (
    <div className={style.container}>
      <Home />
      <div className={style.topContainer}>
        <p className={style.title}>Je sélectionne mon secteur d'activité</p>
        <img
          src="images/info.png"
          style={{ height: 30, marginLeft: 10, marginTop: 50 }}
        />
      </div>
      <div className={style.middleContainer}>
        {element.map((el) => {
          return (
            <div className={style.productContainer}>
              <p>{el.text}</p>
              <div
                className={style.buttonContainer}
                style={{ backgroundColor: el.color }}
              >
                <img src="images/add-circle.png" style={{ height: 42.75 }} />
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={style.bottomContainer}
        onClick={() => navigate("/aboacheteur")}
      >
        <div className={style.nextButton}>
          <InputLogin
            text={"Valider"}
            changeStyle={{
              backgroundColor: "#388ADC",
              width: 300,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    fontFamily: "poppins-regular",
  },
  title: {
    fontSize: 25,
    margin: "50px 0 0 50px",
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: 25,
  },
  middleContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    marginTop: 100,
  },
  productContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "13%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    height: 88,
    width: "100%",
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
  },
  bottomContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexGrow: 1,
    margin: "0 25px 25px",
  },
  nextButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default AcheteurInscription;
