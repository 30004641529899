import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import PopUp from "../PopUp";
import { useNavigate } from "react-router-dom";

const Intro = () => {
  const [squareWidth, setSquareWidth] = useState(
    (window.innerHeight * 25) / 100
  );
  const [closePop, setClosePop] = useState(true);
  const [closePopInd, setClosePopInd] = useState(true);
  const style = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    const pop = document.querySelector("#qr");
    if (closePop === true) {
      console.log("open");
      pop.style.transform = "translateY(100%)";
    } else {
      console.log("close");
      pop.style.transform = "translateY(0)";
    }
  }, [closePop]);

  useEffect(() => {
    const pop = document.querySelector("#ind");
    if (closePopInd === true) {
      console.log("open");
      pop.style.transform = "translateY(100%)";
    } else {
      console.log("close");
      pop.style.transform = "translateY(0)";
    }
  }, [closePopInd]);

  const rdv = [
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
  ];

  return (
    <div className={style.container}>
      <div className={style.qrContainer} id="qr">
        <PopUp
          type={"green"}
          data={rdv}
          close={closePop}
          setClosePop={setClosePop}
          closePop={closePop}
        />
      </div>
      <div className={style.indContainer} id="ind">
        <PopUp
          type={"blue"}
          data={rdv}
          close={closePop}
          setClosePop={setClosePopInd}
          closePop={closePopInd}
        />
      </div>
      <img src="../../images/leftFournisseur.png" className={style.leftImg} />
      <img src="../../images/rightFournisseur.png" className={style.rightImg} />
      <img
        src="../../images/circleOpen.png"
        className={style.open}
        onClick={() => navigate("/dashboard")}
      />
      <div
        className={style.squareContainer}
        style={{ width: squareWidth }}
        onClick={() => setClosePop(false)}
      >
        <img src="../../images/qrBlue.png" className={style.imgInSquare} />
        <p className={style.textSquare}>Prospections QR code</p>
        <div
          className={style.notif}
          style={{ backgroundColor: "#388ADC", color: "white" }}
        >
          3
        </div>
      </div>
      <div
        className={style.squareRightContainer}
        style={{ width: squareWidth }}
        onClick={() => setClosePopInd(false)}
      >
        <img src="../../images/search.png" className={style.imgInSquare} />
        <p className={style.textSquare} style={{ color: "white" }}>
          Prospections indirectes
        </p>
        <div className={style.notif}>3</div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    height: "100vh",
    fontFamily: "poppins-regular",
    overflow: "hidden",
    position: "relative",
  },
  leftImg: {
    position: "absolute",
    width: "50%",
    maxHeight: "65%",
  },
  rightImg: {
    position: "absolute",
    right: 0,
    width: "50%",
    maxHeight: "65%",
  },
  squareContainer: {
    position: "absolute",
    bottom: "-8%",
    left: "40%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "25%",
    borderRadius: 15,
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    "&:hover": {
      cursor: "pointer",
    },
  },
  squareRightContainer: {
    position: "absolute",
    bottom: "-8%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "25%",
    backgroundColor: "#388ADC",
    borderRadius: 15,
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    "&:hover": {
      cursor: "pointer",
    },
  },
  imgInSquare: {
    width: 72,
    height: 72,
    marginTop: 30,
  },
  textSquare: {
    maxWidth: "60%",
    textAlign: "center",
    fontSize: 17,
    color: "#388ADC",
  },
  notif: {
    position: "absolute",
    top: 10,
    right: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    color: "#388ADC",
    width: 35,
    height: 35,
    borderRadius: 50,
    fontFamily: "sogeo",
    fontWeight: 700,
  },
  qrContainer: {
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: 10,
    width: "100%",
    transform: "translateY(100%)",
  },
  indContainer: {
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: 10,
    width: "100%",
    transform: "translateY(100%)",
  },
  open: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "50%",
    top: "30%",
    height: 94,
    zIndex: 100,
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default Intro;
