import React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InputLogin from "../Components/InputLogin";

const Login = () => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <div className={style.leftSide}>
        <img src="images/tourEiffel.png" className={style.eiffel} />
      </div>
      <div className={style.rightSide}>
        <div className={style.rightSideContainer}>
          <h1 className={style.title}>Bienvenue</h1>
          <div
            className={style.inscription}
            onClick={() => {
              navigate("/inscription/type");
              localStorage.setItem("navigationState", "inscription");
            }}
          >
            <InputLogin text={"Inscription"} />
          </div>
          <div
            className={style.connexion}
            onClick={() => {
              navigate("/connexion/type");
            }}
          >
            <InputLogin
              text={"Connexion"}
              changeStyle={{
                backgroundColor: "white",
                color: "#388ADC",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    height: "100vh",
  },
  leftSide: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
  },
  eiffel: {
    height: "90vh",
  },
  title: {
    fontFamily: "segoe",
  },
  inscription: {
    width: "90%",
    marginBottom: 30,
    marginTop: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  connexion: {
    width: "90%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  rightSide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
  },
  rightSideContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
  },
});

export default Login;
