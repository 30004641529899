import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Home from "../Components/Home";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DefaultButton from "../Components/defaultButton";
import useFirebase from "../hooks/useFirebase";
import { doc, getDoc, updateDoc } from "@firebase/firestore";
import { enqueueSnackbar } from "notistack";
import { InfinitySpin } from "react-loader-spinner";

const AppelOffre = ({ colorBgc, titleTable, textColor }) => {
  const style = useStyles();
  const navigate = useNavigate();
  const { devisId } = useParams();
  const [devis, setDevis] = useState(null);
  const [loading, setLoading] = useState(true);
  const { db, user } = useFirebase();
  const route = useLocation();

  const [productName, setProductName] = useState("");
  const [price, setProductPrice] = useState("");
  const [productQty, setProductQty] = useState("");
  const [techical, setTechical] = useState("");
  const [observation, setObservation] = useState("");
  const [productIndex, setProductIndex] = useState(0);
  const [unit, setProductUnit] = useState("");
  const [canDoBetter, setCanDoBetter] = useState(false);

  useEffect(() => {
    (async () => {
      const docRef = doc(db, "devis", devisId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setDevis({
          data: docSnap.data(),
          offerDevis: new Array(docSnap.data().lines.length).fill(null),
        });
        setLoading(false);
        console.log(docSnap.data());
      } else {
        navigate("/dashboard");
        enqueueSnackbar("Ce devis n'existe pas", { variant: "error" });
      }
    })();
  }, []);

  const handleValidateProduct = () => {
    if (devis.offerDevis[productIndex]) {
      return;
    }

    const newProduct = {
      basedPrice: devis.data.lines[productIndex].basedPrice,
      basedQty: devis.data.lines[productIndex].basedQty,
      canDoBetter,
      observation,
      price,
      productName,
      productQty,
      status: devis.offerDevis[productIndex] ? "approved" : "refused",
      techical,
      techicalFile: null,
      title: devis.data.lines[productIndex].title,
      unit: unit ? unit : "kg",
    };

    let tmp = devis.offerDevis;

    tmp[productIndex] = newProduct;

    setDevis((prev) => ({
      ...prev,
      offerDevis: tmp,
    }));

    console.log(tmp);

    setProductName("");
    setProductPrice("");
    setProductQty("");
    setTechical("");
    setObservation("");
    setCanDoBetter(false);
  };

  const handleModifyProduct = () => {
    const newProduct = {
      productName: productName,
      price: price,
      productQty: productQty,
      observation: observation,
      techical: techical,
      unit: unit || devis?.offerDevis[productIndex]?.unit,
      canDoBetter: canDoBetter || devis?.offerDevis[productIndex]?.canDoBetter,
      status: devis?.offerDevis[productIndex] ? "approved" : "refused",
      basedPrice: devis?.offerDevis[productIndex]?.basedPrice,
      basedQty: devis?.offerDevis[productIndex]?.basedQty,
      title: devis?.offerDevis[productIndex]?.title,
      techicalFile: null,
    };

    console.log("l103 ", newProduct);

    let tmp = devis.offerDevis;

    tmp[productIndex] = newProduct;

    setDevis((prev) => ({
      ...prev,
      offerDevis: tmp,
    }));

    setProductName("");
    setProductPrice("");
    setProductQty("");
    setTechical("");
    setObservation("");
    setCanDoBetter(false);
  };

  const handleValidateDevis = async () => {
    const docRef = doc(db, "devis", devisId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      updateDoc(docRef, {
        lines: devis.offerDevis,
        status: "sent",
        firstDevisAt: new Date(),
      });
      navigate("/historique");
    }
  };

  const handleRefuseDevis = async () => {
    const docRef = doc(db, "devis", devisId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      updateDoc(docRef, {
        // lines: devis.offerDevis,
        firstDevisAt: new Date(),
        status: "refused",
      });
      navigate("/dashboard");
    }
  };

  return (
    <div className={style.bigContainer}>
      <Home />
      <p className={style.title}>Appel d'offre</p>
      <div className={style.container}>
        {!loading ? (
          <>
            <div className={style.leftContainer}>
              <div className={style.greyBgc}>
                <div className={style.greyBgcTop}></div>
                <div className={style.greyBgcBottom}></div>
              </div>
              <div className={style.leftContainerTop}>
                <div className={style.leftContainerTopLeft}>
                  <p
                    className={style.leftContainerTopLeftEl}
                    style={{ left: "8%" }}
                  >
                    LIBELLE
                  </p>
                  <p
                    className={style.leftContainerTopLeftEl}
                    style={{ left: "38%" }}
                  >
                    QUANTITE
                  </p>
                  <p
                    className={style.leftContainerTopLeftEl}
                    style={{ left: "72%" }}
                  >
                    PRIX CLIENT
                  </p>
                </div>
                <div className={style.leftContainerTopRight}>
                  <p
                    className={style.leftContainerTopRightEl}
                    style={{ left: "8%" }}
                  >
                    MON PRODUIT
                  </p>
                  <p
                    className={style.leftContainerTopRightEl}
                    style={{ left: "44%" }}
                  >
                    QTE / COLIS
                  </p>
                  <p
                    className={style.leftContainerTopRightEl}
                    style={{ left: "80%" }}
                  >
                    PRIX
                  </p>
                </div>
              </div>
              <div className={style.leftContainerBottom}>
                <div className={style.leftContainerBottomLeft}>
                  {devis.data.lines.map((el, index) => {
                    return (
                      <div
                        className={`${style.elementContainerLeft} ${el?.index}${index} remove`}
                        onClick={() => {
                          if (
                            document.querySelectorAll(
                              '[data-select="selected"]'
                            )
                          ) {
                            for (const element of document.querySelectorAll(
                              '[data-select="selected"]'
                            )) {
                              element.dataset.select = "";
                              element.classList.remove(
                                `${style.selectedElement}`
                              );
                              element.style.backgroundColor = "";
                            }
                          }
                          for (const element of document.querySelectorAll(
                            `.${el?.index}${index}`
                          )) {
                            element.dataset.select = "selected";
                            element.classList.add(`${style.selectedElement}`);
                            element.style.backgroundColor = colorBgc;
                          }
                          setProductIndex(index);
                          setProductName(
                            devis.offerDevis[productIndex].productName
                          );
                          setProductPrice(devis.offerDevis[productIndex].price);
                          setProductQty(
                            devis.offerDevis[productIndex].productQty
                          );
                          setObservation(
                            devis.offerDevis[productIndex].observation
                          );
                          setTechical(devis.offerDevis[productIndex].techical);
                        }}
                      >
                        <p
                          className={style.elementContainerDataLeft}
                          style={{ left: "8%" }}
                        >
                          {el?.title.charAt(0).toUpperCase() +
                            el?.title.slice(1)}
                        </p>
                        <p
                          className={style.elementContainerDataLeft}
                          style={{ left: "38%" }}
                        >
                          {el?.basedQty + " " + el?.unit}
                        </p>
                        <p
                          className={style.elementContainerDataLeft}
                          style={{ left: "72%" }}
                        >
                          {el?.basedPrice + " €"}
                        </p>
                      </div>
                    );
                  })}
                </div>
                <div className={style.leftContainerBottomRight}>
                  {devis.data.lines.map((el, index) => {
                    return (
                      <div
                        className={`${style.elementContainerLeft} ${el?.index}${index} remove`}
                        onClick={() => {
                          if (
                            document.querySelectorAll(
                              '[data-select="selected"]'
                            )
                          ) {
                            for (const element of document.querySelectorAll(
                              '[data-select="selected"]'
                            )) {
                              element.classList.remove(
                                `${style.selectedElement}`
                              );
                              element.dataset.select = "";
                              element.style.backgroundColor = "";
                            }
                          }
                          for (const element of document.querySelectorAll(
                            `.${el?.index}${index}`
                          )) {
                            element.dataset.select = "selected";
                            element.classList.add(`${style.selectedElement}`);
                            element.style.backgroundColor = colorBgc;
                          }
                          setProductIndex(index);
                          setProductName(el?.productName);
                          setProductPrice(el?.price);
                          setProductQty(el?.productQty);
                          setObservation(el?.observation);
                          setTechical(el?.techical);
                        }}
                      >
                        <p
                          className={style.elementContainerDataRight}
                          style={{ left: "8%" }}
                        >
                          {el &&
                            el?.productName.charAt(0).toUpperCase() +
                              el?.productName.slice(1)}
                        </p>
                        <p
                          className={style.elementContainerDataRight}
                          style={{ left: "44%" }}
                        >
                          {el && el?.productQty + " " + el?.unit}
                        </p>
                        <p
                          className={style.elementContainerDataRight}
                          style={{ left: "80%" }}
                        >
                          {el?.canDoBetter === true
                            ? "Je peux faire mieux"
                            : el?.price + " €"}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className={style.rightContainer}
              style={{ backgroundColor: "#F6C745" }}
            >
              <div className={style.rightContainerCenter}>
                <p
                  className={style.rightContainerTitle}
                  style={{ color: textColor }}
                >
                  OBSERVATION
                </p>
                <div className={style.rightContainerTable}>
                  <p>{devis.offerDevis[productIndex]?.observation}</p>

                  {/* <p className={style.rightContainerTableText}>
                Nom:<br></br>
                Prénom:<br></br>
                Adresse email:<br></br>
                Adresse Postale:<br></br>
              </p> */}
                </div>
              </div>
            </div>
          </>
        ) : (
          <InfinitySpin />
        )}
      </div>
      <div className={style.bottomContainer}>
        <div className={style.bottomLeft}>
          <div className={style.bottomInputContainer}>
            <p className={style.bottomInputText}>Produit</p>
            <input
              type="text"
              className={style.bottomInput}
              style={{ width: 250 }}
              onChange={(e) => {
                console.log("l371 ", productName);
                setProductName(e.target.value);
              }}
              value={productName}
            />
          </div>
          <div className={style.bottomInputContainer}>
            <p className={style.bottomInputText}>Qté / kg</p>
            <input
              type="text"
              className={style.bottomInput}
              style={{ width: 100 }}
              onChange={(e) => {
                setProductQty(e.target.value);
              }}
              value={productQty}
            />
            <select
              className={style.bottomInput}
              style={{ width: 100, marginLeft: 10, height: 27, border: "none" }}
              onChange={(e) => {
                setProductUnit(e.target.value);
              }}
              value={unit}
            >
              <option value="kg">Kg</option>
              <option value="unité">Unité</option>
              <option value="pièces">Pièces</option>
              <option value="bottes">Bottes</option>
              <option value="litres">Litres</option>
            </select>
          </div>
          <div className={style.bottomInputContainer}>
            <p className={style.bottomInputText}>Tarif</p>
            <input
              type="text"
              className={style.bottomInput}
              onChange={(e) => {
                setProductPrice(e.target.value);
              }}
              value={price}
            />
          </div>
          <div
            className={style.bottomInputContainer}
            style={{ marginLeft: 100 }}
          >
            <input
              type="checkbox"
              onChange={(e) => {
                setCanDoBetter(e.target.checked);
              }}
              checked={canDoBetter}
            />
            <p
              className={style.bottomInputText}
              style={{ whiteSpace: "nowrap" }}
            >
              Je peux mieux faire
            </p>
          </div>
        </div>
        <div className={style.bottomMiddle}>
          <div className={style.bottomInputContainer}>
            <p className={style.bottomInputText}>Fiche technique</p>
            <textarea
              className={style.bottomArea}
              onChange={(e) => {
                setTechical(e.target.value);
              }}
              value={techical}
            />
          </div>
          <div className={style.bottomInputContainer}>
            <p className={style.bottomInputText}>ou en fichier PDF</p>
            <input type="file" className={style.bottomFileInput} />
          </div>
        </div>
        <div className={style.bottomRight}>
          <div className={style.bottomInputContainer}>
            <p className={style.bottomInputText}>Observation</p>
            <textarea
              className={style.bottomArea}
              onChange={(e) => {
                setObservation(e.target.value);
              }}
              value={observation}
            />
          </div>
          <div className={style.bottomButtonContainer}>
            <DefaultButton
              styleHandle={{ width: "45%", fontSize: 18, height: 40 }}
              onClick={() => {
                handleModifyProduct();
              }}
            >
              Modifier le produit
            </DefaultButton>
            <DefaultButton
              styleHandle={{ width: "45%", fontSize: 18, height: 40 }}
              onClick={() => {
                handleValidateProduct();
              }}
            >
              Valider le produit
            </DefaultButton>
          </div>
        </div>
      </div>
      <div className={style.bigButtonContainer}>
        <div className={style.bigButtonContainerCenter}>
          <DefaultButton
            styleHandle={{
              width: 260,
              fontSize: 20,
              height: 85,
              backgroundColor: "#9BC070",
            }}
            onClick={() => {
              handleValidateDevis();
            }}
          >
            Je valide
          </DefaultButton>
          <DefaultButton
            styleHandle={{
              width: 260,
              fontSize: 20,
              height: 85,
              backgroundColor: "#F6C745",
            }}
            onClick={() => navigate(-1)}
          >
            Je répondrais plus tard
          </DefaultButton>
          <DefaultButton
            styleHandle={{
              width: 260,
              fontSize: 20,
              height: 85,
              backgroundColor: "#B53729",
            }}
            onClick={() => handleRefuseDevis()}
          >
            Je refuse
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  bigContainer: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    // alignItems: "center",
    width: "100%",
    // height: "100vh",
    fontFamily: "poppins-regular",
    fontSize: 16,
  },
  title: {
    marginLeft: 120,
    fontSize: 25,
  },
  leftContainer: {
    width: "75%",
    height: "60vh",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  leftContainerTop: {
    display: "flex",
    fontFamily: "poppins-semiBold",
    fontSize: 18,
  },
  leftContainerTopLeft: {
    display: "flex",
    width: "50%",
    position: "relative",
  },
  leftContainerTopLeftEl: {
    position: "absolute",
    margin: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
  leftContainerTopRight: {
    display: "flex",
    height: "10vh",
    width: "50%",
    position: "relative",
  },
  leftContainerTopRightEl: {
    position: "absolute",
    margin: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
  leftContainerBottom: {
    display: "flex",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  leftContainerBottomLeft: {
    width: "50%",
    height: "100%",
  },
  leftContainerBottomRight: {
    width: "50%",
    height: "40vh",
  },
  elementContainerLeft: {
    height: 80,
    width: "100%",
    position: "relative",
    borderBottom: "1px solid #707070",
  },
  elementContainerRight: {
    height: 80,
    width: "100%",
    position: "relative",
    borderBottom: "2px solid #707070",
  },
  elementContainerDataRight: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    margin: 0,
  },
  elementContainerDataLeft: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    margin: 0,
  },
  selectedElement: {
    backgroundColor: "#F6C745",
    color: "white",
    // height: 120,
  },
  greyBgc: {
    position: "absolute",
    zIndex: -1,
    height: "100%",
    width: "50%",
    backgroundColor: "#F3F3F3",
    right: 0,
  },
  greyBgcTop: {
    height: "10vh",
  },
  greyBgcBottom: {
    height: "50vh",
    backgroundColor: "#E2E2E2",
  },
  rightContainer: {
    width: "25%",
    height: "60vh",
    display: "flex",
    justifyContent: "center",
    fontFamily: "poppins-semiBold",
    fontSize: 18,
  },
  rightContainerCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "95%",
    width: "80%",
  },
  rightContainerTitle: {
    height: 80,
    width: "100%",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center ",
  },
  rightContainerTable: {
    height: "80%",
    width: "100%",
    borderRadius: 20,
    backgroundColor: "white",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
  },
  rightContainerTableText: {
    fontFamily: "poppins-regular",
    fontSize: 20,
    marginLeft: 20,
  },
  validate: {
    position: "absolute",
    bottom: 25,
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#388ADC",
    color: "white",
    fontSize: 20,
    border: "none",
    borderRadius: 10,
    height: 56,
    padding: "0 25px 0 25px",
    cursor: "pointer",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: "#B2D5E9",
    padding: "20px 0 20px 0",
  },
  bottomInputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  bottomInputText: {
    width: 100,
    fontSize: 15,
    fontWeight: 600,
  },
  bottomInput: {
    height: 27,
    border: "none",
    boxShadow: "0 3px 12px rgb(0,0,0, 0.16)",
  },
  bottomArea: {
    border: "none",
    height: 50,
    width: 350,
    resize: "none",
    boxShadow: "0 3px 12px rgb(0,0,0, 0.16)",
  },
  bottomFileInput: {
    backgroundColor: "white",
    width: 350,
    border: "none",
  },
  bottomRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "5px 0 5px 0",
  },
  bottomButtonContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
  },
  bigButtonContainer: {
    display: "flex",
    padding: "30px 0 30px 0",
    justifyContent: "center",
  },
  bigButtonContainerCenter: {
    display: "flex",
    justifyContent: "space-around",
    width: "70%",
  },
});

export default AppelOffre;
