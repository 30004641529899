import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLogin from "../../Components/InputLogin";
import { useNavigate } from "react-router-dom";

const Abonnement = () => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <p className={style.title}>Abonnement</p>
      <img src="../../images/aboAcheteur.png" className={style.image} />
      <div
        className={style.buttonContainer}
        onClick={() => navigate("/inscription/restaurateur/intro")}
      >
        <InputLogin
          text={"Suivant"}
          changeStyle={{
            backgroundColor: "#B4E197",
            width: 300,
            border: "none",
            height: 70,
          }}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    height: "100vh",
  },
  title: {
    position: "absolute",
    top: 20,
    left: "10%",
    fontFamily: "poppins-regular",
    fontSize: 30,
    margin: 0,
  },
  image: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    height: "70%",
    width: "auto",
  },
  buttonContainer: {
    position: "absolute",
    transform: "translate(-50%, 0)",
    bottom: 25,
    left: "50%",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default Abonnement;
