import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const CardGeo = ({
  loc,
  besoin,
  button,
  locIsWhite,
  isPunch,
  isTimer,
  timerText,
  locStreet,
  name,
  depose,
  changeStyle,
  besoinColor,
}) => {
  const style = useStyles();

  return (
    <div className={style.container} style={changeStyle}>
      <div className={style.topContainer}>
        <div className={style.locContainer}>
          <p className={style.depose}>Déposé le: {depose} </p>
        </div>
        <div className={style.besoinContainer}>
          <p className={style.besoinText} style={{ margin: 0 }}>
            | Nb besoin:
          </p>
          <div className={style.besoinNumber}>
            {besoin ? (
              <p
                style={{
                  color: besoinColor,
                  margin: "0 0 0 10px",
                  fontSize: 20,
                }}
              >
                {besoin}
              </p>
            ) : (
              <p style={{ color: besoinColor, margin: 0 }}>0</p>
            )}
          </div>
        </div>
      </div>
      {name && (
        <div className={style.middleContainer}>
          <p style={{ margin: 0 }}>{name}</p>
          <p style={{ margin: 0 }}>{locStreet}</p>
        </div>
      )}
      <div className={style.bottomContainer}>
        <div className={style.bottomContainerLeft}>
          <img src="images/locationBlack.png" className={style.locLogo} />
          <p style={{ margin: 0 }}>{loc}</p>
        </div>
        {button && <div className={style.button}>{button}</div>}
        {isPunch && <img src="images/punch.png" style={{ height: 25 }} />}
        {isTimer && (
          <div className={style.buttonTimer}>
            <img
              src="images/clock.png"
              style={{ height: 15, marginRight: 5 }}
            />
            <p>{timerText}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    // height: 135,
    width: "100%",
    borderRadius: 20,
    padding: 10,
    backgroundColor: "white",
    color: "black",
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
    fontFamily: "poppins-regular",
    fontSize: 15,
  },
  topContainer: {
    display: "flex",
    // justifyContent: "space-between",
  },
  depose: {
    margin: 0,
    fontFamily: "poppins-regular",
    fontSize: 15,
  },
  locLogo: {
    width: 9,
    height: 12.7,
    marginRight: 7,
  },
  locContainer: {
    display: "flex",
    alignItems: "center",
    fontFamily: "segoe",
    fontSize: 16,
  },
  besoinContainer: {
    display: "flex",
    alignItems: "center",
  },
  besoinText: {
    fontFamily: "poppins-regular",
    fontSize: 16,
    marginRight: 10,
  },
  middleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexGrow: 1,
  },
  bottomContainer: {
    display: "flex",
    flexGrow: 1,
    marginTop: 10,
  },
  bottomContainerLeft: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    color: "white",
    backgroundColor: "#388ADC",
    padding: "5px 20px 5px 20px",
    borderRadius: 25,
    fontFamily: "poppins-regular",
    fontSize: 14,
  },
  buttonTimer: {
    display: "flex",
    alignItems: "center",
    color: "white",
    backgroundColor: "#388ADC",
    padding: "0 15px 0 15px",
    borderRadius: 25,
    fontFamily: "poppins-regular",
    fontSize: 12,
  },
});

export default CardGeo;
