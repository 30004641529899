import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import PopUp from "./PopUp";
import { useNavigate } from "react-router-dom";
import Card from "../Components/Card";

const RestaurateurOpen = () => {
  const [squareWidth, setSquareWidth] = useState(
    (window.innerHeight * 25) / 100
  );
  const [closePop, setClosePop] = useState(true);
  const [closePopInd, setClosePopInd] = useState(true);
  const style = useStyles();
  const navigate = useNavigate();

  const rdv = [
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
  ];

  return (
    <div className={style.container}>
      <div className={style.topContainer}>
        <img src="../../images/leftFournisseur.png" className={style.leftImg} />
        <img
          src="../../images/rightFournisseur.png"
          className={style.rightImg}
        />
        <img
          src="../../images/circleOpen.png"
          className={style.open}
          onClick={() => navigate("/dashboard")}
        />
      </div>
      <div className={style.bottomContainer}>
        <div className={style.bottomContainerLeftCenter}>
          <div className={style.bottomContainerLeftTop}>
            <p className={style.offre}>Offre du jour</p>
          </div>
          <div className={style.bottomContainerLeftBottom}>
            <p className={style.date}>18.04.19</p>
            <p className={style.depose}>Déposé le Jeu.15.04</p>
          </div>
        </div>
        <div className={style.bottomContainerRight}>
          <div className={style.bottomTopContainer}>
            <img
              src="../../images/trace.png"
              style={{ height: 30, marginRight: 15 }}
            />
            <p style={{ fontSize: 20 }}>Dernière propositions</p>
          </div>
          <div className={style.bottomCardContainer}>
            {/* .map here */}
            <div className={style.cardContainer}>
              <Card
                besoin={6}
                loc={"80000 Amiens"}
                locIsWhite={true}
                changeStyle={{
                  backgroundColor: "white",
                  width: 400,
                  height: 110,
                }}
                besoinColor={"black"}
              />
            </div>
            <div className={style.cardContainer}>
              <Card
                besoin={6}
                loc={"80000 Amiens"}
                locIsWhite={true}
                changeStyle={{
                  backgroundColor: "white",
                  width: 400,
                  height: 110,
                }}
                besoinColor={"black"}
              />
            </div>
          </div>
          <p className={style.more} onClick={() => navigate("/propositions")}>
            Voir
            <br />
            plus
          </p>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    fontFamily: "poppins-regular",
    overflow: "hidden",
    width: "100%",
  },
  topContainer: {
    height: "65%",
  },
  leftImg: {
    position: "absolute",
    width: "50%",
    maxHeight: "65%",
  },
  rightImg: {
    position: "absolute",
    right: 0,
    width: "50%",
    maxHeight: "65%",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "35%",
  },
  bottomContainerLeftCenter: {
    boxShadow: "10px 13px 16px rgb(0,0,0, 0.16)",
    padding: "50px 0 0 50px",
    width: "25%",
    height: "100%",
  },
  offre: {
    fontSize: 25,
    margin: "0 15px 0 0 ",
  },
  date: {
    fontFamily: "poppins-semiBold",
    fontSize: 80,
    color: "#26466D",
    margin: 0,
    marginTop: 20,
  },
  depose: {
    margin: 0,
    fontSize: 20,
    transform: "translateY(-90%)",
  },
  bottomContainerRight: {
    position: "relative",
    width: "75%",
    padding: "20px 0 0 20px",
    overflow: "hidden",
  },
  bottomTopContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  bottomCardContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  cardContainer: {
    marginRight: 40,
  },
  more: {
    position: "absolute",
    bottom: -50,
    right: -30,
    height: 150,
    width: 150,
    backgroundColor: "#388ADC",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontSize: 20,
    borderRadius: "100%",
    cursor: "pointer",
  },
  open: {
    position: "absolute",
    height: 94,
    width: 94,
    top: "25%",
    left: "50%",
    transform: "translateX(-50%)",
    cursor: "pointer",
  },
});

export default RestaurateurOpen;
