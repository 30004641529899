import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Home from "../Components/Home";
import { useNavigate } from "react-router-dom";

const Support = () => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <div className={style.leftContainer}>
        <img src="images/supports.png" className={style.reverseTitle} />
        <div className={style.box} onClick={() => navigate("/jourj")}>
          <p className={style.boxText}>
            Je suis sur le point de rencontrer un restaurateur
          </p>
          <div className={style.boxNumber}>3</div>
        </div>
      </div>
      <div className={style.rightContainer}>
        <img
          src="images/agenda.png"
          className={style.reverseTitle}
          style={{ height: "50%" }}
        />
        <div className={style.box} onClick={() => navigate("/calendrier")}>
          <p className={style.boxText}>Nouveaux clients</p>
          <div className={style.boxNumber}>3</div>
          <img src="images/bell.png" className={style.bell} />
        </div>
      </div>
      <Home />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    backgroundSize: "cover",
  },
  reverseTitle: {
    position: "absolute",
    top: "50%",
    left: "0",
    color: "white",
    fontSize: 117,
    margin: 0,
    padding: 0,
    transform: "translateY(-50%)",
    height: "70%",
  },
  leftContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    width: "50%",
    backgroundColor: "#CBF5FD",
  },
  rightContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    backgroundColor: "#8DE5F6",
    width: "50%",
  },
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    height: 264,
    width: "50%",
    borderRadius: 68,
    padding: "25px 15px 25px 15px",
    cursor: "pointer",
  },
  boxText: {
    fontSize: 25,
    textAlign: "center",
    fontWeight: 600,
  },
  boxNumber: {
    border: "2px solid black",
    padding: "0 20px 0 20px",
    borderRadius: 15,
    fontSize: 20,
    fontWeight: 700,
  },
  bell: {
    position: "absolute",
    top: "-7px",
    right: "-7px",
    width: 47,
    height: 55,
  },
});

export default Support;
