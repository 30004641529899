import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableDevis from "./TableDevis";
import InputLogin from "../Components/InputLogin";
import Home from "../Components/Home";

const DevisValide = () => {
  const style = useStyles();

  return (
    <div className={style.container}>
      <Home />
      <div className={style.tableContainer}>
        <div className={style.title}>
          <p style={{ margin: 0 }}>Devis validé</p>
        </div>
        <TableDevis colorBgc={"#9BC070"} titleTable={"IDENTITE"} />
        <InputLogin
          text={"Retrouver cette géolocalisation"}
          changeStyle={{ width: 400, marginTop: 30, height: 70 }}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100vh",
  },
  title: {
    margin: "0 70% 50px 0",
    fontFamily: "poppins-regular",
    fontSize: 30,
  },
  tableContainer: {
    position: "absolute",
    top: "10%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export default DevisValide;
