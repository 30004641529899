import {
  collection,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/fr";
import React, { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import { InfinitySpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import "../BigCalendar.css";
import { CardRendezVous } from "../Components/CardRendezVous";
import Home from "../Components/Home";
import useFirebase from "../hooks/useFirebase";

const Calendrier = () => {
  const style = useStyles();
  const navigate = useNavigate();
  const [myEventsList, setMyEventsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { db, user } = useFirebase();
  const [currentDay, setCurrentDay] = useState(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    user &&
      (async () => {
        const _events = query(
          collection(db, "rendez_vous"),
          where("restaurateur", "==", user.ref),
          orderBy("start", "asc")
        );
        const docSnap = await getDocs(_events);

        const docs = await Promise.all(
          docSnap.docs
            .filter((el) => el.data().status != "refused")
            .map(async (event) => {
              const fournisseur = await getDoc(event.data().fournisseur);

              return {
                data: {
                  ...event.data(),
                  ref: event.ref,
                  id: event.id,
                },
                user: {
                  ...fournisseur.data(),
                  ref: fournisseur.ref,
                },
              };
            })
        );

        setMyEventsList(docs);
        setLoading(false);
      })();
  }, [user]);

  //   const _events = query(
  //     collection(db, "rendez_vous"),
  //     where("restaurateur", "==", user.ref),
  //     orderBy("start", "asc")
  //   );

  //   const docSnap = await getDocs(_events);
  //   const docs = await Promise.all(
  //     docSnap.docs
  //       .filter((el) => el.data().status != "refused")
  //       .map(async (event) => {
  //         return {
  //           data: {
  //             ...event.data(),
  //           },
  //         };
  //       })
  //   );

  //   setMyEventsList2(docs);
  // };

  const tileContent = ({ date, view }) => {
    const day = moment(date).format("YYYY-MM-DD");
    const events = myEventsList.filter((el) => el.data.date === day);
    return (
      <div className={style.dotContainer}>
        {events.length > 0 && (
          <div className={day === currentDay ? style.dotToday : style.dot} />
        )}
      </div>
    );
  };

  return (
    <div className={style.container}>
      <Home />
      <div className={style.bottomContainerCenter}>
        <Calendar
          style={{ height: 500 }}
          onClickDay={(day) => {
            setCurrentDay(moment(day).format("YYYY-MM-DD"));
          }}
          value={currentDay ? new Date(currentDay) : new Date()}
          tileContent={tileContent}
        />
      </div>
      {!loading ? (
        <>
          <p className={style.rendezVousTitle}>
            Rendez vous du{" "}
            {!currentDay
              ? moment(new Date()).format("DD/MM/YYYY")
              : moment(currentDay).format("DD/MM/YYYY")}
          </p>
          <div
            className={
              !loading ? style.rendezVousList : style.rendezVousLoading
            }
          >
            {myEventsList
              .filter(
                (el) =>
                  (!currentDay
                    ? moment(new Date()).format("YYYY-MM-DD")
                    : currentDay) === el.data.date
              )
              .map((event, i) => {
                return (
                  <CardRendezVous
                    key={i}
                    personName={event.user.name + " " + event.user.firstname}
                    address={event.user.addressPostal}
                    duration={event.data.start + " - " + event.data.end}
                    status={
                      event.data.status === "approved"
                        ? "Accepté"
                        : event.data.status === "pending"
                        ? "En attente"
                        : event.data.status === "sent"
                        ? "Envoyé"
                        : event.data.status === "modified"
                        ? "Modifié, en attente de validation"
                        : ""
                    }
                    onClick={() => {
                      navigate("/monrendezvous/" + event.data.id);
                    }}
                  />
                );
              })}
          </div>
        </>
      ) : (
        <InfinitySpin width="200" color="#388ADC" />
      )}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  bottomContainerCenter: {
    width: "80%",
    maxHeight: "90%",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    padding: 50,
    borderRadius: 20,
    marginTop: 40,
    marginBottom: 20,

    "@media (max-width: 920px)": {
      width: "80%",
      marginTop: 90,
    },

    "@media (max-width: 768px)": {
      width: "80%",
      marginTop: 100,
    },
    "@media (max-width: 500px)": {
      width: "60%",
    },
  },
  rendezVousTitle: {
    fontSize: 20,
    fontFamily: "poppins-semiBold",
    marginBottom: 20,
  },

  rendezVousList: {
    width: 0,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "calc(80 % + 100px)",
    /* padding: 0 50px; */
    gap: "25px",

    "@media (max-width: 920px)": {
      justifyContent: "center",
    },
  },
  rendezVousLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dotContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
  },
  dot: {
    width: 5,
    height: 5,
    borderRadius: "50%",
    backgroundColor: "#388ADC",
    // margin: "0 auto",
  },
  dotToday: {
    width: 5,
    height: 5,
    borderRadius: "50%",
    backgroundColor: "#fff",
  },
});

export default Calendrier;
