import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const CoursMarche1 = () => {
  const style = useStyles();

  return (
    <div className={style.container}>
      <div className={style.centerContainer}>
        <span style={{ fontSize: 30 }}>Donées cours du marché</span>
        <br></br>
        <br></br>FRANCEAGRIMER<br></br>
        <br></br>
        RNM : Données originales téléchargées depuis la page [lien exact de la
        page] – Mise à jour du 14 février 2017. <br></br>
        <br></br>Enquête terrain : par l'équipe de Prospection commerciale.{" "}
        <br></br>
        <br></br>Données de la plateforme de Prospection commerciale : fourni
        par les restaurateurs sur l'Application Prospection commerciale, en
        plein accord par le présent contrat avec chacun d'eux, et ou ceux-ci se
        sont engagés pleinement par : les conditions générales & la politique de
        confidentialité
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    width: 670,
    height: "100vh",
    backgroundColor: "white",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    fontFamily: "poppins-regular",
    fontSize: 20,
  },
  centerContainer: {
    width: "80%",
    marginTop: 50,
  },
});

export default CoursMarche1;
