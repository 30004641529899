import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";

const AboSalarie = () => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <div className={style.title}>Abonnement</div>
      <div className={style.table}>
        <img src="../../../images/aboSalarie2.png" className={style.image} />
      </div>
      <div className={style.buttonContainer}>
        <div
          className={style.buttonG}
          onClick={() => {
            localStorage.setItem("abosalarie", "gratuit");
            navigate("/inscription/fournisseur/salarie/gratuit");
          }}
        >
          Gratuit
        </div>
        <div
          className={style.buttonG}
          style={{ backgroundColor: "#83BD75" }}
          onClick={() => {
            localStorage.setItem("abosalarie", "pro");
            navigate("/inscription/fournisseur/salarie/pro");
          }}
        >
          Pro
        </div>
        <div
          className={style.buttonG}
          style={{ backgroundColor: "#4E944F" }}
          onClick={() => {
            localStorage.setItem("abosalarie", "pro+");
            navigate("/inscription/fournisseur/salarie/pro+");
          }}
        >
          Pro +
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
    backgroundSize: "cover",
  },
  image: {
    width: "auto",
    height: "65vh",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontFamily: "poppins-regular",
    fontSize: 30,
    marginLeft: "18%",
  },
  table: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 0 30px 0",
  },
  buttonG: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "poppins-regular",
    fontSize: 20,
    alignItems: "center",
    color: "white",
    backgroundColor: "#B4E197",
    width: "10%",
    height: "40px",
    borderRadius: 5,
    padding: "10px 10px 10px 10px",
    marginRight: 60,
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default AboSalarie;
