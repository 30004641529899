import {
  arrayUnion,
  doc,
  getDoc,
  refEqual,
  updateDoc,
} from "@firebase/firestore";
import {makeStyles} from "@material-ui/core/styles";
import "moment/locale/fr";
import React, {useEffect, useState} from "react";
import {Calendar} from "react-calendar";
import {toast, Toaster} from "react-hot-toast";
import {InfinitySpin} from "react-loader-spinner";
import {useNavigate, useParams} from "react-router-dom";
import "../../BigCalendar.css";
import {CardDevisRendezVous} from "../../Components/CardDevisRendezVous";
import Home from "../../Components/Home";
import useFirebase from "../../hooks/useFirebase";
import moment from "moment";

const RendezVousReview = () => {
  const style = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const {db, user} = useFirebase();
  const {rvId} = useParams();
  const [event, setEvent] = useState({});
  const [devis, setDevis] = useState({});
  const [fournisseur, setFournisseur] = useState({});
  const [shouldShow, setShouldShow] = useState(false);
  const [editable, setEditable] = useState(false);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [message, setMessage] = useState("");
  const [display, setDisplay] = useState("none");

  const loadData = async () => {
    const docRef = doc(db, "rendez_vous", rvId);
    const docSnap = await getDoc(docRef);
    const rvData = docSnap.data();

    const devisRef = await getDoc(rvData.devisRef);
    const devisData = devisRef.data();

    const docRef3 = await getDoc(devisData.fournisseur);
    const fournisseurData = docRef3.data();

    if (refEqual(user.ref, rvData.modifiedBy)) {
      setShouldShow(false);
    } else if (rvData.status === "approved" || rvData.status === "refused") {
      setShouldShow(false);
    } else {
      setShouldShow(true);
    }

    setFournisseur(fournisseurData);
    setEvent(rvData);
    setDevis({
      ...devisData,
      id: devisRef.id,
    });

    setStart(rvData.start);
    setEnd(rvData.end);
    setMessage(rvData.message);
    setSelectedDate(new Date(rvData.date));

    setLoading(false);
  };

  const handleValidate = async () => {
    await updateDoc(doc(db, "rendez_vous", rvId), {
      status: "approved",
    });
    toast.success("Vous avez accepté le rendez-vous");
    loadData();
    // navigate("/restaurateur/rendez-vous");
  };

  const handleRefuse = async () => {
    await updateDoc(doc(db, "rendez_vous", rvId), {
      status: "refused",
    });
    toast.success("Vous avez refusé le rendez-vous");
    setDisplay("none");
    loadData();
  };

  const handleModify = async () => {
    const modifiedMeeting = {
      date: moment(selectedDate).format("YYYY-MM-DD"),
      start: start,
      end: end,
      message: message,
      modifiedBy: user.ref,
    };
    await updateDoc(doc(db, "rendez_vous", rvId), {
      date: moment(selectedDate).format("YYYY-MM-DD"),
      start: start,
      end: end,
      message: message,
      status: "modified",
      modifiedBy: user.ref,
      modifiedMeeting: arrayUnion(modifiedMeeting),
    });

    toast.success("Vous avez modifié le rendez-vous");
    setDisplay("none");
    setEditable(false);
    loadData();
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={!loading ? style.container : style.loading}>
      <Home />
      <Toaster />
      {!loading ? (
        <>
          <div className={style.bottomContainer}>
            <div className={style.bottomContainerLeft}>
              <div className={style.calendarWrapper}>
                <Calendar
                  startAccessor="start"
                  endAccessor="end"
                  style={{height: 500}}
                  tileDisabled={() => !editable}
                  maxDetail={"month"}
                  minDetail={!editable ? "month" : "decade"}
                  onChange={(date) => {
                    setSelectedDate(date);
                  }}
                  defaultValue={new Date(event.date)}
                  value={selectedDate}
                />
              </div>
              {shouldShow && (
                <div className={style.buttonsContainer}>
                  <button
                    className={style.button}
                    onClick={() => {
                      editable ? setDisplay("flex") : handleValidate();
                    }}
                  >
                    {editable ? "Valider" : "Accepter"}
                  </button>
                  <button
                    className={style.button}
                    style={{
                      display: editable ? "none" : "flex",
                    }}
                    onClick={() => {
                      setEditable(true);
                      toast.success("Vous pouvez modifier votre rendez-vous");
                    }}
                  >
                    Modifier
                  </button>
                  <button
                    className={style.button}
                    onClick={() => {
                      if (editable) {
                        setEditable(false);
                        loadData();
                      } else {
                        setDisplay("flex");
                      }
                    }}
                  >
                    {editable ? "Annuler" : "Refuser"}
                  </button>
                </div>
              )}
            </div>

            {/*// ********************************* */}
            <div className={style.bottomContainerRight}>
              <div className={style.horaireContainer}>
                <div className={style.horaire}>Horaire</div>
                <div className={style.hoursContainer}>
                  <input
                    readOnly={!editable}
                    className={style.input}
                    type={"time"}
                    onChange={(e) => {
                      setStart(e.target.value);
                    }}
                    defaultValue={event.start}
                    value={start}
                  />
                  <p className={style.hours}>-</p>
                  <input
                    className={style.input}
                    type={"time"}
                    onChange={(e) => {
                      setEnd(e.target.value);
                    }}
                    min={start || event.start}
                    readOnly={!editable}
                    defaultValue={event.end}
                    value={end}
                  />
                </div>
              </div>
              {/*// ******************************** */}
              <div className={style.devisContainer}>
                <p className={style.titleDevis}>Devis validé</p>
                <CardDevisRendezVous
                  email={fournisseur.email}
                  address={fournisseur.adressePostal}
                  needs={devis.lines.length}
                  personName={fournisseur.firstname + " " + fournisseur.name}
                  onClick={() => navigate(`/devis/${devis.id}`)}
                />
              </div>
              {/*// ******************************** */}
              <div className={style.messageContainer}>
                <p className={style.titleMessage}>Message</p>
                <div className={style.message}>
                  <textarea
                    className={style.messageText}
                    readOnly={!editable}
                    defaultValue={event.message}
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <InfinitySpin width="200" color="#388ADC" />
      )}
      <>
        <div className={style.dialogContainer} style={{display: display}}>
          <p
            style={{
              fontSize: "16px",
              fontFamily: "poppins-bold",
            }}
          >
            {editable
              ? "Vous êtes sur de vos modifications ?"
              : "Vous êtes sur de vouloir refuser ce rendez-vous ?"}
          </p>
          <div className={style.buttonsContainer}>
            <button
              className={style.buttonDialog}
              style={{backgroundColor: "#B53729"}}
              onClick={() => {
                setDisplay("none");
              }}
            >
              Non
            </button>
            <button
              className={style.buttonDialog}
              style={{backgroundColor: "#9BC070"}}
              onClick={() => (editable ? handleModify() : handleRefuse())}
            >
              Oui
            </button>
          </div>
        </div>
      </>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },

  bottomContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,

    "@media (max-width: 920px)": {
      flexDirection: "column",
    },
  },
  calendarWrapper: {
    maxHeight: "90%",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    padding: 50,
    borderRadius: 20,
    marginBottom: 50,

    "@media (max-width: 768px)": {
      marginTop: 50,
    },
  },
  bottomContainerLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",

    "@media (max-width: 920px)": {
      width: "80%",
    },

    "@media (max-width: 768px)": {
      width: "100%",
    },
    "@media (max-width: 500px)": {
      width: "80%",
    },
  },
  bottomContainerRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "40%",
    maxHeight: "90%",
    marginLeft: 50,

    "@media (max-width: 920px)": {
      width: "80%",
      marginLeft: 0,
    },
    "@media (max-width: 768px)": {
      width: "100%",
      marginLeft: 0,
    },
    "@media (max-width: 500px)": {
      width: "80%",
      marginLeft: 0,
    },
  },
  horaireContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
    backgroundColor: "#ffffff",
    borderRadius: 20,
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    width: "100%",

    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  horaire: {
    color: "#000000",
    fontFamily: "Poppins-Bold",
    fontSize: 16,
    marginRight: 38,
    marginLeft: 20,

    "@media (max-width: 768px)": {
      fontSize: 14,
    },
  },
  hours: {
    color: "#000000",
    fontFamily: "Poppins-Regular",
    fontSize: 16,
    margin: 0,
    padding: 0,

    "@media (max-width: 768px)": {
      fontSize: 14,
    },
  },
  input: {
    backgroundColor: "#F2F2F2",
    // borderRadius: 20,
    paddingInline: 15,
    paddingBlock: 5,
    border: "none",
    outline: "none",
    "@media (max-width: 768px)": {
      fontSize: 14,
    },
    "@media (max-width: 400px)": {
      paddingInline: 10,
    },
    "&::-webkit-calendar-picker-indicator": {
      opacity: 0,
      position: "absolute",
    },
  },
  hoursContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F2F2F2",
    borderRadius: 20,
    paddingBlock: 7.5,
    width: "100%",

    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  devisContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 20,
  },
  titleDevis: {
    color: "#000000",
    fontFamily: "Poppins-Bold",
    fontSize: 16,
  },

  messageContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 20,
  },
  titleMessage: {
    color: "#000000",
    fontFamily: "Poppins-Bold",
    fontSize: 16,
  },
  message: {
    // backgroundColor: "#F2F2F2",
    borderRadius: 20,
    padding: 20,
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    height: "100%",
  },
  messageText: {
    color: "#000000",
    fontFamily: "Poppins-Regular",
    fontSize: 16,
    border: "none",
    outline: "none",
    resize: "none",
    verticalAlign: "top",
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
  },

  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "20px",
    // flexWrap: "wrap",
    justifyContent: "center",

    "@media (max-width: 920px)": {
      marginBottom: 25,
    },

    "@media (max-width: 768px)": {
      gap: "10px",
    },
  },
  button: {
    backgroundColor: "#388ADC",
    color: "#ffffff",
    fontFamily: "Poppins-Bold",
    fontSize: 16,
    borderRadius: 15,
    padding: "10px 40px",
    margin: 0,
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#2D6DA0",
    },

    "@media (max-width: 500px)": {
      fontSize: 14,
      padding: "10px 15px",
    },
  },

  dialogContainer: {
    position: "absolute",
    bottom: "5%",
    left: "50%",
    transform: "translate(-50%, -5%)",
    backgroundColor: "white",
    width: "450px",
    height: "100px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.16)",
    borderRadius: 15,
    padding: 20,
    textAlign: "center",

    "@media (max-width: 768px)": {
      width: "80%",
    },
  },
  buttonDialog: {
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    border: "none",
    width: "50px",
    cursor: "pointer",
    fontFamily: "poppins-semibold",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
});

export default RendezVousReview;
