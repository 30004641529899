import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Card from "../../Components/Card";
import Home from "../../Components/Home";
import PopUp from "../PopUp";
import useFirebase from "../../hooks/useFirebase";
import {
  query,
  collection,
  getDoc,
  getDocs,
  orderBy,
  where,
} from "@firebase/firestore";
import Card2 from "../../Components/Card2";
import moment from "moment";

const ProspectionPoing = () => {
  const style = useStyles();
  const {db, user} = useFirebase();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusColor, setStatusColor] = useState("#F6C745");

  useEffect(() => {
    (async () => {
      const devisRef = query(
        collection(db, "devis"),
        where("fournisseur", "==", user.ref),
        where("origin", "==", "geo"),
        orderBy("createdAt", "desc")
      );
      const docSnap = await getDocs(devisRef);

      const docs = await Promise.all(
        docSnap.docs
          .filter(
            (d) =>
              (d.data().status == "refused" && !d.data().secondDevisAt) ||
              ["sent", "pending"].includes(d.data().status)
          )
          .map(async (doc) => {
            const _user = await getDoc(doc.data().restaurateur);
            if (doc.data().status === "sent") {
              setStatusColor("#F6C745");
            } else if (doc.data().status === "pending") {
              setStatusColor("#388ADC");
            }

            return {
              data: {
                ...doc.data(),
                ref: doc.ref,
                id: doc.id,
              },
              user: {
                ..._user.data(),
                ref: _user.ref,
                id: _user.id,
              },
            };
          })
      );

      setData(docs);
      setLoading(false);
      console.log(docs);
    })();
  }, []);

  const people = [
    {
      name: "18.04.19",
      date: "Jeu. 15.04",
    },
    {
      name: "18.04.19",
      date: "Jeu. 15.04",
    },
    {
      name: "18.04.19",
      date: "Jeu. 15.04",
    },
  ];

  const rdv = [
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
  ];

  // const data = [
  //   {loc: "80000 Amiens", besoin: 6},
  //   {loc: "80000 Amiens", besoin: 10},
  //   {loc: "62100 Calais", besoin: 8},
  // ];

  return (
    <div className={style.container}>
      <Home />
      {/* <div className={style.popupContainer}>
        <PopUp type={"bluePunch"} data={data} />
      </div> */}
      <div className={style.leftContainer}>
        <p style={{fontSize: 30, maxWidth: "50%"}}>
          Prospection du jour indirecte
        </p>
        <div className={style.historyContainer}>
          <p>Date</p>
          <img src="images/fournisseur5.png" style={{width: 32, height: 32}} />
        </div>
        <div className={style.rdvContainer}>
          {/* {people.map((el) => {
            return (
              <div className={style.rdv}>
                <p className={style.rdvName}>{el.name}</p>
                <p className={style.rdvDate}>Déposé le {el.date}</p>
              </div>
            );
          })} */}
        </div>
      </div>
      <div className={style.rightContainer}>
        <div className={style.tableTitle}>
          <p>Deuxième appel d'offre</p>
        </div>
        <div className={style.tableTitle2}>
          <p>Opération coup de poing</p>
        </div>
        <div className={style.table}>
          <div className={style.tableLeft}>
            {data
              .filter(
                (d) => d.data.status === "refused" && !d.data.secondDevisAt
              )
              .map((el) => {
                return (
                  <div className={style.rightSide}>
                    <div className={style.cardWidth}>
                      <Card2
                        besoin={el.data.lines.length}
                        besoinColor={"#B53729"}
                        loc={el.user.adressePostale}
                        locIsWhite={true}
                        changeStyle={{
                          backgroundColor: "#B53729",
                          color: "white",
                          height: 100,
                          width: "100%",
                        }}
                        day={
                          el.data.status == "pending"
                            ? moment(el.data.createdAt.toDate()).format(
                                "DD.MM.YYYY"
                              )
                            : moment(el.data.firstDevisAt.toDate()).format(
                                "DD.MM.YYYY"
                              )
                        }
                        end={
                          el.data.status == "pending"
                            ? moment(el.data.createdAt.toDate()).format("HH:mm")
                            : moment(el.data.firstDevisAt.toDate()).format(
                                "HH:mm"
                              )
                        }
                        button={"Deuxième relance"}
                        redirect={"/appeloffre/" + el.data.id}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={style.tableRight}>
            {data
              .filter((d) => ["sent", "pending"].includes(d.data.status))
              .map((el) => {
                return (
                  <div className={style.rightSide}>
                    <div className={style.cardWidth}>
                      <Card2
                        besoin={el.data.lines.length}
                        loc={el.user.adressePostale}
                        day={
                          el.data.status == "pending"
                            ? moment(el.data.createdAt.toDate()).format(
                                "DD.MM.YYYY"
                              )
                            : moment(el.data.firstDevisAt.toDate()).format(
                                "DD.MM.YYYY"
                              )
                        }
                        end={
                          el.data.status == "pending"
                            ? moment(el.data.createdAt.toDate()).format("HH:mm")
                            : moment(el.data.firstDevisAt.toDate()).format(
                                "HH:mm"
                              )
                        }
                        personName={
                          el.data.status == "approved" &&
                          el.restaurateur.name + " " + el.restaurateur.firstname
                        }
                        changeStyle={{
                          backgroundColor:
                            el.data.status == "sent" ? "#F6C745" : "#388ADC",
                          color: "white",
                          height: 100,
                          width: "100%",
                        }}
                        isPunch={true}
                        redirect={"/appeloffre/" + el.data.id}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    backgroundSize: "cover",
    fontFamily: "poppins-regular",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "100vh",
    width: "35%",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    overflow: "auto",
  },
  historyContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F6F6F6",
    width: "65%",
    height: 47,
    padding: 10,
    borderRadius: 5,
  },
  rdvContainer: {
    display: "flex",
    flexDirection: "column",
  },
  rdv: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 40,
  },
  rdvName: {
    fontFamily: "poppins-semiBold",
    fontSize: 70,
    color: "#26466D",
    margin: 0,
  },
  rdvDate: {
    fontFamily: "poppins-light",
    fontSize: 20,
    margin: 0,
    transform: "translateY(-20px)",
  },
  rightContainer: {
    display: "flex",
    maxHeight: "100vh",
    width: "65%",
    position: "relative",
  },
  table: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    borderRadius: 15,
    width: "80%",
    height: "80%",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    overflow: "auto",
  },
  tableLeft: {
    width: "50%",
  },
  tableRight: {
    width: "50%",
  },
  tableTitle: {
    position: "absolute",
    alignItems: "center",
    display: "flex",
    fontSize: 30,
    top: -10,
    left: "10%",
  },
  tableTitle2: {
    position: "absolute",
    alignItems: "center",
    display: "flex",
    fontSize: 30,
    top: -10,
    left: "52%",
  },
  logoTitle: {
    width: 23.5,
    height: 28,
    marginRight: 10,
  },
  leftSide: {
    display: "flex",
    marginTop: 30,
  },
  rightSide: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  cardWidth: {
    width: "85%",
  },
  popupContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    zIndex: 10,
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    overflow: "hidden",
  },
});

export default ProspectionPoing;
