import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../Components/Card";
import Home from "../../Components/Home";

const Messagerie = () => {
  const style = useStyles();
  const [userSelected, setUserSelected] = useState();

  const people = [
    {
      name: "nom",
      firstName: "prenom",
    },
    {
      name: "nom1",
      firstName: "prenom2",
    },
    {
      name: "nom3",
      firstName: "prenom3",
    },
  ];

  const rdv = [
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
  ];

  const msg = [
    {
      message:
        "hello ça va ? hello ça va ? hello ça va ?hello ça va ? hello ça va ? hello ça va ?",
      itsUser: true,
    },
    {
      message: "hello ça va ?",
    },
    {
      message: "hello ça va ?",
    },
    {
      message: "hello ça va ?",
      itsUser: true,
    },
    {
      message: "hello ça va ?",
      itsUser: true,
    },
  ];

  return (
    <div className={style.container}>
      <Home />
      <div className={style.leftContainer}>
        <p style={{ fontSize: 30, maxWidth: "50%", textAlign: "center" }}>
          Message
        </p>
        <div className={style.input_search_container}>
          <input placeholder="Rechercher" className={style.input_search} />
          <img
            src="images/search.png"
            className={style.logoTitle}
            style={{
              position: "absolute",
              transform: "translate(-50%, 50%)",
              top: 0,
              right: 0,
            }}
          />
        </div>
        <div className={style.rdvContainer}>
          {people.map((el) => {
            return (
              <div
                className={style.rdv}
                style={{
                  backgroundColor:
                    userSelected === el.name
                      ? "rgba(56, 138, 220, 0.2)"
                      : "white",
                  cursor: "pointer",
                }}
                onClick={() => setUserSelected(el.name)}
              >
                <p className={style.rdvDate}>{el.name}</p>
                <p className={style.rdvDate}>{el.firstName}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={style.rightContainer}>
        <div className={style.tableTitle}>
          <p>{userSelected}</p>
        </div>
        <div className={style.table}>
          {msg.map((el, i) => {
            return (
              <div className={style.cardContainer}>
                {!el.itsUser ? (
                  <div className={style.leftSide}>
                    <div
                      className={style.cardWidth}
                      style={{ backgroundColor: "rgba(56, 138, 220, 0.2)" }}
                    >
                      {el.message}
                    </div>
                  </div>
                ) : (
                  <div className={style.rightSide}>
                    <div className={style.cardWidth}>{el.message}</div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className={style.input_message_container}>
          <input placeholder="Messages" className={style.input_message} />
          <img
            src="images/send.png"
            className={style.logoTitle}
            style={{
              position: "absolute",
              transform: "translate(-50%, 50%)",
              top: 0,
              right: 0,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    backgroundSize: "cover",
    fontFamily: "poppins-regular",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "100vh",
    width: "35%",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    overflow: "auto",
  },
  historyContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F6F6F6",
    width: "65%",
    height: 47,
    padding: 10,
    borderRadius: 5,
  },
  rdvContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 20,
  },
  rdv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 85,
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
    paddingLeft: "15%",
  },
  rdvName: {
    fontFamily: "poppins-semiBold",
    fontSize: 70,
    color: "#26466D",
    margin: 0,
  },
  rdvDate: {
    fontFamily: "poppins-light",
    fontSize: 20,
    margin: 0,
  },
  rightContainer: {
    display: "flex",
    maxHeight: "100vh",
    width: "65%",
    position: "relative",
    // backgroundColor: "#B53729",
  },
  table: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 15,
    padding: "0 50px 0 50px",
    width: "80%",
    height: "80%",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    overflow: "auto",
    backgroundColor: "white",
  },
  tableTitle: {
    position: "absolute",
    alignItems: "center",
    display: "flex",
    fontSize: 30,
    top: 0,
    left: "10%",
  },
  logoTitle: {
    width: 28,
    height: 28,
    marginRight: 10,
  },
  leftSide: {
    display: "flex",
    marginTop: 30,
  },
  rightSide: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 30,
  },
  cardWidth: {
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    padding: 20,
    borderRadius: 10,
    maxWidth: "40%",
  },
  input_search_container: {
    position: "relative",
  },
  input_search: {
    height: 58,
    width: 307,
    border: "none",
    backgroundColor: "#F6F6F6",
    borderRadius: 50,
    paddingLeft: 20,
  },
  input_message_container: {
    position: "absolute",
    width: "80%",
    height: 58,
    top: "82%",
    left: "49%",
    transform: "translate(-50%, -50%)",
  },
  input_message: {
    height: "100%",
    width: "100%",
    borderRadius: 50,
    border: "none",
    backgroundColor: "#F6F6F6",
    paddingLeft: 20,
  },
});

export default Messagerie;
