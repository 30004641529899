import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLogin from "../Components/InputLogin";
import Home from "../Components/Home";

const FourProspectQrDetails = () => {
  const style = useStyles();

  const arrayElement = [
    {
      lib: "Farine de blé",
      weight: 150,
      price: 25,
      prop: 20,
    },
    {
      lib: "Farine de blé",
      weight: 150,
      price: 25,
      prop: 20,
    },
    {
      lib: "Farine de sègle",
      weight: 150,
      price: 25,
      prop: "Je peux mieux faire",
    },
    {
      lib: "Farine de sègle",
      weight: 150,
      price: 25,
      prop: "Je peux mieux faire",
    },
    {
      lib: "Farine de sègle",
      weight: 150,
      price: 25,
      prop: "Je peux mieux faire",
    },
    {
      lib: "Farine de sègle",
      weight: 150,
      price: 25,
      prop: "Je peux mieux faire",
    },
    {
      lib: "Farine de sègle",
      weight: 150,
      price: 25,
      prop: "Je peux mieux faire",
    },
    {
      lib: "Farine de sègle",
      weight: 150,
      price: 25,
      prop: "Je peux mieux faire",
    },
  ];

  return (
    <div className={style.container}>
      <Home />
      <p className={style.title}>Prospection du jour</p>
      <div className={style.buttonContainer}>
        <InputLogin
          text={"Je valide"}
          changeStyle={{
            backgroundColor: "#9BC070",
            border: "none",
            width: 300,
          }}
        />
      </div>
      <div className={style.table}>
        <div className={style.tableTop}>
          <p className={style.titlesTop} style={{ left: "5%" }}>
            LIBELLE
          </p>
          <p className={style.titlesTop} style={{ left: "30%" }}>
            POIDS
          </p>
          <p className={style.titlesTop} style={{ left: "55%" }}>
            PRIX DE BASE
          </p>
          <p className={style.titlesTop} style={{ left: "85%" }}>
            PRIX PROPOSE
          </p>
        </div>
        <div className={style.tableBottom}>
          {arrayElement.map((el, index) => {
            return (
              <div className={style.product}>
                <p
                  className={style.productElement}
                  style={{ left: "5%", fontFamily: "poppins-bold" }}
                >
                  {el.lib}
                </p>
                <p className={style.productElement} style={{ left: "30%" }}>
                  {el.weight} kg
                </p>
                <p className={style.productElement} style={{ left: "55%" }}>
                  {el.price} €
                </p>
                <p className={style.productElement} style={{ left: "85%" }}>
                  {typeof el.prop == "number" ? `${el.prop} €` : el.prop}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    height: "100vh",
    fontFamily: "poppins-regular",
    backgroundColor: "#B53729",
  },
  title: {
    position: "absolute",
    left: "10%",
    fontSize: 30,
  },
  table: {
    position: "absolute",
    transform: "translate(-50%, 0)",
    top: "15%",
    left: "50%",
    width: "80%",
    maxHeight: "60%",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    borderRadius: 20,
    overflow: "auto",
    backgroundColor: "white",
  },
  tableTop: {
    fontFamily: "poppins-bold",
    fontSize: 18,
    borderBottom: "1px solid #E3E3E3",
    height: 115,
    position: "relative",
  },
  titlesTop: {
    position: "absolute",
    transform: "translateY(-50%)",
    top: "50%",
    margin: 0,
  },
  product: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: 70,
  },
  productElement: {
    position: "absolute",
    transform: "translateY(-50%)",
    margin: 0,
    top: "50%",
  },
  buttonContainer: {
    position: "absolute",
    bottom: "5%",
    left: 0,
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
});

export default FourProspectQrDetails;
