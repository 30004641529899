import axios from "axios";
import moment from "moment";
const url = "https://api.open-meteo.com/v1/forecast";

const codes = {
  0: "Ensolleillé",
  1: "Ciel dégagé",
  2: "Éclaircies",
  3: "Ciel couvert",
  45: "Brouillard",
  48: "Brouillard verglaçant",
  51: "Légère bruine",
  53: "Bruine",
  55: "Forte bruine",
  56: "Burine verglaçante",
  57: "Forte bruine verglaçante",
  61: "Pluie légère",
  63: "Pluie",
  65: "Forte pluie",
  66: "Pluie verglaçante",
  67: "Forte pluie verglaçante",
  71: "Légère chute de neige",
  73: "Chute de neige",
  75: "Forte chute de neige",
  77: "Chute de neige",
  80: "Faibles averses",
  81: "Averses",
  82: "Violentes averses",
  85: "Averses de neige",
  86: "Violentes averses de neige",
  95: "Orage",
  96: "Orage",
  99: "Orage",
};
const icons = {
  0: "/images/weather/sunny-outline.svg",
  1: "/images/weather/sunny-outline.svg",
  2: "/images/weather/partly-sunny-outline/svg",
  3: "/images/weather/cloudy-outline.svg",
  45: "/images/weather/cloudy-outline.svg",
  48: "/images/weather/cloudy-outline.svg",
  51: "/images/weather/rainy-outline.svg",
  53: "/images/weather/rainy-outline.svg",
  55: "/images/weather/rainy-outline.svg",
  56: "/images/weather/rainy-outline.svg",
  57: "/images/weather/rainy-outline.svg",
  61: "/images/weather/rainy-outline.svg",
  63: "/images/weather/rainy-outline.svg",
  65: "/images/weather/rainy-outline.svg",
  66: "/images/weather/rainy-outline.svg",
  67: "/images/weather/rainy-outline.svg",
  71: "/images/weather/snow-outline.svg",
  73: "/images/weather/snow-outline.svg",
  75: "/images/weather/snow-outline.svg",
  77: "/images/weather/snow-outline.svg",
  80: "/images/weather/rainy-outline.svg",
  81: "/images/weather/rainy-outline.svg",
  82: "/images/weather/rainy-outline.svg",
  85: "/images/weather/snow-outline.svg",
  86: "/images/weather/snow-outline.svg",
  95: "/images/weather/thunderstorm-outline.svg",
  96: "/images/weather/thunderstorm-outline.svg",
  99: "/images/weather/thunderstorm-outline.svg",
};

const useWeather = () => {
  const getWeather = async (lat, lon, timezone = "Europe/Paris") => {
    try {
      const res = await axios.get(
        `${url}?latitude=${lat}&longitude=${lon}&hourly=weathercode,temperature_2m&daily=weathercode,temperature_2m_max&timezone=${timezone}`
      );
      const data = res.data;
      const hour = moment().get("hour");
      const current = {
        icon: icons[data.hourly.weathercode[hour]],
        code: data.hourly.weathercode[hour],
        temp: data.hourly.temperature_2m[hour],
        weather: codes[data.hourly.weathercode[hour]],
      };

      const predictions = data.daily.time.slice(1).map((el, i) => ({
        date: el,
        code: data.daily.weathercode[i + 1],
        temp: data.daily.temperature_2m_max[i + 1],
        weather: codes[data.daily.weathercode[i + 1]],
        icon: icons[data.daily.weathercode[i + 1]],
      }));

      return {current, predictions};
    } catch (error) {
      console.log(error.response);
      return Promise.reject(error);
    }
  };

  return getWeather;
};

export default useWeather;
