import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate, useParams } from "react-router-dom";
import InputLogin from "../../Components/InputLogin";

const FournisseurSelectType = () => {
  const style = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    console.log("l12 ", params);
  }, []);

  return (
    <div className={style.containers}>
      <div
        className={style.inputContainer}
        style={{ backgroundImage: "url('../../images/registerPage2-1.png')" }}
      >
        <div
          className={style.buttons}
          onClick={() => {
            if (!params.inscription) {
              navigate("/connexion");
            } else {
              navigate("/inscription/fournisseur/salarie/relation");
            }
          }}
        >
          <InputLogin text={"Salarié / Indépendant"} />
        </div>
      </div>
      <div
        className={style.inputContainer}
        style={{ backgroundImage: "url('../../images/registerPage2-2.png')" }}
      >
        <div
          className={style.buttons}
          onClick={() => {
            if (!params.inscription) {
              navigate("/connexion");
            } else {
              navigate("/inscription/fournisseur/siege/relation");
            }
          }}
        >
          <InputLogin text={"Siège / Hiérarchie supérieur"} />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  containers: {
    display: "flex",
    width: "100%",
    height: "100vh",
  },
  inputContainer: {
    width: "50%",
    backgroundSize: "cover",
    position: "relative",
  },
  buttons: {
    position: "absolute",
    width: "50%",
    transform: "translate(-50%, -50%)",
    top: "80%",
    left: "50%",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default FournisseurSelectType;
