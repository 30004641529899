import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const DashboardZoom = ({ changeStyle, rotate }) => {
  const style = useStyles();

  return (
    <div className={style.home} style={changeStyle}>
      <img
        src="images/search-plus.png"
        style={
          rotate === true
            ? { width: 29, height: 27, transform: "rotateY(180deg)" }
            : { width: 29, height: 27 }
        }
      />
    </div>
  );
};

const useStyles = makeStyles({
  home: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#388ADC",
    width: 106,
    height: 106,
    position: "absolute",
    bottom: "-3.5%",
    left: "-3.5%",
    zIndex: 5,
  },
});

export default DashboardZoom;
