import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Home from "../../Components/Home";
import {useNavigate, useParams} from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "@firebase/firestore";
import useFirebase from "../../hooks/useFirebase";
import {InfinitySpin} from "react-loader-spinner";
import {toast, Toaster} from "react-hot-toast";

const SupportRendezVous = () => {
  const [id, setId] = useState("none");
  const [marche, setMarche] = useState("none");
  const [infos, setInfos] = useState("none");
  const [data, setData] = useState([]);
  const {db, user} = useFirebase();
  const {rendezvousId} = useParams();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const style = useStyles();

  const handleCarteId = () => {
    setInfos("none");
    setMarche("none");
    setId("flex");
  };
  const handleGraph = () => {
    setInfos("none");
    setId("none");
    setMarche("flex");
  };
  const handleInfos = () => {
    setMarche("none");
    setId("none");
    setInfos("flex");
  };

  useEffect(() => {
    user &&
      (async () => {
        const docRef = doc(db, "rendez_vous", rendezvousId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const rvData = docSnap.data();

          const devisRef = await getDoc(rvData.devisRef);
          const devisData = devisRef.data();

          const docRef3 = await getDoc(rvData.fournisseur);
          const fournisseurData = docRef3.data();

          setData({
            fournisseur: fournisseurData,
            rendezVous: rvData,
            devis: devisData,
          });

          setLoading(false);
        } else {
          toast.error("Ce rendez-vous n'existe pas");
          navigate("/jourj");
        }
      })();
  }, [user]);

  const handleValidate = async () => {
    await updateDoc(doc(db, "rendez_vous", rendezvousId), {
      status: "finished",
    });
    // navigate("/notation")
    navigate("/jourj");
  };

  return (
    <div className={style.container}>
      <Home />
      <Toaster />
      <div className={style.rightContainer}>
        <div className={style.rightContainerCenter}>
          <p className={style.rightContainerRdv}>Mon support de rendez-vous</p>
          <p className={style.rightContainerActu}>Mes actualités</p>
          <div className={style.imgContainer}>
            <img
              src="/images/carteId2.png"
              className={style.img}
              onClick={() => handleCarteId()}
            />
            <img
              src="/images/graph.png"
              className={style.img}
              onClick={() => handleGraph()}
            />
            <img
              src="/images/infosActu.png"
              className={style.img}
              onClick={() => handleInfos()}
            />
          </div>
          <div className={style.rightTable}>
            {!loading ? (
              <div className={style.rightTableId} style={{display: id}}>
                <p className={style.idElementsStyle}>
                  Nom: {data.fournisseur.name}
                </p>
                <p className={style.idElementsStyle}>
                  Prénom: {data.fournisseur.firstname}
                </p>
                <p className={style.idElementsStyle}>
                  Adresse email: {data.fournisseur.email}
                </p>
              </div>
            ) : null}
            <div
              className={style.marcheElementsStyle}
              style={{display: marche}}
            >
              <img src="/images/info.png" className={style.marcheImg} />
              <div className={style.marcheInput}>
                <p>Mon produit:</p>
                <input type="text" className={style.marcheInputEl} />
              </div>
              <div className={style.marchePricesContainer}>
                <div className={style.marchePrices}>
                  <div
                    className={style.marcheCircle}
                    style={{backgroundColor: "#B4E197"}}
                  ></div>
                  <p style={{margin: 0}}>Prix minimum:</p>
                </div>
                <div className={style.marchePrices}>
                  <div
                    className={style.marcheCircle}
                    style={{backgroundColor: "#B53729"}}
                  ></div>
                  <p style={{margin: 0}}>Prix maximum:</p>
                </div>
                <div className={style.marchePrices}>
                  <div
                    className={style.marcheCircle}
                    style={{backgroundColor: "black"}}
                  ></div>
                  <p style={{margin: 0}}>Non renseigné:</p>
                </div>
              </div>
            </div>
            <div className={style.infosElementsStyle} style={{display: infos}}>
              <img src="/images/info.png" className={style.marcheImg} />
              <div className={style.infosContainer}>
                <WarningAmberIcon style={{fontSize: 35, marginRight: 20}} />
                <p>Pénurie sur le beurre</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.leftContainer}>
        <div className={style.leftTable}>
          <div className={style.tableTop}>
            <p className={style.topDevis}>Devis</p>
            <div className={style.bottomLibs}>
              <p className={style.topLib}>Libélé</p>
              <p className={style.topPrice}>Prix</p>
            </div>
          </div>
          <div className={style.tableBottom}>
            {!loading &&
              data.devis.lines.map((el, index) => {
                return (
                  <div
                    className={style.devisContainer}
                    style={
                      index % 2 == 0
                        ? {backgroundColor: "#E2E2E2"}
                        : {backgroundColor: "#F6F6F6"}
                    }
                  >
                    <div className={style.devisInfos}>
                      {el.title}
                      <br></br>
                      {el.basedQty + " " + el.unit}
                    </div>
                    <p className={style.devisPrice}>{el.price} €</p>
                  </div>
                );
              })}
          </div>
        </div>
        <button className={style.validate} onClick={() => handleValidate()}>
          Rendez-vous terminé
        </button>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    height: "100vh",
    fontFamily: "poppins-regular",
  },
  rightContainer: {
    display: "flex",
    justifyContent: "center",
    width: "50%",
    height: "100%",
  },
  rightContainerCenter: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
  },
  rightContainerRdv: {
    fontSize: 30,
  },
  rightContainerActu: {
    fontSize: 25,
  },
  imgContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  img: {
    width: "28%",
    height: "auto",
    "&:hover": {
      cursor: "pointer",
    },
  },
  rightTable: {
    width: "100%",
    height: "35%",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    borderRadius: 20,
    marginTop: "5%",
    position: "relative",
  },
  rightTableId: {
    position: "absolute",
    top: 20,
    left: 20,
    fontSize: 25,
    display: "flex",
    flexDirection: "column",
  },
  idElementsStyle: {
    margin: 0,
    wordBreak: "break-all",
  },
  marcheElementsStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
    fontSize: 25,
    overflow: "auto",
  },
  marcheImg: {
    position: "absolute",
    top: 20,
    left: 20,
    height: 40,
  },
  marcheInput: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "2%",
  },
  marcheInputEl: {
    width: "35%",
    height: "50%",
    marginLeft: 20,
    borderRadius: 50,
  },
  marchePricesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  marchePrices: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  marcheCircle: {
    backgroundColor: "blue",
    height: 20,
    width: 20,
    borderRadius: "50%",
    marginRight: 10,
  },
  infosElementsStyle: {
    display: "flex",
    justifyContent: "center",
  },
  infosContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
  },
  leftContainer: {
    width: "50%",
    position: "relative",
  },
  leftTable: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    borderRadius: 20,
    minHeight: "70%",
    maxHeight: "70%",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    overflow: "auto",
  },
  tableTop: {
    height: 130,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  topDevis: {
    margin: "0 0 0 20",
    fontSize: 25,
    paddingInline: 20,
  },
  topLib: {
    margin: 0,
    fontSize: 15,
  },
  topPrice: {
    margin: 0,
    fontSize: 15,
    textAlign: "start",
    width: 75,
  },
  bottomLibs: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    paddingLeft: 60,
    paddingRight: 40,
  },
  devisContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 60,
    paddingRight: 40,
    fontSize: 20,
    height: 105,
  },
  devisInfos: {
    margin: 0,
    alignSelf: "flex-start",
  },
  devisPrice: {
    margin: 0,
    // marginTop: -20,
    alignSelf: "flex-start",
    textAlign: "start",
    width: 75,
  },
  validate: {
    position: "absolute",
    bottom: "2%",
    right: "10%",
    backgroundColor: "#388ADC",
    color: "white",
    border: "none",
    fontSize: 15,
    height: 60,
    width: 200,
    borderRadius: 10,
    cursor: "pointer",
  },
});

export default SupportRendezVous;
