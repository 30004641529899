import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Home from "../Components/Home";
import Card2 from "../Components/Card2";
import useFirebase from "../hooks/useFirebase";
import {
  collection,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import moment from "moment";

const JourJ = () => {
  const style = useStyles();
  const [rdv, setRdv] = useState([]);
  const { db, user } = useFirebase();

  useEffect(() => {
    user &&
      (async () => {
        const _rdvs = query(
          collection(db, "rendez_vous"),
          where("restaurateur", "==", user.ref),
          orderBy("start", "asc")
        );

        const docsSnap = await getDocs(_rdvs);

        const docs = await Promise.all(
          docsSnap.docs.map(async (event) => {
            const fournisseur = await getDoc(event.data().fournisseur);
            const devis = await getDoc(event.data().devisRef);

            return {
              data: {
                ...event.data(),
                ref: event.ref,
                id: event.id,
              },
              user: {
                ...fournisseur.data(),
                ref: fournisseur.ref,
                id: fournisseur.id,
              },
              devis: {
                ...devis.data(),
                ref: devis.ref,
                id: devis.id,
              },
            };
          })
        );

        setRdv(docs);
      })();
  }, [user]);

  return (
    <div className={style.container}>
      <Home />
      <div className={style.leftContainer}>
        <div className={style.topContainer}>
          <p style={{ fontSize: 30 }}>Historique</p>
        </div>
        <div className={style.rdvContainer2}>
          {rdv
            .filter((el) => el.data.status == "finished")
            .map((el, index) => {
              return (
                <div key={index} className={style.leftSide}>
                  <Card2
                    personName={el.user.name + " " + el.user.firstname}
                    start={el.data.start}
                    end={el.data.end}
                    day={moment(el.data.date).format("DD.MM.YYYY")}
                    besoin={el.devis.lines.length}
                    isTimer
                    timerText="Support"
                    redirect={"/supportrendezvous/" + el.data.id}
                    changeStyle={{
                      width: "100%",
                    }}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div className={style.rightContainer}>
        <div className={style.tableTitle}>
          <p>Rendez-vous</p>
        </div>
        <div className={style.table}>
          {rdv
            .filter(
              (el) =>
                el.data.date != moment(new Date()).format("YYYY-MM-DD") &&
                ["approved"].includes(el.data.status)
            )
            .map((el, index) => {
              return (
                <div
                  key={index}
                  className={index % 2 == 0 ? style.leftSide : style.rightSide}
                >
                  <Card2
                    personName={el.user.name + " " + el.user.firstname}
                    start={el.data.start}
                    end={el.data.end}
                    day={moment(el.data.date).format("DD.MM.YYYY")}
                    besoin={el.devis.lines.length}
                    isTimer
                    timerText="Support"
                    redirect={"/supportrendezvous/" + el.data.id}
                    changeStyle={{
                      width: "60%",
                    }}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    minHeight: "100vh",
    fontFamily: "poppins-regular",
    justifyContent: "space-between",
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "33%",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
  },
  historyContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F6F6F6",
    width: "65%",
    height: 47,
    padding: 10,
    borderRadius: 5,
  },
  rdvContainer: {
    display: "flex",
    flexDirection: "column",
  },
  rdvContainer2: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    gap: 30,
  },
  rdv: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 40,
  },
  rdvName: {
    fontFamily: "poppins-semiBold",
    fontSize: 70,
    color: "#26466D",
    margin: 0,
  },
  rdvDate: {
    fontFamily: "poppins-light",
    fontSize: 20,
    margin: 0,
    transform: "translateY(-20px)",
  },
  rightContainer: {
    width: "63%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: 50,
  },
  table: {
    borderRadius: 15,
    width: "80%",
    padding: "5%",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    display: "flex",
    flexDirection: "column",
    gap: 30,
    minHeight: "50vh",
  },
  tableTitle: {
    alignItems: "center",
    display: "flex",
    alignSelf: "flex-start",
    fontSize: 30,
  },
  logoTitle: {
    width: 35,
    height: 35,
    marginRight: 10,
  },
  leftSide: {
    display: "flex",
    // marginTop: 30,
  },
  rightSide: {
    display: "flex",
    justifyContent: "flex-end",
    // marginTop: 30,
  },
  cardWidth: {
    width: "60%",
    // margin: "0 70px 0 50px",
  },
});

export default JourJ;
