import {
  collection,
  collectionGroup,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {Calendar} from "react-calendar";
import {useCookies} from "react-cookie";
import {InfinitySpin} from "react-loader-spinner";
import {useNavigate} from "react-router-dom";
import Card from "../../Components/Card";
import Card2 from "../../Components/Card2";
import DashboardZoom from "../../Components/DashboardZoom";
import {useFirebase} from "../../hooks/useFirebase";
import PopUp from "../PopUp";

const DashboardRestaurateur = () => {
  const style = useStyles();
  const navigate = useNavigate();
  const [closePop, setClosePop] = useState(true);
  const [closePopInd, setClosePopInd] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [value, onChange] = useState(new Date());
  const [devisOffre, setDevisOffre] = useState([]);
  const {user, logout, db} = useFirebase();
  const [myEventsList, setMyEventsList] = useState([]);
  const [currentDay, setCurrentDay] = useState(moment().format("YYYY-MM-DD"));
  const [loadingRendezVous, setLoadingRendezVous] = useState(true);
  const [loadingOffers, setLoadingOffers] = useState(true);
  const [todayOffers, setTodayOffers] = useState([]);
  const [searchMarche, setSearchMarche] = useState("");
  const [coursMarche, setCoursMarche] = useState([]);
  const [marcheSearch, setMarcheSearch] = useState([]);
  const [loadingMarche, setLoadingMarche] = useState(true);

  const disconnect = async () => {
    logout();
    removeCookie("userToken");
    navigate("/");
  };

  useEffect(() => {
    console.log("l20 ", value);
  }, [value]);

  useEffect(() => {
    const pop = document.querySelector("#qr");
    if (closePop === true) {
      console.log("open");
      pop.style.transform = "translateY(100%)";
    } else {
      console.log("close");
      pop.style.transform = "translateY(0)";
    }
  }, [closePop]);

  useEffect(() => {
    const pop = document.querySelector("#ind");
    if (closePopInd === true) {
      console.log("open");
      pop.style.transform = "translateY(100%)";
    } else {
      console.log("close");
      pop.style.transform = "translateY(0)";
    }
  }, [closePopInd]);

  useEffect(() => {
    (async () => {
      const devisRef = query(
        collection(db, "devis"),
        where("restaurateur", "==", user.ref),
        orderBy("firstDevisAt", "desc")
      );

      const docSnap = await getDocs(devisRef);
      const docs = await Promise.all(
        docSnap.docs
          .filter((el) => el.data().status === "sent")
          .map(async (qr) => {
            const _user = await getDoc(qr.data().fournisseur);

            return {
              data: {
                ...qr.data(),
                ref: qr.ref,
                id: qr.id,
              },
              user: {
                ..._user.data(),
                ref: _user.ref,
              },
            };
          })
      );

      const docs2 = await Promise.all(
        docSnap.docs.filter(
          (el) =>
            el.data().status === "sent" &&
            moment(el.data().firstDevisAt.toDate()).format("YYYY-MM-DD") ===
              currentDay
        )
      );

      setDevisOffre(docs);
      setTodayOffers(docs2);
      setLoadingOffers(false);
    })();
  }, [user]);

  useEffect(() => {
    user &&
      (async () => {
        const _events = query(
          collection(db, "rendez_vous"),
          where("restaurateur", "==", user.ref),
          orderBy("start", "asc")
        );
        const docSnap = await getDocs(_events);

        const docs = await Promise.all(
          docSnap.docs
            .filter((el) => el.data().status != "refused")
            .map(async (event) => {
              const fournisseur = await getDoc(event.data().fournisseur);

              return {
                data: {
                  ...event.data(),
                  ref: event.ref,
                  id: event.id,
                },
                user: {
                  ...fournisseur.data(),
                  ref: fournisseur.ref,
                },
              };
            })
        );

        setMyEventsList(docs);
        setLoadingRendezVous(false);
      })();
  }, [user]);

  useEffect(() => {
    (async () => {
      const q = query(collectionGroup(db, "produits_courants"));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => doc.data());
      const flatData = data.flatMap((item) => item.produits);

      // if the name of the product is the same, put it in the same array and make the average price
      const result = Object.values(
        flatData.reduce((acc, {name, price, weight, unit}) => {
          name = name.charAt(0).toUpperCase() + name.slice(1);
          if (!acc[name]) {
            acc[name] = {
              name,
              price: [parseInt(price)],
              weight: parseFloat(weight),
              unit,
            };
          } else {
            acc[name].price.push(parseInt(price));
          }
          return acc;
        }, {})
      );

      // make the average price
      const averagePrice = result.map((item) => {
        const average = item.price.reduce((a, b) => a + b) / item.price.length;
        return {
          name: item.name,
          weight: item.weight,
          unit: item.unit,
          moyennePrice: average.toFixed(2),
          maxPrice: Math.max(...item.price).toFixed(2),
          minPrice: Math.min(...item.price).toFixed(2),
        };
      });

      // sort by price
      // const sortedData = averagePrice.sort((a, b) => a.price - b.price);

      setCoursMarche(averagePrice);
      setLoadingMarche(false);
      console.log(averagePrice);
    })();
  }, [db]);

  const tileContent = ({date, view}) => {
    const day = moment(date).format("YYYY-MM-DD");
    const events = myEventsList.filter((el) => el.data.date === day);
    return (
      <div className={style.dotContainer}>
        {events.length > 0 && (
          <div className={day === currentDay ? style.dotToday : style.dot} />
        )}
      </div>
    );
  };

  const rdv = [
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
    {
      loc: "80000 Amiens",
      besoin: 6,
    },
  ];

  // const handleSearchMarche = (e) => {
  //   setSearchMarche(e.target.value);

  //   if (e.target.value.length > 0) {
  //     setMarcheSearch(
  //       coursMarche.filter((el) =>
  //         el.name.toLowerCase().startsWith(e.target.value.toLowerCase())
  //       )
  //     );
  //   } else {
  //     setMarcheSearch([]);
  //   }
  // };

  useEffect(() => {
    if (searchMarche.length > 0) {
      setMarcheSearch(
        coursMarche.filter((el) =>
          el.name.toLowerCase().startsWith(searchMarche.toLowerCase())
        )
      );
    } else {
      setMarcheSearch([]);
    }
  }, [searchMarche]);

  return (
    <div className={style.container}>
      <div className={style.deco} onClick={() => disconnect()}>
        <p className={style.decoText}>Déconnexion</p>
      </div>
      <div className={style.voir} onClick={() => navigate("/propositions")}>
        <p>
          VOIR<br></br>PLUS
        </p>
      </div>
      <div className={style.popUpContainer} id="qr">
        <PopUp
          type={"green"}
          data={rdv}
          close={closePop}
          key="test"
          setClosePop={setClosePop}
          closePop={closePop}
        />
      </div>
      <div className={style.popUpContainer} id="ind">
        <PopUp
          type={"bluePunch"}
          data={rdv}
          close={closePop}
          key="test"
          setClosePop={setClosePopInd}
          closePop={closePopInd}
        />
      </div>
      <div className={style.topContainer}>
        <div className={style.topContainerLeft}>
          <div
            onClick={() => navigate("/cours")}
            className={style.zoomContainers}
          >
            <DashboardZoom rotate={true} />
          </div>
          <div className={style.geoContainer}>
            <p style={{margin: 0}}>Cours du marché</p>
          </div>
          <div className={style.carte}>
            <div className={style.carteTop}>
              <p className={style.carteTitle}>Mon produit:</p>
              <input
                type="text"
                className={style.carteInput}
                onChange={(e) => setSearchMarche(e.target.value)}
                value={searchMarche}
              />
            </div>
            {searchMarche && marcheSearch.length > 1 && (
              <div className={style.carteMiddle}>
                {marcheSearch.map((el, index) => {
                  return (
                    <p
                      key={index}
                      style={{
                        margin: 0,
                        fontSize: 20,
                        fontWeight: "bold",
                        cursor: "pointer",
                        flexShrink: 0,
                      }}
                      onClick={() => setSearchMarche(el.name)}
                    >
                      {el.name}
                      {index !== marcheSearch.length - 1 && ", "}
                    </p>
                  );
                })}
              </div>
            )}

            <div className={style.carteBottom}>
              <div className={style.carteBottomFiled}>
                <div
                  className={style.fieldDot}
                  style={{backgroundColor: "#9BC070"}}
                ></div>
                <p className={style.fieldText}>
                  Prix minimum:{" "}
                  {marcheSearch.length === 1 && marcheSearch[0].minPrice + "€"}
                </p>
              </div>
              <div className={style.carteBottomFiled}>
                <div
                  className={style.fieldDot}
                  style={{backgroundColor: "#B53729"}}
                ></div>
                <p className={style.fieldText}>
                  Prix maximum:{" "}
                  {marcheSearch.length === 1 && marcheSearch[0].maxPrice + "€"}
                </p>
              </div>
              <div className={style.carteBottomFiled}>
                <div
                  className={style.fieldDot}
                  style={{backgroundColor: "black"}}
                ></div>
                <p className={style.fieldText}>
                  Prix moyen:{" "}
                  {marcheSearch.length === 1 &&
                    marcheSearch[0].moyennePrice + "€"}
                </p>
              </div>
              <div className={style.carteBottomFiled}>
                <div
                  className={style.fieldDot}
                  style={{backgroundColor: "#388ADC"}}
                ></div>
                <p className={style.fieldText}>Non renseigné</p>
              </div>
            </div>
          </div>
        </div>
        <div className={style.topContainerRight}>
          <div
            onClick={() => navigate("/support")}
            style={{"&:hover": {cursor: "pointer"}}}
            className={style.zoomContainers}
          >
            <DashboardZoom changeStyle={{left: "90.5%"}} />
          </div>
          <div className={style.orgaContainer}>
            <p style={{margin: 0}}>Organisation</p>
            <img
              src="images/bell.png"
              style={{height: 40, marginLeft: 15}}
              alt="bell"
            />
          </div>
          <div className={style.calendarContainer}>
            {/* search props selectRange & defaultValue to add dots, use ::after in css file to style dots */}
            <Calendar
              className={"dashboardCalendar"}
              // onClickDay={(day) => {
              //   setCurrentDay(moment(day).format("YYYY-MM-DD"));
              // }}
              onChange={onChange}
              value={value}
              tileContent={loadingRendezVous ? () => <div></div> : tileContent}
            />
          </div>
        </div>
      </div>
      <div className={style.middleContainer}>
        <img
          src="images/panier.png"
          style={{left: "45%"}}
          className={style.logos}
          onClick={() => navigate("/produits")}
          alt="panier"
        />
        <img
          src="images/historique.png"
          style={{left: "55%"}}
          className={style.logos}
          onClick={() => navigate("/historique")}
          alt="historique"
        />
        <img
          src="images/meteo.png"
          style={{left: "90%"}}
          className={style.logos}
          onClick={() => navigate("/meteo")}
          alt="meteo"
        />
        <img
          src="images/leaf.png"
          style={{left: "95%", height: "5vh"}}
          className={style.logos}
          onClick={() => navigate("/ecoresponsable")}
          alt="ecoresponsable"
        />
      </div>
      <div className={`${style.bottomContainer}`}>
        <div className={style.bottomContainerLeft}>
          <div className={style.bottomContainerLeftCenter}>
            <div className={style.bottomContainerLeftTop}>
              <p className={style.offre}>Offre du jour</p>
              <p className={style.offreNumber}>
                {todayOffers.length > 0 ? todayOffers.length : "0"}
              </p>
            </div>
            <div className={style.bottomContainerLeftBottom}>
              <p className={style.date}>
                {moment(currentDay).format("DD.MM.YY")}
              </p>
            </div>
          </div>
        </div>
        <div className={style.bottomContainerRight}>
          <div className={style.bottomContainerRightTop}>
            <img
              src="images/trace.png"
              style={{height: 30, marginRight: 15}}
              alt="trace"
            />
            <p>Dernière propositions</p>
          </div>
          {/* <div className={style.bottomContainerRightBottom}> */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: !loadingOffers ? "flex-start" : "center",
              gap: 40,
              marginLeft: 50,
            }}
          >
            {!loadingOffers ? (
              devisOffre.slice(0, 2).map((el, index) => {
                return (
                  <div
                    className={style.cardContainer}
                    key={index}
                    onClick={() => navigate("/offre/" + el.data.id)}
                  >
                    <Card2
                      besoin={el?.data?.lines.length}
                      day={moment(el.data.firstDevisAt.toDate()).format(
                        "DD.MM.YYYY"
                      )}
                      end={moment(el?.data?.firstDevisAt.toDate()).format(
                        "HH:mm"
                      )}
                      changeStyle={{
                        width: 400,
                        height: 110,
                      }}
                    />
                  </div>
                );
              })
            ) : (
              <InfinitySpin width="200" color="#388ADC" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100%",
    fontFamily: "poppins-regular",
    fontSize: 30,
    overflow: "hidden",
    position: "relative",
  },
  topContainer: {
    height: "55vh",
    width: "100%",
    display: "flex",
  },
  topContainerLeft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    backgroundColor: "#E2E2E2",
    position: "relative",
  },
  geoContainer: {
    position: "absolute",
    top: 5,
    left: 30,
  },
  direct: {
    margin: 0,
    color: "white",
    backgroundColor: "#F6C745",
    width: "50%",
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 15,
    padding: "2px 5px 2px 5px",
  },
  carte: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
    // height: 300,
    maxHeight: "70%",
    backgroundColor: "white",
    boxShadow: "0 3px 12px rgb(0,0,0, 0.16)",
    borderRadius: 25,
    overflow: "hidden",
    padding: "20px 0 20px 0",
  },
  carteTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  carteTitle: {
    fontSize: 25,
  },
  carteInput: {
    height: 43,
    width: 220,
    marginLeft: 20,
    borderRadius: 50,
    border: "1px solid black",
    fontFamily: "poppins-regular",
    fontSize: 20,
    paddingLeft: 20,
  },
  carteMiddle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflowX: "scroll",
    width: "80%",
    gap: 10,
    margin: "10px 0",

    "&::-webkit-scrollbar": {
      backgroundColor: "#E2E2E2",
      borderRadius: 10,
      height: 10,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#388ADC",
      borderRadius: 10,
    },
  },
  carteBottomFiled: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  fieldDot: {
    height: 15,
    width: 15,
    borderRadius: "100%",
    marginRight: 15,
  },
  fieldText: {
    fontSize: 25,
    margin: 0,
  },
  zoomContainers: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  topContainerRight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    height: "100%",
    position: "relative",
  },
  calendar: {
    height: "70%",
  },
  orgaContainer: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: 5,
    right: 30,
  },
  middleContainer: {
    display: "flex",
    height: "10vh",
    width: "100%",
    backgroundColor: "white",
    boxShadow: "0 3px 12px rgb(0,0,0, 0.16)",
    zIndex: 10,
    position: "relative",
  },
  logos: {
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    height: "7vh",
    "&:hover": {
      cursor: "pointer",
    },
  },
  bottomContainer: {
    display: "flex",
    height: "35vh",
    width: "100%",
    position: "relative",
  },
  bottomContainerLeft: {
    height: "100%",
    width: "20%",
    boxShadow: "0 3px 12px rgb(0,0,0, 0.16)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bottomContainerLeftTop: {
    display: "flex",
    flexWrap: "nowrap",
    marginBottom: 20,
  },
  offre: {
    margin: "0 15px 0 0 ",
  },
  offreNumber: {
    margin: 0,
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
    padding: "0 25px 0 25px",
    fontSize: 25,
    display: "flex",
    alignItems: "center",
    borderRadius: 10,
  },
  date: {
    fontFamily: "poppins-semiBold",
    fontSize: 60,
    color: "#26466D",
    margin: 0,
  },
  depose: {
    margin: 0,
    fontSize: 18,
    transform: "translateY(-90%)",
  },
  bottomContainerRight: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
  },
  bottomContainerRightTop: {
    display: "flex",
    alignItems: "center",
    marginLeft: 50,
  },
  bottomContainerRightBottom: {
    display: "flex",
    flexWrap: "nowrap",
    marginLeft: 50,
  },
  cardContainer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  cards: {
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    height: "90%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  popUpContainer: {
    position: "absolute",
    width: "100%",
    zIndex: 10,
    bottom: 0,
    overflow: "hidden",
    transform: "translateY(100%)",
    height: "35vh",
  },
  voir: {
    position: "absolute",
    backgroundColor: "#388ADC",
    height: 150,
    width: 150,
    bottom: "-4%",
    right: "-2%",
    zIndex: 100,
    display: "flex",
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    fontSize: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  calendarContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
    width: "60%",
  },
  deco: {
    position: "absolute",
    top: -25,
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1000,
    height: 100,
    width: 100,
    borderRadius: "100%",
    backgroundColor: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  decoText: {
    color: "white",
    fontSize: 12,
    margin: 0,
  },
  dotContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
  },
  dot: {
    width: 5,
    height: 5,
    borderRadius: "50%",
    backgroundColor: "#388ADC",
    // margin: "0 auto",
  },
  dotToday: {
    width: 5,
    height: 5,
    borderRadius: "50%",
    backgroundColor: "#fff",
  },
});

export default DashboardRestaurateur;
