import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactStars from "react-rating-stars-component";
import theme from "../../theme";
import Home from "../../Components/Home";

const Notation = () => {
  const style = useStyles();

  const thirdExample = {
    size: 50,
    count: 5,
    isHalf: false,
    color: "#C1C1C1",
    activeColor: "yellow",
    emptyIcon: <i className="fa fa-star" />,
    filledIcon: <i className="fa fa-star" />,
  };

  const criteres = [
    {
      name: "Ponctualité",
      stars: 0,
    },
    {
      name: "Tenue vestimentaire",
      stars: 0,
    },
    {
      name: "Force de proposition",
      stars: 0,
    },
    {
      name: "Professionnalisme ",
      stars: 0,
    },
    {
      name: "Politesse",
      stars: 0,
    },
    {
      name: "Courtoisie",
      stars: 0,
    },
  ];

  return (
    <div className={style.container}>
      <Home />
      <div className={style.top}>
        <p>
          <strong>Notation anonyme</strong>
          <br></br>
          <br></br>
          Car il est important de valoriser un bon representant comme de
          sensibiliser un commerciale vous ayant offert un rendez vous qui
          laisse a désirer. Prospection commerciale, vous serez grée de bien
          vouloir noter cette entrevue.
          <br />
          (Soyez impartial et intransigeant)
        </p>
      </div>
      <div className={style.bottom}>
        {criteres.map((el, index) => {
          return (
            <div className={style.rateContainer}>
              <p className={style.rateText}>{el.name}</p>
              <ReactStars
                {...thirdExample}
                value={el.stars}
                onChange={(newValue) => (criteres[index].stars = newValue)}
              />
            </div>
          );
        })}
        <button className={style.validate}>Valider</button>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "poppins-regular",
    fontSize: 20,
    paddingBottom: 50,
    // height: "100vh",
    // overflow: "hidden",
  },
  top: {
    width: "80%",
    margin: "60px 0 50px 40px",
  },
  bottom: {
    marginLeft: 40,
    // overflow: "auto",
  },
  rateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "75%",
  },
  rateText: {
    fontSize: 50,
    fontWeight: 700,
    margin: 0,
    whiteSpace: "nowrap",
  },
  validate: {
    position: "fixed",
    bottom: 20,
    right: 20,
    border: "none",
    backgroundColor: "#388ADC",
    color: "white",
    height: 80,
    width: 200,
    fontSize: 20,
    borderRadius: 10,
  },
});

export default Notation;
