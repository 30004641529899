import logo from "./logo.svg";
import "./App.css";
import { makeStyles } from "tss-react/mui";
import Login from "./Pages/Login";

import { Routes, Route } from "react-router-dom";
import Relation from "./Pages/salarie/Relation";
import AboSalarie from "./Pages/AboSalarie";
import Inscription from "./Pages/restaurateur/Inscription";
import FournisseurConnexion from "./Pages/FournisseurConnexion";
import AcheteurInscription from "./Pages/AcheteurInscription1";
import Support from "./Pages/Support";
import AboAcheteur5 from "./Pages/AboAcheteur5";
import AboAcheteur7 from "./Pages/AboAcheteur7";
import ProspectionHorsQr from "./Pages/ProspectionHorsQr";
import Intro from "./Pages/restaurateur/Intro";
import EcoResponsable from "./Pages/EcoResponsable";
import Meteo from "./Pages/Meteo";
import InscriptionSelectType from "./Pages/InscriptionSelectType";
import InscriptionCollaborateurs from "./Pages/InscriptionCollaborateurs";
import ConditionsGenerales from "./Pages/ConditionsGenerales";
import Politique from "./Pages/Politique";
import MentionsL from "./Pages/MentionsL";
// import Historique from "./Pages/Historique";
import FourProspect from "./Pages/salarie/ProspectionsQR";
import ProspectionPoing from "./Pages/salarie/ProspectionPoing";
import Historique from "./Pages/restaurateur/Historique";
import Qr from "./Pages/restaurateur/Qr";
import AcheteurInscriptionProduit from "./Pages/AcheteurInscriptionProduit";
import Abonnement from "./Pages/restaurateur/Abonnement";
import SupportRendezVous from "./Pages/restaurateur/SupportRendezVous";
import Notation from "./Pages/restaurateur/Notation";
import Offre from "./Pages/restaurateur/Offre";
import AcheteurProspectQrDetails1 from "./Pages/AcheteurProspectQrDetails1";
import FourProspectQrDetails from "./Pages/FourProspectQrDetails";
import CoursMarche from "./Pages/restaurateur/CoursMarche";
import ConnexionSelectType from "./Pages/ConnexionSelectType.js";
import FournisseurSelectType from "./Pages/salarie/FournisseurSelectType";
import DevisReview from "./Pages/restaurateur/DevisReview";
import DevisRefuse from "./Pages/DevisRefuse";
import InscriptionCollaborateurs1 from "./Pages/InscriptionCollaborateurs1";
import Geo from "./Pages/Geo";
import DashboardSalarie from "./Pages/salarie/DashboardSalarie";
import ActiviteAjout from "./Pages/siege/ActiviteAjout";
import DashboardRestaurateur from "./Pages/restaurateur/DashboardRestaurateur";
import DashboardSiege from "./Pages/siege/DashboardSiege";
import AjouterRendezVous from "./Pages/AjouterRendezVous";
import MyTeam from "./Pages/siege/MyTeam";
import Messagerie from "./Pages/siege/Messagerie";
import Poing from "./Pages/Poing";
import Produits from "./Pages/Produits";
import ProduitsEdit from "./Pages/ProduitsEdit";
import RestaurateurOpen from "./Pages/RestaurateurOpen";
import TableDevis from "./Pages/TableDevis";
import Propositions from "./Pages/restaurateur/Propositions";
import RendezVous from "./Pages/restaurateur/RendezVous";
import JourJ from "./Pages/JourJ";
import { useFirebase } from "./hooks/useFirebase";
import Connexion from "./Pages/Connexion";
import Calendrier from "./Pages/Calendrier";
import HistoriqueAll from "./Pages/Historique";
import AppelOffre from "./Pages/AppelOffre";
import ProspectionsQr from "./Pages/salarie/ProspectionsQR";
import ProduitsSelect from "./Pages/salarie/ProduitsSelect";
import ZoneHorsQr from "./Pages/ZoneHorsQr";
import { SnackbarProvider } from "notistack";
import RendezVousReview from "./Pages/restaurateur/RendezVousReview";
import Statistique from "./Pages/Statistique";
import DevisValide from "./Pages/DevisValide";
import InscriptionSalarie from "./Pages/salarie/InscriptionSalarie";
import RelationRestaurateur from "./Pages/restaurateur/RelationRestaurateur";
import AboSiege from "./Pages/siege/AboSiege";
import RelationSiege from "./Pages/siege/RelationSiege";
import RedirectionAbonnement from "./Pages/RedirectionAbonnement";

const useStyles = makeStyles()((theme) => ({
  app: {
    textAlign: "center",
    backgroundColor: "blue",
  },
}));

const restaurateurRoutes = [
  {
    path: "/dashboard",
    element: <DashboardRestaurateur />,
  },
  {
    path: "/cours",
    element: <CoursMarche />,
  },
  {
    path: "/support",
    element: <Support />,
  },
  {
    path: "/produits",
    element: <Produits />,
  },
  {
    path: "/historique",
    element: <Historique />,
  },
  {
    path: "/meteo",
    element: <Meteo />,
  },
  {
    path: "/ecoresponsable",
    element: <EcoResponsable />,
  },
  {
    path: "/offre/:offerId",
    element: <Offre />,
  },
  {
    path: "/propositions",
    element: <Propositions />,
  },
  {
    path: "/rendezvous/:offerId",
    element: <RendezVous />,
  },
  {
    path: "/produits/modification",
    element: <ProduitsEdit />,
  },
  {
    path: "/jourj",
    element: <JourJ />,
  },
  {
    path: "/supportrendezvous/:rendezvousId",
    element: <SupportRendezVous />,
  },
  {
    path: "/notation",
    element: <Notation />,
  },
  {
    path: "/devis/:devisId",
    element: <DevisReview />,
  },
  {
    path: "/monrendezvous/:rvId",
    element: <RendezVousReview />,
  },
  {
    path: "/inscription/restaurateur/qr",
    element: <Qr />,
  },
  {
    path: "/inscription/restaurateur/produits",
    element: <Produits />,
  },
  {
    path: "/inscription/restaurateur/produits/modification",
    element: <ProduitsEdit />,
  },
  {
    path: "/inscription/restaurateur/abonnement",
    element: <Abonnement />,
  },
  {
    path: "/inscription/restaurateur/intro",
    element: <RestaurateurOpen />,
  },
  {
    path: "/calendrier",
    element: <Calendrier />,
  },
];

const fournisseurSalarieRoutes = [
  {
    path: "/dashboard",
    element: <DashboardSalarie />,
  },
  {
    path: "/meteo",
    element: <Meteo />,
  },
  {
    path: "/ecoresponsable",
    element: <EcoResponsable />,
  },
  {
    path: "/historique",
    element: <HistoriqueAll />,
  },
  {
    path: "/prospections",
    element: <ProspectionHorsQr />,
  },
  {
    path: "/coupdepoing",
    element: <Poing />,
  },
  {
    path: "/support",
    element: <Support />,
  },
  {
    path: "/produits",
    element: <ProduitsSelect />,
  },
  {
    path: "/jourj",
    element: <JourJ />,
  },
  {
    path: "/produits/modification",
    element: <ProduitsEdit />,
  },
  {
    path: "/tabledevis",
    element: <TableDevis />,
  },
  {
    path: "/devisvalide/:devisId",
    element: <TableDevis />,
  },
  {
    path: "/devisrefuse",
    element: <TableDevis />,
  },
  {
    path: "/geo",
    element: <Geo />,
  },
  {
    path: "/appeloffre/:devisId",
    element: <AppelOffre />,
  },
  {
    path: "/prospectionsqr",
    element: <ProspectionsQr />,
  },
  {
    path: "/prospectionspoing",
    element: <ProspectionPoing />,
  },
  {
    path: "/zonehorsqr",
    element: <ZoneHorsQr />,
  },
  {
    path: "/appeloffre",
    element: <AppelOffre />,
  },
  {
    path: "/calendrier",
    element: <Calendrier />,
  },
  {
    path: "/fourniseur/salarie/intro",
    element: <Intro />,
  },
  {
    path: "/fourniseur/paiement-abonnement",
    element: <RedirectionAbonnement />,
  },
];

const fournisseurSiegeRoutes = [
  {
    path: "/dashboard",
    element: <DashboardSiege />,
  },
  {
    path: "/meteo",
    element: <Meteo />,
  },
  {
    path: "/ecoresponsable",
    element: <EcoResponsable />,
  },
  {
    path: "/messagerie",
    element: <Messagerie />,
  },
  {
    path: "/poing",
    element: <Poing />,
  },
  {
    path: "/geo",
    element: <Geo />,
  },
  {
    path: "/equipe",
    element: <MyTeam />,
  },
  {
    path: "/historique",
    element: <HistoriqueAll />,
  },
  {
    path: "/equipe",
    element: <MyTeam />,
  },
  {
    path: "/prospections",
    element: <ProspectionHorsQr />,
  },
  {
    path: "/produits",
    element: <ActiviteAjout />,
  },
  {
    path: "/support",
    element: <Support />,
  },
  {
    path: "/jourj",
    element: <JourJ />,
  },
  {
    path: "/tabledevis",
    element: <TableDevis />,
  },
  {
    path: "/appeloffre",
    element: <AppelOffre />,
  },
  {
    path: "/prospectionsqr",
    element: <ProspectionsQr />,
  },
  {
    path: "/prospectionspoing",
    element: <ProspectionPoing />,
  },
  {
    path: "/zonehorsqr",
    element: <ZoneHorsQr />,
  },
  {
    path: "/calendrier",
    element: <Calendrier />,
  },
  {
    path: "/statistique",
    element: <Statistique />,
  },
  {
    path: "/devisvalide",
    element: <TableDevis />,
  },
  {
    path: "/devisrefuse",
    element: <TableDevis />,
  },
  {
    path: "/fourniseur/siege/collaborateurs",
    element: <InscriptionCollaborateurs />,
  },
  {
    path: "/fourniseur/siege/activites",
    element: <ActiviteAjout />,
  },
  {
    path: "/fourniseur/siege/intro",
    element: <Intro />,
  },
  {
    path: "/fourniseur/paiement-abonnement",
    element: <RedirectionAbonnement />,
  },
];

function App() {
  const { classes } = useStyles();
  const { user } = useFirebase();
  return (
    <div>
      <Routes>
        {!user && (
          <>
            <Route path="/" element={<Login />} />
            <Route path="/connexion/type" element={<ConnexionSelectType />} />
            <Route path="/connexion" element={<Connexion />} />
            <Route
              path="/inscription/type"
              element={<InscriptionSelectType />}
            />
            <Route
              path="/inscription/restaurateur/relation"
              element={<RelationRestaurateur />}
            />
            <Route path="/inscription/restaurateur" element={<Inscription />} />
            <Route
              path="/connexion/fournisseur/type"
              element={<FournisseurSelectType />}
            />
            <Route
              path="/:inscription/fournisseur/type"
              element={<FournisseurSelectType />}
            />
            <Route
              path="/inscription/fournisseur/:salarie/relation"
              element={<Relation />}
            />
            <Route
              path="/inscription/fournisseur/siege/relation"
              element={<RelationSiege />}
            />
            <Route
              path="/inscription/fournisseur/siege/abonnement"
              element={<AboSiege />}
            />
            <Route
              path="/inscription/fournisseur/:salarie/abonnement"
              element={<AboSalarie />}
            />
            <Route
              path="/inscription/fournisseur/:fourType/:aboType"
              element={<InscriptionSalarie />}
            />
          </>
        )}

        {user?.data()?.type === "Restaurateur" &&
          restaurateurRoutes.map((el) => {
            return <Route path={el.path} element={el.element} />;
          })}
        {user?.data()?.type === "Fournisseur" &&
          fournisseurSalarieRoutes.map((el) => {
            return <Route path={el.path} element={el.element} />;
          })}
        {user?.data()?.type === "Siege" &&
          fournisseurSiegeRoutes.map((el) => {
            return <Route path={el.path} element={el.element} />;
          })}

        {/* mentions légales */}
        <Route path="/politique" element={<Politique />} />
        <Route path="/mentions" element={<MentionsL />} />
        <Route path="/conditionsgenerales" element={<ConditionsGenerales />} />

        {/* 
        <Route path="/dashboardfoursiege" element={<DashboardFourSiege />} />
        <Route path="/restaurateurconnexion" element={<Connexion />} />
        <Route path="/myteam" element={<MyTeam />} />
        <Route path="/register1" element={<ConnexionSelectType />} />
        <Route path="/register2" element={<RegisterPage2 />} />
        <Route path="/restaurateurrdv" element={<RestaurateurRdv />} />
        <Route
          path="/fournisseurinscription"
          element={<FournisseurInscription />}
        />
        <Route
          path="/fournisseurconnexion"
          element={<FournisseurConnexion />}
        />
        <Route path="/acheteurinscription1" element={<AcheteurInscription />} />
        <Route path="/rdvcalendar" element={<RdvCalendar />} />
        <Route path="/restaurateuropen" element={<RestaurateurOpen />} />
        <Route
          path="/restaurateurlastprop"
          element={<RestaurateurLastProp />}
        />
        <Route path="/acheteurprofil" element={<AcheteurProfil />} />
        <Route path="/acheteurprofil1" element={<AcheteurProfil1 />} />
        <Route path="/acheteurmeteo1" element={<AcheteurMeteo1 />} />
        <Route path="/activiteajout" element={<ActiviteAjout />} />
        <Route path="/aboSalarie" element={<AboSalarie />} />
        <Route path="/aboacheteur" element={<AboAcheteur />} />
        <Route path="/aboacheteur2" element={<AboAcheteur2 />} />
        <Route path="/coursmarche" element={<CoursMarche />} />
        <Route path="/devisvalide" element={<DevisValide />} />
        <Route path="/devisrefuse" element={<DevisRefuse />} />
        
        <Route path="/geoviaop" element={<GeoViaOp />} />
        <Route
          path="/acheteurprospectqrdetails"
          element={<AcheteurProspectQrDetails />}
        />
        <Route
          path="/acheteurprospectqrdetails1"
          element={<AcheteurProspectQrDetails1 />}
        />
        <Route
          path="/fourprospectqrdetails"
          element={<FourProspectQrDetails />}
        />
        <Route
          path="/acheteurinscriptionproduit5"
          element={<AcheteurInscriptionProduit5 />}
        />
        <Route
          path="/acheteurinscriptionqr"
          element={<AcheteurInscriptionQr />}
        />
        <Route path="/aboacheteur5" element={<AboAcheteur5 />} />
        <Route path="/aboacheteur7" element={<AboAcheteur7 />} />
        <Route path="/fournisseur5" element={<Fournisseur5 />} />
        <Route path="/fournisseur3" element={<Fournisseur3 />} />
        <Route path="/fourprospect" element={<FourProspect />} />
        <Route path="/fourprospectpoing" element={<FourProspectPoing />} />
        <Route path="/fourprospecttimer" element={<FourProspectTimer />} />
        <Route
          path="/acheteurinscriptionproduit"
          element={<AcheteurInscriptionProduit />}
        />
        <Route path="/historique" element={<Historique />} />
        <Route
          path="/inscriptioncollaborateurs"
          element={<InscriptionCollaborateurs />}
        />
        <Route
          path="/inscriptioncollaborateurs1"
          element={<InscriptionCollaborateurs1 />}
        />
        
        <Route
          path="/dashboardsalarieindep1"
          element={<DashboardSalarieIndep1 />}
        />
        <Route path="/ajouterrendezvous" element={<AjouterRendezVous />} />
        <Route
          path="/restaurateurproduits"
          element={<RestaurateurProduits />}
        />
        <Route
          path="/restaurateurproduitsedit"
          element={<RestaurateurProduitsEdit />}
        />
        <Route path="/messagerie" element={<Messagerie />} /> */}
      </Routes>
    </div>
  );
}

export default App;
