import React from "react";

const TextMentions = () => {
  return (
    <p>
      Ce service (ci-après « App ») est fourni par PROSPECTION COMMERCIALE
      FRANCE, dont le siège social est situé au 180, rue des fins de fessy 74800
      Arenthon, FRANCE, adresse e-mail : @prospectioncommerciale.fr (ci-après «
      PROSPECTION COMMERCIALE », « nous » ou « nos ») comme le responsable du
      traitement au sens de la législation applicable en matière de protection
      des données conformément à la loi générale sur la protection des données
      (ci-après RGPD). L'utilisation des services proposés par PROSPECTION
      COMMERCIALE nécessite que les utilisateurs s'enregistrent dans
      l'application ou via l'interface Web et acceptent nos conditions générales
      et politique de confidentialité, également disponibles sur le lien suivant
      : Conditions Générales / Politique de confidentialité. L'Application
      Prospection commerciale offre à ses utilisateurs restaurateurs l'accès à
      des services tels que des devis, des cours du marché et être géocaliser
      ceux-ci permettant aux commerciaux du secteur de la gastronomie et de
      l’alimentaire (ci-après les « Gastronomes ») de communiquer directement
      avec les restaurateurs (ci-après les « Fournisseurs ») et de prendre
      rendez-vous. L'Application Prospection commerciale offre à ses
      utilisateurs fournisseurs l'accès à des services tels que des appels
      d'offres, la geocalisation de ses prospections directes et indirectes, et
      de prise de rendez-vous, qui sont directement reçus par les fournisseurs,
      toutes les informations pour les deux utilisateurs sont regroupés sous
      forme numérique sans délai. Pour des informations plus détaillées sur les
      services proposés par Prospection commerciale, veuillez vous référer à la
      section 4 de nos Conditions Générales. Lors de l'utilisation de
      l'application, nous traitons des données personnelles vous concernant. Les
      données personnelles désignent toute information relative à une personne
      physique identifiée ou identifiable (personne concernée). La protection de
      votre vie privée lors de l'utilisation de l'application est importante
      pour nous, c'est pourquoi nous souhaitons vous informer en particulier sur
      l'étendue, la base juridique, les droits des personnes concernées et les
      données personnelles que nous traitons lorsque vous utilisez
      l'application. Vous pouvez accéder à cette politique de confidentialité à
      tout moment dans les conditions générales. <br></br>
      <br></br> 1. INFORMATIONS SUR LE TRAITEMENT DE VOS DONNÉES Certaines
      informations sont déjà traitées automatiquement dès que vous utilisez
      l'application. Nous avons répertorié ci-dessous exactement quelles données
      personnelles sont traitées pour vous : <br></br>
      <br></br>1.1 Informations collectées lors du téléchargement Lors du
      téléchargement de l'application, Prospection commerciale collecte en
      données personnelles uniquement au Restaurateur, en pleine accord et
      immédiat des leurs inscriptions, l’utilisons de leurs listing des produits
      courants, les poids et les prix, cela permet d'alimenter et enrichir
      l'Application Prospection commerciale, en Appel d'offre, en cours du
      marchés et en prise de rendez-vous pour les fournisseur. Les informations
      collectées pour les fournisseurs, sont uniquement leur identité à fin de
      les transmettre à un restaurateur après validation des restaurateurs de
      leurs appels d’offres en devis. Néanmoins, nous souhaitons vous informer
      que certaines informations requises peuvent être demandées par l'App Store
      que vous avez sélectionné (par exemple: Google Play ou Apple App Store).
      Le traitement de ces données a lieu exclusivement par l'App Store
      respectif, est hors de notre contrôle, et nous déclinons toute
      responsabilité pour les dommages qui vous sont causés résultant du
      traitement des données effectué uniquement par l'App Store que vous avez
      sélectionné pour le téléchargement du App. 1.2 Informations collectées
      automatiquement Dans le cadre de votre utilisation de l'Application, nous
      collectons automatiquement certaines données nécessaires à l'utilisation
      de l'Application. Ceux-ci incluent : les informations de votre appareil,
      la version de votre système d'exploitation, le type d'appareil que vous
      utilisez, l'heure d'accès et l'adresse IP. <br></br>
      <br></br>Ces données nous sont automatiquement transmises et stockées sur
      nos serveurs, (1) pour vous fournir le Service et les fonctionnalités
      associées ; (2) pour améliorer les fonctions et les performances de
      l'application et (3) pour prévenir et supprimer les abus et les
      dysfonctionnements. Ce traitement de données est basé sur le fait que le
      traitement est nécessaire à l'exécution des services prévus dans les
      conditions générales entre vous en tant que personne concernée et nous
      conformément à l'art. 6 paragraphe 1 phrase 1 lit. b RGPD [concernant 1.2
      (1)]. Nous avons également un intérêt légitime à garantir la
      fonctionnalité et le fonctionnement sans erreur de l'application et à
      pouvoir offrir un service en ligne avec le marché et les intérêts, qui
      l'emportent ici sur vos droits et intérêts dans la protection de vos
      données personnelles au sens d'art. 6 paragraphe 1 phrase 1 lit. f RGPD
      [concernant 1.2 (2) et (3)]. <br></br>
      <br></br>1.3 Création d'un compte utilisateur (inscription) et connexion
      Pour la création d'un compte utilisateur, vous devez remplir un formulaire
      d'inscription dans l'application et accepter les conditions générales et
      la politique de l’entreprise, à cette fin, les informations marquées comme
      obligatoires dans le formulaire d'inscription doivent être renseignées.
      Pour votre compte utilisateur ou connexion dans, nous utilisons votre
      numéro de téléphone ou e-mail pour vous permettre d'accéder à votre compte
      utilisateur et de le gérer ("Informations obligatoires"). Les informations
      obligatoires (nom d'utilisateur, numéro de téléphone, raison sociale et
      adresse professionnelle) sont nécessaires pour remplir le formulaire
      d'inscription dans l'application, ainsi que l'acceptation des conditions
      générales et politiques de confidentialités. Si vous ne fournissez pas ces
      données, vous ne pourrez pas créer de compte utilisateur. <br></br>
      <br></br>Nous utilisons les informations obligatoires pour vous
      authentifier lorsque vous vous connectez. Dans ce cas, sur la page de
      connexion de l'Application, vous pouvez indiquer votre numéro de téléphone
      ou e-mail et de créer un code automatique à quatre chiffres minium (le «
      Code »). Après avoir créer le code, vous devrez rapidement le saisir sur
      la page de connexion de l'application. Nous tenons à vous informer que le
      code est unique et modifiable et a une validité limitée. Veuillez noter
      que vous êtes entièrement responsable de la confidentialité du Code qui
      est strictement personnel et ne doit pas être partagé avec des personnes
      non autorisées. Les données que vous avez saisies lors de votre
      inscription ou de votre connexion seront traitées et utilisées par nous
      (1) pour vérifier votre autorisation à gérer le compte utilisateur ; (2)
      pour faire respecter les conditions d'utilisation de l'Application et tous
      les droits et obligations associés et (3) pour vous contacter afin de
      pouvoir vous envoyer des avis techniques ou juridiques, des mises à jour,
      des messages de sécurité ou d'autres messages concernant l'administration
      de l'Application. compte d'utilisateur. <br></br>
      <br></br>De plus, vous pouvez fournir volontairement les informations
      suivantes lors de votre inscription : adresse e-mail et pseudonyme. De
      plus, vous pouvez fournir les informations volontaires suivantes après
      l'inscription sur l'application :<br></br>
      <br></br>Vous avez la possibilité de saisir les listes des produits que
      vous commandez habituellement auprès de vos Fournisseurs sans les nommés,
      il en va de votre responsabilité civile et pénale, PROSPECTION COMMERCIALE
      ne pourra être tenu responsable via des conditions générales et politiques
      de confidentialité que les tiers ont cocher et donc accepter (ci-après «
      Listes ») <br></br>
      <br></br>Informations financières : Vous avez la possibilité de
      télécharger sur l'Application des informations financières dans le but de
      simplifier la passation de vos commandes. Nous traiterons les informations
      volontaires le réalistement automatique des prix si vous ne pouvez le
      faire vous même sur la base de votre consentement que vous pouvez retirer
      à tout moment avec effet pour l'avenir, à l’inverse cela est fait
      automatiquement toutes les 2 semaines par un algorithme art. 6 paragraphe
      1 phrase 1 lit. un RGPD. Ce traitement de données est justifié par le fait
      que [concernant 1.3 (1)] le traitement est nécessaire à l'exécution du
      contrat entre vous en tant que personne concernée et nous conformément à
      l'art. 6 alinéa 1 phrase lit. b GDPR pour l'utilisation de l'application,
      ou [concernant 1.3 (2) et (3)] nous avons un intérêt légitime à assurer la
      fonctionnalité, le fonctionnement sans erreur et réalisme de
      l'application, qui l'emporte ici sur vos droits et intérêts dans la
      protection de vos données personnelles au sens de l'art. 6 paragraphe 1
      phrase 1 lit. f RGPD. <br></br>
      <br></br>1.4 Utilisation de l'Application Dans le cadre de l'application,
      vous pouvez saisir, gérer et modifier diverses informations, tâches et
      activités. Ces informations comprennent notamment les données relatives
      aux communications que les Gastronomes adressent avec leurs appels
      d'offres aux Fournisseurs qui sont reçues par ces derniers en Qr-codes ou
      en lignes sans Qr-codes regroupées sous forme numérique sans délai.{" "}
      <br></br>
      <br></br>Vous pouvez également activer les fonctions suivantes : –
      Fournisseur : votre geocalisation « interface HORS Qr-code » : Ceci est
      nécessaire pour stocker vos entrées personnelles de restaurateurs sur nos
      serveurs. Veuillez noter que nous ne pouvons accepter aucune
      responsabilité pour le contenu des données écrites sur votre compte
      volontairement sur l'application, par conséquent, vous êtes entièrement
      responsable de toute information supplémentaire partagée sur
      l'application, non requise aux fins du traitement des données
      personnelles, qui enfreint le / les lois applicables et/ou les droits de
      tiers. <br></br>
      <br></br>Le traitement des données susmentionnées est basé sur votre
      consentement que vous pouvez retirer à tout moment avec effet pour
      l'avenir, Art. 6 paragraphe 1 phrase 1 lit. un RGPD. <br></br>
      <br></br>2. DIVULGATION ET TRANSFERT DE DONNÉES Nous transférons vos
      données personnelles aux destinataires à condition qu'une base légale
      existe et/ou que vous ayez donné votre consentement au traitement des
      données. De plus, nous traitons vos données personnelles à des
      prestataires de services en tant que nos sous-traitants. Nous transférons
      vos données aux destinataires suivants :<br></br>
      <br></br>2.1 Les données que vous avez fournies lors de votre inscription
      seront transmises au sein du groupe PROSPECTION COMMERCIALE à des fins
      administratives internes, y compris le support client commun, dans la
      mesure nécessaire. Veuillez noter que le Groupe PROSPECTION COMMERCIALE
      désigne le groupe mondial de sociétés PROSPECTION COMMERCIALE dont
      PROSPECTION COMMERCIALE est la société mère dont le siège social est situé
      au 180, route des fins de fessy / 74800 Arenthon, FRANCE. Le groupe
      PROSPECTION COMMERCIALE est voué dans l’avenir à être composer d'autres
      sociétés détenues ou contrôlées par Prospection commerciale, présentes
      dans différents États membres de l'Union européenne et aux États-Unis
      d'Amérique, et d'autres sociétés détenues par ou sous propriété commune de
      PROSPECTION COMMERCIALE, qui comprend également nos filiales
      (c'est-à-dire, toute organisation que nous possédons ou contrôlons), en
      particulier lorsque nous collaborons à la fourniture de l'Application.{" "}
      <br></br>
      <br></br>2.2 Nous pouvons partager vos données personnelles avec nos
      partenaires commerciaux, tels que des gastronomes ou des fournisseurs et
      des partenaires de livraison, ainsi qu'avec des tiers avec lesquels nous
      nous associons pour proposer des concours, des activités promotionnelles
      conjointes ou des services comarqués, et une telle divulgation est
      nécessaire pour remplir demandes ou candidatures. <br></br>
      <br></br>2.3 Veuillez noter que si vous utilisez l'application dans le
      cadre de votre rôle d'employé ou de sous-traitant d'une entreprise ou
      d'une autre entité juridique, nous pouvons partager vos informations avec
      cette entité. <br></br>
      <br></br>2.4 Nous partageons vos données personnelles avec des tiers qui
      nous aident à fournir nos services à la clientèle et à faciliter nos
      communications avec les personnes qui soumettent des demandes. Nous
      engageons des prestataires de services tiers qui fournissent des services
      commerciaux ou opérationnels pour nous ou en notre nom, tels que
      l'hébergement de sites Web, la fourniture d'infrastructures, les services
      informatiques, les services d'analyse, les services administratifs.{" "}
      <br></br>
      <br></br>2.5 Nous pouvons divulguer vos données personnelles uniquement à
      des tiers, décrits à l'article 4 de la présente politique de
      confidentialité, qui utilisent ces informations pour fournir des
      informations d’appel d’offre / des appels offres et/ou devis dans le but
      d'obtenir un rendez-vous sur les produits ou services d'intérêt,
      conformément à leurs propres politiques et conditions de confidentialité.
      Veuillez noter que Prospection commerciale a conclu des accords de
      traitement de données, ou des co-contrôleurs (selon le cas) et des clauses
      contractuelles types avec des prestataires de services, qui agissent en
      tant que sous-traitants, ou co-contrôleurs, en ce qui concerne le
      traitement de vos données personnelles, selon le cas. <br></br>
      <br></br>2.6 S'il est nécessaire de clarifier une utilisation illégale ou
      abusive de l'application ou de poursuites judiciaires, les données
      personnelles seront transmises aux autorités chargées de l'application de
      la loi ou à d'autres autorités et, si nécessaire, à des tiers lésés ou à
      des conseillers juridiques. Cependant, cela ne se produit que s'il existe
      des indications de comportement illégal ou abusif. Un transfert peut
      également avoir lieu si cela sert l'application des conditions
      d'utilisation ou d'autres réclamations légales. Nous sommes également
      légalement tenus de fournir des informations à certains organismes publics
      sur demande. Il s'agit des autorités répressives, des autorités chargées
      de poursuivre les infractions administratives sanctionnées par une amende
      et des autorités fiscales. Toute divulgation des données personnelles est
      justifiée par le fait que (1) le traitement est nécessaire pour remplir
      une obligation légale à laquelle nous sommes soumis conformément à l'art.
      6 paragraphe 1 phrase 1 lit. c GDPR dans les exigences légales nationales
      pour la divulgation de données aux autorités répressives ou (2) nous avons
      un intérêt légitime à utiliser les données en présence de preuves d'un
      comportement abusif ou pour faire respecter nos conditions d'utilisation,
      d'autres conditions ou les réclamations envers les tiers susmentionnés et
      vos droits et intérêts dans la protection de vos données personnelles au
      sens de l'art. 6 paragraphe 1 phrase 1 lit. f RGPD ne prévalent pas.{" "}
      <br></br>
      <br></br>2.7. Dans le cadre du développement ultérieur de nos activités,
      la structure de notre société peut changer en changeant la forme
      juridique, les filiales, les parties de sociétés ou les composants en
      cours de création, d'achat ou de vente. Dans de telles transactions, les
      informations client peuvent être partagées avec la partie de l'entreprise
      à transférer. Chaque fois que des données personnelles sont transmises à
      des tiers dans la mesure décrite ci-dessus, nous veillerons à ce que cela
      soit fait conformément à la présente déclaration de protection des données
      et à la loi applicable en matière de protection des données. Toute
      divulgation de données personnelles est justifiée par le fait que nous
      avons un intérêt légitime à adapter si nécessaire notre forme sociale aux
      circonstances économiques et juridiques et vos droits et intérêts à la
      protection de vos données personnelles au sens de l'art. 6 paragraphe 1
      phrase 1 lit. f RGPD ne prévalent pas. <br></br>
      <br></br>3. TRANSFERT DE DONNÉES VERS DES PAYS TIERS Nous souhaitons vous
      informer que le bouclier de protection des données UE-États-Unis (décision
      d'adéquation) a été déclaré invalide et qu'en cas de transfert de données
      vers des pays tiers non sécurisés (ici : les États-Unis), il n'existe pas
      de niveau de protection des données adéquat selon les normes de l'UE. En
      particulier, il existe un risque que vos données soient traitées par les
      autorités américaines, à des fins de contrôle et de surveillance,
      éventuellement également sans aucun recours légal. En cas de traitement de
      données dans le cadre de l'utilisation d'outils d'analyse, le traitement
      des données est basé sur votre consentement que vous pouvez révoquer à
      tout moment avec effet pour l'avenir, Art. 49 alinéa 1 lit. un RGPD. En
      outre, nous avons conclu avec des prestataires de services basés dans des
      pays tiers soit un accord de traitement de données conformément à l'art.
      28 RGPD ou une co-responsabilité selon l'art. 26 RGPD ainsi que des
      clauses contractuelles types, selon la constellation contractuelle.{" "}
      <br></br>
      <br></br>4. OUTILS D'ANALYSE ET DE MARKETING <br></br>
      <br></br>4.1 Outils d'analyse Afin d'améliorer notre Application, nous
      utilisons des outils pour l'enregistrement statistique et l'analyse du
      comportement d'utilisation général sur la base des données d'accès
      ("outils d'analyse"), et d'autres tâches opérationnelles visant à assurer
      la fonctionnalité de l'Application ("autres outils" ). Nous utilisons
      également des services d'analyse pour évaluer l'utilisation de nos
      différents canaux de commercialisation. Sauf indication contraire, la base
      juridique des outils d'analyse et autres outils est votre consentement
      conformément à l'art. 6 par. 1 phrase 1 lit. un RGPD. Dans le cas où des
      données personnelles sont transférées aux États-Unis ou dans d'autres pays
      tiers, votre consentement s'étend expressément au transfert de données
      (art. 49 alinéa 1 phrase 1 lit.a RGPD). Les risques associés peuvent être
      trouvés dans la section 3 ("Transfert de données vers des pays tiers").{" "}
      <br></br>
      <br></br>4.2 Outils de commercialisation Nous utilisons également des
      outils à des fins publicitaires ("outils marketing") et d'autres tâches
      administratives/opérationnelles ("outils supplémentaires"). En analysant
      et en évaluant les données d'accès lors de l'utilisation de notre site
      Web, nous sommes en mesure de vous présenter des publicités
      personnalisées, c'est-à-dire celles qui répondent à vos intérêts et
      besoins réels sur notre site Web et sur les sites Web d'autres
      fournisseurs. La base juridique des outils de marketing et des outils
      supplémentaires est votre consentement conformément à l'art. 6 par. 1
      phrase 1 lit. un RGPD. Dans le cas où des données personnelles sont
      transférées aux États-Unis ou dans d'autres pays tiers, votre consentement
      s'étend expressément au transfert de données (art. 49 al. 1 phrase 1 lit.
      a RGPD). Les risques associés peuvent être trouvés dans la section 3
      (Transfert de données vers des pays tiers). <br></br>
      <br></br>5. Durée de stockage Nous supprimons vos données personnelles dès
      que les finalités pour lesquelles nous les avons collectées ou traitées,
      conformément aux paragraphes précédents, sont atteintes, et aucune
      exigence légale ne nous oblige à continuer à les conserver plus longtemps.
      Nous supprimons vos données personnelles dès que vous nous soumettez votre
      demande de suppression de votre compte utilisateur, aux fins pour
      lesquelles nous les avons collectées ou utilisées conformément aux
      paragraphes précédents. Veuillez noter qu'en cas d'annulation de votre
      compte utilisateur, vos données personnelles stockées dans l'application
      seront supprimées. <br></br>
      <br></br>6. Vos droits en tant que personne concernée En tant que
      personnes concernées, vous disposez des droits suivants conformément à
      l'art. 15 – 21, 77 RGPD : <br></br>
      <br></br>– Droit d'accès de la personne concernée (art. 15 RGPD) – Droit
      de rectification (art. 16 RGPD) – Droit à l'effacement (« droit à l'oubli,
      art. 17 RGPD) – Droit à la limitation du traitement (art. 18 RGPD) – Droit
      à la portabilité des données (Art. 20 RGPD) – Droit d'opposition (Art. 21
      RGPD) – Droit d'introduire une réclamation auprès d'une autorité de
      contrôle (Art. 77 RGPD). <br></br>
      <br></br>Si le traitement des données est basé sur votre consentement,
      vous avez le droit de retirer votre consentement à tout moment. Le retrait
      du consentement n'affecte pas la licéité du traitement fondé sur le
      consentement avant son retrait. <br></br>
      <br></br>Dans le cas où le traitement des données est basé sur notre
      intérêt légitime (art. 6 alinéa 1 phrase 1 lit. f RGPD), vous avez le
      droit de vous opposer à tout moment, pour des motifs liés à votre
      situation. <br></br>
      <br></br>Vos demandes de revendication de droits en matière de protection
      des données et nos réponses à celles-ci seront conservées à des fins de
      documentation pendant une période pouvant aller jusqu'à trois ans et, dans
      des cas individuels, pour la constatation, l'exercice ou la défense de
      droits en justice même au-delà. La base juridique est l'art. 6 paragraphe
      1 phrase 1 lit. f RGPD, sur la base de notre intérêt légitime à nous
      défendre contre toute réclamation de droit civil conformément à l'art. 82
      RGPD, l'évitement des amendes conformément à l'art. 83 RGPD et
      l'accomplissement de notre responsabilité en vertu de l'art. 5 al. 2 RGPD.{" "}
      <br></br>
      <br></br>En tant que personne concernée, vous avez le droit d'introduire
      une réclamation auprès d'une autorité de contrôle, notamment dans l'État
      membre de votre résidence habituelle, de votre lieu de travail ou du lieu
      de l'infraction alléguée si vous considérez que le traitement des données
      à caractère personnel vous concernant enfreint les réglementations GDPR{" "}
      <br></br>
      <br></br>7. Délégué à la protection des données et coordonnées : Si vous
      avez des questions ou des commentaires sur notre traitement de vos données
      personnelles, ou si vous souhaitez exercer vos droits de personne
      concernée, veuillez contacter l'équipe juridique de Prospection
      commerciale en utilisant les coordonnées suivantes :
      …@prospectioncommerciale.fr <br></br>
      <br></br>En outre, vous pouvez adresser vos préoccupations en matière de
      protection des données à notre délégué à la protection des données en
      envoyant un e-mail à l'adresse e-mail mentionnée ci-dessus. Veuillez noter
      que notre délégué à la protection des données ne sera pas le seul à
      recevoir votre demande. Si vous souhaitez contacter uniquement notre
      délégué à la protection des données et/ou si vous souhaitez envoyer des
      informations confidentielles, veuillez faire référence dans votre e-mail
      au délégué à la protection des données et demander à vous contacter.{" "}
      <br></br>
      <br></br>8. Modifications de cette politique de confidentialité Nous
      gardons toujours cette politique de confidentialité à jour. Par
      conséquent, nous nous réservons le droit de les modifier de temps à autre
      et de maintenir les changements dans la collecte, le traitement ou
      l'utilisation de vos données. La version actuelle de la déclaration de
      protection des données est toujours disponible sous l'entrée « Paramètres
      » dans l'application.
    </p>
  );
};

export default TextMentions;
