import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { collection, doc, getFirestore, onSnapshot } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { useContext } from "react";
import { createContext } from "react";
import { useEffect } from "react";
import { useState } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyD3PFIpCNUCHanrus6QgjQeGw91kpbpu7s",

  authDomain: "prospection-commerciale-10ace.firebaseapp.com",

  projectId: "prospection-commerciale-10ace",

  storageBucket: "prospection-commerciale-10ace.appspot.com",

  messagingSenderId: "556341352320",

  appId: "1:556341352320:web:b582b25e1e1dec297e1365",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export const FirebaseContext = createContext();

export const useFirebase = () => useContext(FirebaseContext);
export const FirebaseContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const [unsubscribe, setUnsubscribe] = useState(null);

  useEffect(() => {
    const authUnsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("Auth state changed to " + user.email);
        const userRef = doc(collection(db, "users"), user.email);
        const _unsub = onSnapshot(userRef, (snap) => {
          setUser(snap);
          console.log(snap.data());
        });
        setUnsubscribe(() => _unsub);
      } else {
        console.log("Auth state changed to disconnected");
        setUser(null);
      }
    });

    return authUnsubscribe;
  }, []);

  const logout = async () => {
    unsubscribe();
    await signOut(auth);
  };

  return (
    <FirebaseContext.Provider value={{ auth, db, user, logout, storage }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default useFirebase;
