import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {withTheme} from "@emotion/react";
import {useNavigate} from "react-router-dom";

const InputLogin = ({text, changeStyle, redirect, onClick}) => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div
      className={style.container}
      style={changeStyle}
      onClick={onClick ? onClick : () => navigate(redirect)}
    >
      {text}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "poppins-regular",
    textAlign: "center",
    fontSize: 20,
    color: "white",
    width: "100%",
    height: 85,
    backgroundColor: "#388ADC",
    border: "2px solid #388ADC",
    borderRadius: 10,
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
    cursor: "pointer",
  },
});

export default InputLogin;
