import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLogin from "../../Components/InputLogin";
import { useNavigate } from "react-router-dom";

const Qr = () => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <div className={style.leftContainer}>
        <div
          className={style.nextButton}
          onClick={() => navigate("/inscription/restaurateur/produits")}
        >
          <InputLogin
            text={"Suivant"}
            changeStyle={{
              width: 300,
            }}
          />
        </div>
        <div className={style.leftContainerCenter}>
          <p className={style.leftContainerCenterText}>
            <span style={{ fontSize: 30 }}>Votre QR-code</span>
            <br></br>
            <br></br>
            Recevez votre QR code et/ou télécharger une imitation parfaite de la
            vignette QR-code provisoire à imprimer.<br></br>
            <br></br> Vous pourrez la placer sur votre devanture, afin de n'être
            plus dérangé par des fournisseurs mais être solicité différemment.
          </p>
          <div className={style.buttonContainer}>
            <InputLogin
              text={"Recevoir le QR code par la poste"}
              changeStyle={{
                width: "47%",
              }}
            />
            <InputLogin
              text={"Télécharger le QR-code provisoire"}
              changeStyle={{
                width: "47%",
              }}
            />
          </div>
        </div>
      </div>
      <div className={style.rightContainer}>
        <p className={style.rightContainerText}>Visuel de la vignette</p>
        <img src="../../images/prospectQr.png" style={{ height: "90%" }} />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    height: "100vh",
    fontFamily: "poppins-regular",
    fontSize: 20,
  },
  nextButton: {
    position: "absolute",
    bottom: 30,
    right: 30,
    "&:hover": {
      cursor: "pointer",
    },
  },
  leftContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    position: "relative",
  },
  leftContainerCenter: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    width: "80%",
    transform: "translateY(-20%)",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  rightContainer: {
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  rightContainerText: {
    fontFamily: "poppins-italic",
    fontSize: 20,
    color: "#707070",
  },
});

export default Qr;
