import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../Components/Card";
import Home from "../../Components/Home";
import InputLogin from "../../Components/InputLogin";
import { useNavigate } from "react-router-dom";

const MyTeam = () => {
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [showInputs, setShowInputs] = useState(false);
  const [showStats, setShowStats] = useState(false);
  const [userSelected, setUserSelected] = useState("hey");
  const [users, setUsers] = useState([]);
  const style = useStyles();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!name || !id || !password) {
      window.alert("Tous les champs sont requis");
      return;
    }

    setUsers((prev) => [
      ...prev,
      {
        name,
        id,
        password,
      },
    ]);
  };

  return (
    <div className={style.container}>
      <Home />
      <p className={style.title}>Mon équipe</p>
      <div className={style.leftContainer}>
        <div className={style.table}>
          <div className={style.tableTop}>
            <p className={style.tableTopName}>PRENOM</p>
            <p className={style.tableTopId}>IDENTIFIANT</p>
            <p className={style.tableTopPassword}>MOT DE PASSE</p>
          </div>
          <div className={style.tableBottom}>
            {users.map((el, index) => {
              return (
                <div
                  className={style.userContainer}
                  style={{
                    backgroundColor:
                      userSelected === el.id ? "rgba(0,0,0,0.07)" : "white",
                  }}
                  onClick={() => {
                    setUserSelected(el.id);
                    setShowInputs(false);
                    setShowStats(true);
                  }}
                >
                  <p className={style.userName}>{el.name}</p>
                  <p className={style.userId}>{el.id}</p>
                  <div
                    className={style.userPasswordContainer}
                    id={"userContainer"}
                  >
                    <div className={style.hideContainer}>
                      <p
                        className={style.hideContainerText}
                        id={`password${index}`}
                        style={{ display: "none" }}
                      >
                        {el.name}
                      </p>
                      <img
                        src="images/passwordHide.png"
                        style={{ width: 113 }}
                        className={style.hideContainerImg}
                        id={`img${index}`}
                      />
                    </div>
                    <img
                      src="images/oeil.png"
                      className={style.passwordImg}
                      onClick={() => {
                        const img = document.querySelector(`#img${index}`);
                        img.style.display =
                          img.style.display === "none" ? "" : "none";
                        const password = document.querySelector(
                          `#password${index}`
                        );
                        password.style.display =
                          password.style.display === "none" ? "" : "none";
                      }}
                    />
                  </div>

                  <img src="images/close-circle.png" className={style.delete} />
                </div>
              );
            })}
            <div className={style.button_container}>
              <img
                src="images/plusButton.png"
                className={style.plusButton}
                onClick={() => {
                  setShowInputs(true);
                  setShowStats(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={style.rightContainer}>
        <div className={style.rightContainerCenter}>
          {showInputs && (
            <div>
              <p style={{ marginTop: 0 }}>Nouveau collaborateur</p>
              <div className={style.add_mate}>
                <div className={style.add_mate_table}>
                  <input
                    type="text"
                    className={style.add_mate_input}
                    placeholder="Nom"
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    type="text"
                    className={style.add_mate_input}
                    placeholder="Identifiant"
                    onChange={(e) => setId(e.target.value)}
                  />
                  <input
                    type="text"
                    className={style.add_mate_input}
                    placeholder="Mot de passe"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className={style.add_mate_button_container}>
                  <button
                    className={style.add_mate_button}
                    onClick={() => handleSubmit()}
                  >
                    Valider
                  </button>
                </div>
              </div>
            </div>
          )}
          {showStats && (
            <div
              className={style.add_mate}
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                padding: 20,
              }}
            >
              <div>
                <p>
                  <strong>{userSelected}</strong>
                </p>
                <p>{userSelected}</p>
              </div>
              <button className={style.stats_button}>STATISTIQUES</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    backgroundSize: "cover",
    fontFamily: "poppins-regular",
    fontSize: 20,
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    maxHeight: "100vh",
    width: "50%",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
    overflow: "auto",
  },
  leftContainerCenter: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
  },
  leftTitle: {
    fontSize: 30,
    margin: "50px 0 80px",
  },
  title: {
    position: "absolute",
    fontSize: 30,
    top: "5%",
    left: 100,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    width: "100%",
    height: 50,
    backgroundColor: "#EEEEEE",
    border: "none",
    borderRadius: 10,
    margin: "10px 0 35px",
    textIndent: 10,
    "&::placeholder": {
      fontFamily: "poppins-regular",
      fontSize: 18,
      color: "##707070",
      marginLeft: 20,
    },
  },
  leftButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  rightContainer: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightContainerCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "70%",
  },
  table: {
    transform: "translate(0, -50%)",
    marginTop: "50vh",
    width: "70%",
    maxHeight: "70%",
    overflow: "auto",
    borderRadius: 25,
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
  },
  tableTop: {
    display: "flex",
    width: "100%",
    height: 100,
    position: "relative",
    borderBottom: "1px solid #E3E3E3",
    fontFamily: "poppins-bold",
    fontSize: 18,
  },
  tableTopPassword: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "70%",
    top: "50%",
    margin: 0,
  },
  tableTopId: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "40%",
    top: "50%",
    margin: 0,
  },
  tableTopName: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "10%",
    top: "50%",
    margin: 0,
  },
  tableBottom: {
    maxHeight: "100%",
  },
  userContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 60,
    position: "relative",
  },
  userPasswordContainer: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "70%",
  },
  hideContainer: {
    position: "relative",
    width: 113,
  },
  hideContainerImg: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  hideContainerText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    margin: 0,
    fontFamily: "poppins-regular",
    fontSize: 18,
  },
  userId: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "40%",
    top: "50%",
    margin: 0,
    fontFamily: "poppins-regular",
    fontSize: 18,
  },
  userName: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "10%",
    top: "50%",
    margin: 0,
    fontFamily: "poppins-bold",
    fontSize: 18,
  },
  passwordImg: {
    width: 33.7,
    height: 27,
    marginLeft: 15,
  },
  delete: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "92%",
    width: 20,
    height: 20,
  },
  nextButtonContainer: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    bottom: 0,
    right: 0,
    width: 332,
    "&:hover": {
      cursor: "pointer",
    },
  },
  plusButton: {
    height: 80,
    width: 80,
    cursor: "pointer",
  },
  button_container: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: 50,
  },
  add_mate: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxHeight: "70%",
    overflow: "auto",
    borderRadius: 15,
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
    height: 146,
  },
  add_mate_table: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: 20,
  },
  add_mate_input: {
    border: "2px solid #388ADC",
    borderRadius: 10,
    height: 27,
    width: "25%",
  },
  add_mate_button_container: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 20,
    paddingBottom: 20,
  },
  add_mate_button: {
    height: 40,
    width: 137,
    fontSize: 18,
    backgroundColor: "#388ADC",
    borderRadius: 50,
    border: "none",
    color: "white",
    cursor: "pointer",
  },
  stats_button: {
    width: 224,
    height: 84,
    fontSize: 18,
    color: "white",
    backgroundColor: "#F6C745",
    borderRadius: 10,
    border: "none",
    cursor: "pointer",
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
  },
});

export default MyTeam;
