import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Card from "../../Components/Card";
import Home from "../../Components/Home";
import {useNavigate} from "react-router-dom";
import {
  collection,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import useFirebase from "../../hooks/useFirebase";
import moment from "moment";
import {InfinitySpin} from "react-loader-spinner";
import Card2 from "../../Components/Card2";

const Propositions = () => {
  const style = useStyles();
  const navigate = useNavigate();
  const {db, user} = useFirebase();
  const [devisOffre, setDevisOffre] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const devisRef = query(
        collection(db, "devis"),
        where("restaurateur", "==", user.ref),
        orderBy("firstDevisAt", "desc")
      );

      const docSnap = await getDocs(devisRef);
      const docs = await Promise.all(
        docSnap.docs
          .filter((el) => el.data().status === "sent")
          .map(async (devis) => {
            const _user = await getDoc(devis.data().fournisseur);
            return {
              data: {
                ...devis.data(),
                ref: devis.ref,
                id: devis.id,
              },
              user: {
                ..._user.data(),
                ref: _user.ref,
                id: _user.id,
              },
            };
          })
      );

      setDevisOffre(docs);
      setLoading(false);
    })();
  }, []);

  return (
    <div className={style.container}>
      <Home />
      <div className={style.leftContainer}>
        <div className={style.topContainer}>
          <p style={{fontSize: 30}}>Offre du jour</p>
        </div>
        <div className={style.historyContainer}>
          <p>Date</p>
          <img src="images/fournisseur5.png" style={{width: 32, height: 32}} />
        </div>
        <div className={style.rdvContainer2}>
          {!loading ? (
            devisOffre
              .filter((el) =>
                moment(el.data.firstDevisAt.toDate()).isSame(new Date(), "day")
              )

              .map((el, index) => {
                return (
                  <div
                    className={style.leftSide}
                    onClick={() => navigate("/offre/" + el.data.id)}
                    key={index}
                  >
                    <Card2
                      besoin={el.data.lines.length}
                      day={moment(el.data.firstDevisAt.toDate()).format(
                        "DD.MM.YYYY"
                      )}
                      end={moment(el.data.firstDevisAt.toDate()).format(
                        "HH:mm"
                      )}
                      changeStyle={{
                        width: "100%",
                      }}
                    />
                  </div>
                );
              })
          ) : (
            <InfinitySpin width="200" color="#388ADC" />
          )}
        </div>
      </div>
      <div className={style.rightContainer}>
        <div className={style.tableTitle}>
          <p>Dernières propositions</p>
        </div>
        <div className={style.table}>
          {!loading ? (
            devisOffre
              .filter(
                (el) =>
                  !moment(el.data.firstDevisAt.toDate()).isSame(
                    new Date(),
                    "day"
                  )
              )
              .map((el, index) => {
                return (
                  <div
                    className={
                      index % 2 == 0 ? style.leftSide : style.rightSide
                    }
                    onClick={() => navigate("/offre/" + el.data.id)}
                    key={index}
                  >
                    <Card2
                      besoin={el.data.lines.length}
                      day={moment(el.data.firstDevisAt.toDate()).format(
                        "DD.MM.YYYY"
                      )}
                      end={moment(el.data.firstDevisAt.toDate()).format(
                        "HH:mm"
                      )}
                      changeStyle={{width: "60%"}}
                    />
                  </div>
                );
              })
          ) : (
            <InfinitySpin width="200" color="#388ADC" />
          )}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    minHeight: "100vh",
    fontFamily: "poppins-regular",
    justifyContent: "space-between",
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "35%",
    boxShadow: "0 3px 20px rgb(0,0,0, 0.16)",
  },
  historyContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F6F6F6",
    width: "65%",
    height: 47,
    padding: 10,
    borderRadius: 5,
  },
  rdvContainer: {
    display: "flex",
    flexDirection: "column",
  },
  rdvContainer2: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    gap: 30,
    marginTop: 30,
  },
  rdv: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 40,
  },
  rdvName: {
    fontFamily: "poppins-semiBold",
    fontSize: 70,
    color: "#26466D",
    margin: 0,
  },
  rdvDate: {
    fontFamily: "poppins-light",
    fontSize: 20,
    margin: 0,
    transform: "translateY(-20px)",
  },
  rightContainer: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: 50,
  },
  table: {
    borderRadius: 15,
    width: "80%",
    padding: "5%",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    display: "flex",
    flexDirection: "column",
    gap: 30,
    minHeight: "50vh",
  },
  tableTitle: {
    alignItems: "center",
    display: "flex",
    alignSelf: "flex-start",
    fontSize: 30,
  },
  logoTitle: {
    width: 35,
    height: 35,
    marginRight: 10,
  },
  leftSide: {
    display: "flex",
    justifyContent: "flex-start",
  },
  rightSide: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

export default Propositions;
