import { doc, getDocFromServer } from "@firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { InfinitySpin } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import Home from "../../Components/Home";
import useFirebase from "../../hooks/useFirebase";

const DevisReview = () => {
  const style = useStyles();
  const { devisId } = useParams();
  const [devis, setDevis] = useState(null);
  const [loading, setLoading] = useState(true);
  const { db, user } = useFirebase();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      const docRef = doc(db, "devis", devisId);
      const docSnap = await getDocFromServer(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setDevis(data);
        setLoading(false);
      } else {
        navigate("/historique");
        enqueueSnackbar("Ce devis n'existe pas", { variant: "error" });
      }
    })();
  }, []);

  return (
    <div className={style.container}>
      <Home />
      <p className={style.title}>
        {!loading && devis.status === "approved"
          ? "Devis validé"
          : !loading && devis.status === "refused"
          ? "Devis refusé"
          : null}
      </p>
      <div className={style.table}>
        <div className={style.tableTop}>
          <p className={style.titlesTop} style={{ left: "5%" }}>
            LIBELLE
          </p>
          <p className={style.titlesTop} style={{ left: "30%" }}>
            POIDS
          </p>
          <p className={style.titlesTop} style={{ left: "55%" }}>
            PRIX DE BASE
          </p>
          <p className={style.titlesTop} style={{ left: "85%" }}>
            PRIX PROPOSE
          </p>
        </div>
        {!loading ? (
          <div className={style.tableBottom}>
            {devis.lines.map((el, index) => {
              return (
                <div className={style.product}>
                  <p
                    className={style.productElement}
                    style={{ left: "5%", fontFamily: "poppins-bold" }}
                  >
                    {el?.title}
                  </p>
                  <p className={style.productElement} style={{ left: "30%" }}>
                    {el?.basedQty + " " + el?.unit}
                  </p>
                  <p className={style.productElement} style={{ left: "55%" }}>
                    {el?.basedPrice} €
                  </p>
                  <p className={style.productElement} style={{ left: "85%" }}>
                    {el?.status === "approved" && el?.canDoBetter
                      ? "Je peux mieux faire"
                      : el?.status === "refused"
                      ? "Produit refusé"
                      : !el?.canDoBetter && el?.price
                      ? el?.price + " €"
                      : "non défini"}
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          <InfinitySpin width="200" color="#388ADC" />
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    height: "100vh",
    fontFamily: "poppins-regular",
  },
  title: {
    position: "absolute",
    left: "10%",
    fontSize: 30,
  },
  table: {
    position: "absolute",
    transform: "translate(-50%, 0)",
    top: "15%",
    left: "50%",
    width: "80%",
    maxHeight: "60%",
    boxShadow: "0 3px 18px rgb(0,0,0, 0.16)",
    borderRadius: 20,
    overflow: "auto",
  },
  tableTop: {
    fontFamily: "poppins-bold",
    fontSize: 18,
    borderBottom: "1px solid #E3E3E3",
    height: 115,
    position: "relative",
  },
  titlesTop: {
    position: "absolute",
    transform: "translateY(-50%)",
    top: "50%",
    margin: 0,
  },
  product: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: 70,
  },
  productElement: {
    position: "absolute",
    transform: "translateY(-50%)",
    margin: 0,
    top: "50%",
  },
  buttonContainer: {
    position: "absolute",
    bottom: "5%",
    left: 0,
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
});

export default DevisReview;
