import { style } from "@mui/system";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../Components/Card";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const PopUp = ({
  type,
  data,
  closePop,
  setClosePop,
  redirect,
  redirectVoir,
}) => {
  const style = useStyles();
  const navigate = useNavigate();
  const handleCardStyleGreen = {
    height: 100,
    backgroundColor: "#9BC070",
    color: "white",
  };
  const handleCardStyleBlue = {
    height: 100,
    backgroundColor: "#388ADC",
    color: "white",
  };

  return (
    <div className={style.container}>
      <img
        src="../../images/closeBlue.png"
        className={style.close}
        onClick={() => setClosePop(!closePop)}
      />
      <div
        className={style.circle}
        onClick={() => navigate(redirectVoir)}
      ></div>
      <div className={style.voir} onClick={() => navigate(redirectVoir)}>
        VOIR<br></br>PLUS
      </div>
      {type == "green" && (
        <div className={style.elContainer}>
          <div className={style.titleContainer}>
            <img src="../../images/blackQR.png" className={style.qr} />
            <p>Prospections QR code</p>
          </div>
          <div className={style.cardsContainer}>
            {data.map((el, index) => {
              if (index > 2) {
                return;
              }
              return (
                <div className={style.card}>
                  <Card
                    besoin={el.besoin}
                    loc={"Le: 12.06.2023"}
                    locIsWhite={true}
                    changeStyle={handleCardStyleGreen}
                    besoinColor={"#9BC070"}
                    redirect={redirect}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {type == "blue" && (
        <div className={style.elContainer}>
          <div className={style.titleContainer}>
            <img src="../../images/search.png" className={style.qr} />
            <p>Prospections indirectes</p>
          </div>
          <div className={style.cardsContainer}>
            {data.map((el, index) => {
              if (index > 2) {
                return;
              }
              return (
                <div className={style.card}>
                  <Card
                    besoin={el.besoin}
                    loc={"Le: 12.06.2023"}
                    locIsWhite={true}
                    changeStyle={handleCardStyleBlue}
                    besoinColor={"green"}
                    redirect={redirect}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {type == "bluePunch" && (
        <div className={style.elContainer}>
          <div className={style.titleContainer}>
            <img src="images/search.png" className={style.qr} />
            <p>Prospections indirectes</p>
          </div>
          <div className={style.cardsContainer}>
            {data.map((el, index) => {
              if (index > 2) {
                return;
              }
              if (index == 2) {
                return (
                  <div className={style.card}>
                    <Card
                      besoin={el?.besoin}
                      loc={"Le: 12.06.2023"}
                      locIsWhite={true}
                      changeStyle={handleCardStyleBlue}
                      besoinColor={"#388ADC"}
                      isPunch={true}
                      redirect={redirect}
                    />
                  </div>
                );
              }
              return (
                <div
                  className={style.card}
                  onClick={() => navigate(`/appeloffre/${el.id}`)}
                >
                  <Card
                    besoin={el?.data?.lines?.length}
                    loc={`Le: ${moment(el?.data?.createdAt?.toDate()).format(
                      "L"
                    )}`}
                    locIsWhite={true}
                    changeStyle={handleCardStyleBlue}
                    besoinColor={"#388ADC"}
                    redirect={redirect}
                    data={el}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default PopUp;

const useStyles = makeStyles({
  constainer: {
    width: "100%",
    fontFamily: "poppins-regular",
    overflow: "hidden",
    height: "35vh",
  },
  elContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: 300,
    backgroundColor: "white",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: 30,
  },
  qr: {
    height: 35,
    marginRight: 20,
  },
  cardsContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "80%",
  },
  card: {
    width: "30%",
  },
  close: {
    position: "absolute",
    height: 45,
    right: 20,
    top: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  circle: {
    position: "absolute",
    right: 0,
    bottom: 0,
    transform: "translate(40%, 40%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#388ADC",
    height: 150,
    width: 150,
    fontFamily: "poppins-regular",
    fontSize: 20,
    color: "white",
    borderRadius: "50%",
    cursor: "pointer",
    // "&:hover": {
    //   transform: "translate(40%, 40%) scale(1.3)",
    //   transitionDuration: "500ms",
    // },
  },
  voir: {
    position: "absolute",
    bottom: 5,
    right: 5,
    color: "white",
    fontWeight: 400,
    fontSize: 18,
    cursor: "pointer",
  },
});
