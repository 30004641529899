import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import InputLogin from "../../Components/InputLogin";
import Home from "../../Components/Home";

const ActiviteAjout = () => {
  const style = useStyles();
  const navigate = useNavigate();

  const dataArray = ["Anais", "Benoit", "Jean", "Michel"];
  const element = [
    {
      text: "VIANDES",
      color: "#B53729",
    },
    {
      text: "POISSONS",
      color: "#388ADC",
    },
    {
      text: "LAITIER",
      color: "#F6C745",
    },
  ];
  const elementBottom = [
    {
      text: "PRODUITS FRAIS",
      color: "#61B194",
    },
    {
      text: "EPICERIE",
      color: "#DFA265",
    },
    {
      text: "VIN",
      color: "#E3E3E3",
    },
  ];

  return (
    <div className={style.container}>
      <Home />
      <div className={style.leftContainer}>
        <div className={style.leftContainerCenter}>
          <div className={style.leftContainerTop}>
            <p style={{ fontSize: 30, textAlign: "center", margin: 0 }}>
              Je sélectionne leurs secteurs d'activités
            </p>
            <img src="../../images/info.png" className={style.info} />
          </div>
          <div className={style.leftContainerMiddle}>
            <div className={style.leftContainerMiddleTop}>
              <p style={{ fontSize: 30, margin: 0, marginRight: 15 }}>
                Colaborateurs
              </p>
              <input type="checkbox" />
              <p>Tout sélectionner</p>
            </div>
            <div
              className={style.leftContainerMiddleBottom}
              style={{ padding: "20px 0 40px 0" }}
            >
              {dataArray.map((el, index) => {
                return (
                  <div className={style.user}>
                    <p style={{ margin: 0, width: 120 }}>{el}</p>
                    <input type="checkbox" style={{ marginLeft: 15 }} />
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={style.leftContainerBottom}
            onClick={() => navigate("/fourniseur/siege/intro")}
          >
            <InputLogin text={"Valider"} changeStyle={{ width: "99%" }} />
          </div>
        </div>
      </div>
      <div className={style.rightContainer}>
        <div className={style.products}>
          <div className={style.productsTop}>
            {element.map((el) => {
              return (
                <div className={style.productContainer}>
                  <p>{el.text}</p>
                  <div
                    className={style.buttonContainer}
                    style={{ backgroundColor: el.color }}
                  >
                    <img
                      src="../../images/add-circle.png"
                      style={{ height: 42.75 }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className={style.productsBottom}>
            {elementBottom.map((el) => {
              return (
                <div className={style.productContainer}>
                  <p>{el.text}</p>
                  <div
                    className={style.buttonContainer}
                    style={{ backgroundColor: el.color }}
                  >
                    <img
                      src="../../images/add-circle.png"
                      style={{ height: 42.75 }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    fontFamily: "poppins-regular",
  },
  leftContainer: {
    width: "30%",
    height: "100vh",
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    // justifyContent: "center",
  },
  leftContainerCenter: {
    width: "80%",
  },
  leftContainerTop: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
    marginBottom: 100,
  },
  info: {
    position: "absolute",
    bottom: 8,
    right: 0,
    height: 35,
  },
  leftContainerMiddleTop: {
    display: "flex",
    flexWrap: "nowrap",
  },
  leftContainerBottom: {
    height: "20vh",
    "&:hover": {
      cursor: "pointer",
    },
  },
  user: {
    display: "flex",
    fontSize: 25,
  },
  rightContainer: {
    width: "70%",
  },
  products: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  productsTop: {
    display: "flex",
    justifyContent: "space-between",
    width: "60%",
  },
  productsBottom: {
    display: "flex",
    justifyContent: "space-between",
    width: "60%",
  },
  productContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "30%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    height: 88,
    width: "100%",
    boxShadow: "0 3px 6px rgb(0,0,0, 0.16)",
  },
});

export default ActiviteAjout;
