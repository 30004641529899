import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "@firebase/auth";
import InputLogin from "../Components/InputLogin";
import { useFirebase } from "../hooks/useFirebase";
import { useCookies } from "react-cookie";
import { toast, Toaster } from "react-hot-toast";
import { doc, getDoc } from "@firebase/firestore";

const Connexion = () => {
  const style = useStyles();
  const { auth, db } = useFirebase();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [cookies, setCookie] = useCookies();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!email || !password) {
      toast.error("Veuillez remplir tous les champs !");
      // enqueueSnackbar("Veuillez remplir tous les champs", {
      //   variant: "error",
      // });
      return;
    }

    try {
      const loggedInUser = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );

      const token = await loggedInUser.user.getIdToken();

      const user = await getDoc(doc(db, `users/${email.toLowerCase()}`));

      setCookie("userToken", token);

      if (user.data().type === "Fournisseur" || user.data().type === "Siege")
        navigate("/fourniseur/paiement-abonnement");
      else navigate("/dashboard");
    } catch (e) {
      if (e.message.includes("auth/invalid-email")) {
        toast.error("Cet email n'est associé à aucun compte !");
        return;
      }
      if (e.message.includes("auth/wrong-password")) {
        toast.error("Mot de passe incorrect !");
        return;
      }
      toast.error("Une erreur est survenue");
      console.log(e);
    }
  };

  return (
    <div className={style.container}>
      <div>
        <Toaster />
      </div>
      <div className={style.containerLeft}></div>
      <div className={style.containerRight}>
        <form className={style.form}>
          <p className={style.title}>Connexion</p>
          <label>Email</label>
          <input
            type="text"
            className={style.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <label>Mot de passe</label>
          <div className={style.oeilContainer}>
            <input
              type="password"
              className={style.input}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ marginBottom: 0 }}
            ></input>
            <img src="../images/oeil.png" className={style.oeil} />
          </div>
          <div
            className={style.buttonContainer}
            onClick={() => {
              handleSubmit();
            }}
          >
            <InputLogin
              text={"Connexion"}
              changeStyle={{
                backgroundColor: "#388ADC",
                color: "white",
                height: 70,
              }}
            />
          </div>

          <p style={{ marginTop: 60 }}>
            Je n'ai pas de compte ?{" "}
            <span
              style={{ color: "#388ADC" }}
              className={style.buttonContainer}
              onClick={() => navigate("/fournisseurinscription")}
            >
              S'inscrire.
            </span>
          </p>
        </form>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    fontFamily: "poppins-regular",
  },
  containerLeft: {
    width: "50%",
    height: "100%",
    backgroundImage: "url('../images/fournisseurConnexion.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  containerRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    maxHeight: "100vh",
    overflow: "auto",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
  },
  title: {
    fontSize: 30,
  },
  oeilContainer: {
    display: "flex",
    alignItems: "center",
    height: "contents",
    width: "100%",
    position: "relative",
    marginBottom: 60,
  },
  oeil: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: 30,
    height: "3vh",
  },
  input: {
    backgroundColor: "#F2F2F2",
    borderRadius: 5,
    border: "none",
    height: "6vh",
    marginBottom: 60,
    width: "100%",
  },
  checkboxContainer: {
    display: "flex",
    marginBottom: 20,
  },
  buttonContainer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default Connexion;
