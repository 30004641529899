import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

export const CardDevisRendezVous = ({
  personName,
  needs,
  address,
  email,
  onClick,
}) => {
  const style = useStyles();

  return (
    <div className={style.cardContainer}>
      <div className={style.cardTopContainer}>
        <p className={style.cardName}>{personName}</p>
        <div className={style.needsContainer}>
          <p className={style.cardNeeds}>Besoins </p>
          <p className={style.needNumber}>{needs}</p>
        </div>
      </div>
      <div className={style.cardBottomContainer}>
        <div className={{display: "flex", flexDirection: "column"}}>
          <div className={style.cardEmailContainer}>
            <LocationOnIcon style={{height: 20, fill: "white"}} />
            <p className={style.cardAddress}>{address}</p>
          </div>
          <div className={style.cardEmailContainer}>
            <EmailOutlinedIcon style={{height: 20, fill: "white"}} />
            <p className={style.cardAddress}>{email}</p>
          </div>
        </div>
        <div onClick={onClick} className={style.button}>
          Voir
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  cardContainer: {
    // width: "100%",
    backgroundColor: "#9BC070",
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    paddingInline: 10,
  },
  cardTopContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardName: {
    fontFamily: "poppins-bold",
    fontSize: 16,
    color: "white",

    "@media (max-width: 400px)": {
      fontSize: 14,
    },
  },
  needsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 0,
  },
  cardNeeds: {
    fontFamily: "poppins-regular",
    fontSize: 16,
    color: "white",

    "@media (max-width: 400px)": {
      fontSize: 14,
    },
  },
  needNumber: {
    backgroundColor: "white",
    borderRadius: 5,
    padding: "0 15px 0 15px",
    marginLeft: 10,
    fontFamily: "poppins-bold",
    fontSize: 16,
    color: "#9BC070",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",

    "@media (max-width: 400px)": {
      fontSize: 14,
    },
  },
  cardBottomContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardEmailContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  cardAddress: {
    fontFamily: "poppins-regular",
    fontSize: 16,
    color: "white",
    margin: 0,

    "@media (max-width: 400px)": {
      fontSize: 14,
    },
  },
  button: {
    backgroundColor: "#388ADC",
    borderRadius: 10,
    padding: "5px 20px 5px 20px",
    fontFamily: "poppins-bold",
    fontSize: 16,
    color: "white",
    cursor: "pointer",

    "@media (max-width: 400px)": {
      fontSize: 14,
      padding: "5px 15px 5px 15px",
    },
  },
});
